import { Box, Container, Typography } from '@mui/material';
import { LoginForm } from '../components/LoginForm';

export function LoginPage(): JSX.Element {
    return (
        <Container maxWidth="xs">
            <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>Войти</Typography>

                <LoginForm />
            </Box>
        </Container>
    );
};
