import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {AdminTable, IAdminTableProps} from "../components/AdminTable";
import {Button, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, Typography} from "@mui/material";
import {IFilter} from "./admin";
import styles from './product.module.scss';
import React, {ChangeEvent} from "react";
import {AdminHeader} from "./header";
import {Link} from "react-router-dom";

interface IProps extends IAdminTableProps {
    header: string;
    isLoading: boolean;
    pages: number;
    onClick: (event: React.ChangeEvent<unknown>, page: number) => void;
    filter: IFilter;
    handleChange: (event: SelectChangeEvent<number | string | null>) => void;
    handleTextChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function ProductList(props: IProps) {
    const {data, isSuccess, isError, header, isLoading, pages, onClick, filter, handleChange, handleTextChange} = props;
    return (
        <Box sx={{flexGrow: 1, m: 2}}>
            <Grid container spacing={2}>
                <AdminHeader/>
                <Grid item xs={12} marginBottom={2}>
                    <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                        <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/upload-file">Загрузка каталога</Link>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h2">
                        {header}
                    </Typography>
                    <Button variant="outlined" color="primary" component="span">
                        <Link to={'/admin/catalog-lost'}>Несинхронизированные товары</Link>
                    </Button>


                    <Box sx={{display: 'flex'}}>
                        <div className={styles.filterItem}>
                            <InputLabel>Наличие каталога</InputLabel>
                            <Select
                                id="select-image"
                                value={filter._catalog}
                                label="Наличие каталога"
                                onChange={handleChange}
                                name="_catalog"
                                sx={{width: '100%'}}
                            >
                                <MenuItem value={1}>Есть</MenuItem>
                                <MenuItem value={0}>Нет</MenuItem>
                                <MenuItem value={-1}>Все</MenuItem>
                            </Select>
                        </div>
                        <div className={styles.filterItem}>
                            <InputLabel>Наличие изображения</InputLabel>
                            <Select
                                id="select-image"
                                value={filter._image}
                                label="Наличие фото"
                                name="_image"
                                onChange={handleChange}
                                sx={{width: '100%'}}
                            >
                                <MenuItem value={1}>Есть</MenuItem>
                                <MenuItem value={0}>Нет</MenuItem>
                                <MenuItem value={-1}>Все</MenuItem>
                            </Select>
                        </div>
                    </Box>
                    <AdminTable data={data}
                                handleChange={handleChange}
                                handleTextChange={handleTextChange}
                                brand={filter._brand} isSuccess={isSuccess}
                                isError={isError}/>
                    {data && !isLoading && <Pagination count={pages}
                                                       onChange={onClick}
                                                       variant="outlined"
                                                       shape="rounded"
                                                       sx={{
                                                           margin: '10px auto 10px',
                                                           display: 'flex',
                                                           justifyContent: 'center'
                                                       }}/>}
                </Grid>
            </Grid>
        </Box>
    );
}
