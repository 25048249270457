import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const jackingApi = createApi({
    reducerPath: 'jackingApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({

        getSend: build.query({
            query({limit, page}) {
                return {
                    url: 'form-interceptor',
                    params: {
                        limit, page,
                    }
                }
            }
        }),
    })
});

export const { useLazyGetSendQuery } = jackingApi;
