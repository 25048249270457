import {useLazyDeleteOrderQuery, useLazyGetOrdersQuery} from "../store/protected/orders/orders.api";
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridEventListener, GridRenderCellParams, ruRU} from '@mui/x-data-grid';
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AdminHeader} from "./header";
import {useRole} from "../hooks/auth";
import map from "lodash/map";
import includes from "lodash/includes";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme(
    {},
    ruRU,
);


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 130,
        filterable: true,
    },
    {
        field: 'manager',
        headerName: 'Менеджер',
        width: 250,
        filterable: true,
    },
    {
        field: 'title',
        headerName: 'Заказ',
        flex: 1,
        filterable: true,
    },
    {
        field: 'files', headerName: 'Файлы', flex: 1, renderCell: (params) => (
            <Box sx={{whiteSpace: 'normal'}}>{
                map(params.value, (file) => (<Box sx={{borderBottom: 1, paddingY: 1}}>{file.fileName}</Box>))
            }
            </Box>
        )
    }
];

const DeleteButton = (cellValues: GridRenderCellParams): JSX.Element => {
    const [deleteHandler] = useLazyDeleteOrderQuery();
    const [getOrders] = useLazyGetOrdersQuery();

    const onClickHandler = async (): Promise<void> => {
        await deleteHandler({
            id: cellValues.row.id
        })
        getOrders({limit: 0, page: 1});

    }

    return (
        <IconButton onClick={onClickHandler} color="default" size="small" sx={{ml: 1}}>
            <DeleteIcon fontSize="small"/>
        </IconButton>
    )
};

export const OrderListPage = (): JSX.Element => {
    const [getOrders, {data, isSuccess}] = useLazyGetOrdersQuery();
    const navigate = useNavigate();
    const {role} = useRole();

    useEffect(() => {
        getOrders({limit: 0, page: 1});
    }, [getOrders]);

    const getColumns = useCallback((): GridColDef[] => {
        const d = [...columns];
        if (includes(role, 'admin')) {
            d.push({field: 'delete', headerName: 'Удалить', flex: 1, renderCell: DeleteButton} as GridColDef)
        }

        return d;
    }, [role]);

    const handleEvent: GridEventListener<'rowClick'> = (
        params, // GridRowParams
        event, // MuiEvent<React.MouseEvent<HTMLElement>>
    ) => {
        if (!(event.target as HTMLElement).closest('[data-field="delete"]')) {
            navigate(`/admin/order/${params.row.id}`)
        }
    };

    return isSuccess && !!data?.data.length ? (
        <>
            <AdminHeader/>
            <Box sx={{height: '600px'}}>
                {!!data?.data.length &&
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            disableRowSelectionOnClick={true}
                            rows={data.data}
                            onRowClick={handleEvent}
                            getRowHeight={() => 'auto'}
                            columns={getColumns()}
                            slotProps={{
                                panel: {
                                    sx: {
                                        top: '-120px !important',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                }
                            }}
                        />
                    </ThemeProvider>}
            </Box>
        </>

    ) : <div>Нет данных</div>;
}
