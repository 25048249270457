import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {IOrderProductResponse} from "../../../../../store/protected/orders/models";
import {baseQueryWithReauth} from "../../../../../consts/baseQuery";

export const orderLostApi = createApi({
    reducerPath: 'orderLostApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getProducts: build.query<IOrderProductResponse[], void>({
            query: () => {

                return {
                    url: 'product/without-info',
                }
            },
        }),
    })
});


export const { useGetProductsQuery } = orderLostApi;
