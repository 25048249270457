import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { number as znumber, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { NumberInput } from './NumberInput';
import { useLazyCreateProductQuery } from '../store/protected/products/products.api';
import { TextInput } from './TextInput';
import { useState } from 'react';

const createSchema = object({
    brand: string().max(255).optional(),
    productName: string().max(255).optional(),
    productNumber: string().max(255).optional(),
    sku: string().max(255).optional(),
    supplierArt: string().max(255).optional(),
    description: string().max(255).optional(),
    color: string().max(255).optional(),
    priceRrp: znumber().nonnegative().finite().optional(),
    netHeight: znumber().nonnegative().finite().optional(),
    netWidth: znumber().nonnegative().finite().optional(),
    netDepth: znumber().nonnegative().finite().optional(),
    series: string().max(255).optional(),
    netWeight: znumber().nonnegative().finite().optional(),
    brHeight: znumber().nonnegative().finite().optional(),
    brWidth: znumber().nonnegative().finite().optional(),
    brDepth: znumber().nonnegative().finite().optional(),
    brWeight: znumber().nonnegative().finite().optional(),
    grossVolume: znumber().nonnegative().finite().optional(),
    eanCode: string().max(255).optional(),
    imageLink: string().max(500).optional(),
    measurements: string().max(255).optional(),
    supp: string().max(255).optional(),
    linkInstruction: string().max(600).optional(),
    linkQr: string().max(255).optional(),
    linkPublic: string().max(255).optional(),
});

type TCreateInput = TypeOf<typeof createSchema>;

export function CreateForm(): JSX.Element {
    const formData = new FormData();
    const [selectedImage, setSelectedImage] = useState<File>();
    const [selectedCatalog, setSelectedCatalog] = useState<File>();
    const navigate = useNavigate();

    const methods = useForm<TCreateInput>({
        resolver: zodResolver(createSchema),
        defaultValues: {
            brand: "",
            productName: "",
            productNumber: "",
            sku: "",
            supplierArt: "",
            description: "",
            color: "",
            priceRrp: 0,
            netHeight: 0,
            netWidth: 0,
            netDepth: 0,
            series: "",
            netWeight: 0,
            brHeight: 0,
            brWidth: 0,
            brDepth: 0,
            brWeight: 0,
            grossVolume: 0,
            eanCode: "",
            imageLink: "",
            measurements: "",
            supp: "",
            linkInstruction: "",
            linkQr: "",
            linkPublic: "",
        },
    });

    const { handleSubmit } = methods;

    const [create] = useLazyCreateProductQuery();

    const submitHandler: SubmitHandler<TCreateInput> = (values) => {
        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value.toString());
        }

        if (selectedCatalog) {
            formData.append('catalog', selectedCatalog);
        }

        if (selectedImage) {
            formData.append('image', selectedImage);
        }

        create(formData)
            .then(() => {
                navigate('/admin');
            })
            .catch((e) => {
                console.log(e);

                // Проверить на 403
            })
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0])
        }
    };

    const handleCatalogChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedCatalog(e.target.files[0])
        }
    };

    return (
        <>
            <input
                type="file"
                onChange={ handleImageChange }
                accept=".png, .jpg, .jpeg, .bmp"
                style={{ display: "none" }}
                id="select-image"
            />
            <label htmlFor="select-image" style={{ width: '100%', display: 'flex', marginBottom: "16px" }}>
                <Typography sx={{ flexGrow: 1 }} component="span">Файл изображения: {selectedImage ? selectedImage.name : "не выбран"}</Typography>
                <Button variant="outlined" color="primary" component="span">
                    {selectedImage ? "Выбрать другой файл" : "Выбрать файл"}
                </Button>
            </label>

            <input
                type="file"
                onChange={ handleCatalogChange }
                accept=".pdf"
                style={{ display: "none" }}
                id="select-catalog"
            />
            <label htmlFor="select-catalog" style={{ width: '100%', display: 'flex', marginBottom: "8px" }}>
                <Typography sx={{ flexGrow: 1 }} component="span">Файл каталога: {selectedCatalog ? selectedCatalog.name : "не выбран"}</Typography>
                <Button variant="outlined" color="primary" component="span">
                    {selectedCatalog ? "Выбрать другой файл" : "Выбрать файл"}
                </Button>
            </label>


            <FormProvider { ...methods }>
                <Box component="form" onSubmit={ handleSubmit(submitHandler) } autoComplete="off" noValidate sx={{ mt: 1 }}>
                    <TextInput name="brand" id="brand" label="Бренд" autoFocus />
                    <TextInput name="productName" id="productName" label="Наименование товара" />
                    <TextInput name="productNumber" id="productNumber" label="Номер товара" />
                    <TextInput name="sku" id="sku" label="Артикул" />
                    <TextInput name="supplierArt" id="supplierArt" label="Артикул поставщика" />
                    <TextInput name="description" id="description" label="Описание" />
                    <TextInput name="color" id="color" label="Цвет" />
                    <NumberInput name="priceRrp" id="priceRrp" label="Цена РРЦ" type="number" />
                    <NumberInput name="netHeight" id="netHeight" label="Высота нетто" type="number" />
                    <NumberInput name="netWidth" id="netWidth" label="Ширина нетто" type="number" />
                    <NumberInput name="netDepth" id="netDepth" label="Глубина нетто" type="number" />
                    <TextInput name="series" id="series" label="Серия" />
                    <NumberInput name="netWeight" id="netWeight" label="Вес нетто" type="number" />
                    <NumberInput name="brHeight" id="brHeight" label="Высота брутто" type="number" />
                    <NumberInput name="brWidth" id="brWidth" label="Ширина брутто" type="number" />
                    <NumberInput name="brDepth" id="brDepth" label="Глубина брутто" type="number" />
                    <NumberInput name="brWeight" id="brWeight" label="Вес брутто" type="number" />
                    <NumberInput name="grossVolume" id="grossVolume" label="Объем брутто" type="number" />
                    <TextInput name="eanCode" id="eanCode" label="EAN-код" />
                    <TextInput name="imageLink" id="imageLink" label="Ссылка на картинку" />
                    <TextInput name="measurements" id="measurements" label="Ед. измерения" />
                    <TextInput name="supp" id="supp" label="Supp" />
                    <TextInput name="linkInstruction" id="linkInstruction" label="Ссылка на инструкцию" />
                    <TextInput name="linkQr" id="linkQr" label="Ссылка на QR" />
                    <TextInput name="linkPublic" id="linkPublic" label="Публичная ссылка" />

                    <Button type="submit" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>Создать</Button>
                </Box>  
            </FormProvider>
        </>
    );
};
