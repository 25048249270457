import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { useLazyUpdateFieldValueQuery} from "../../../store/protected/clients/clients.api";
import isNil from "lodash/isNil";
import {DisabledBox} from "./Disabled";
import {useCheckTokenQuery} from "../../../store/protected/auth/auth.api";

interface BlockButtonProps {
    isBlocked: boolean;
    clientId: number;
    clientUserId?: number;
    userId: number;
    setIsOpen: (status: boolean)=>void;
    onChangeValue: (id: number,key: string, value: any) => void;
}

export const BlockButton: React.FC<BlockButtonProps> = ({ isBlocked, clientUserId, userId, setIsOpen, clientId, onChangeValue }) => {
    const [currentBlocked, setCurrentBlocked] = useState(isBlocked);
    const [updateField] = useLazyUpdateFieldValueQuery();
    const {data: userData} = useCheckTokenQuery(null, {refetchOnMountOrArgChange: true});


    useEffect(() => {
        setCurrentBlocked(isBlocked);
    }, [isBlocked]);
    const handleBlock = async () => {
        try {
            const result = await updateField({ clientId, field:'block', value: !currentBlocked  });

            // @ts-ignore
            if(!result.data.success){
                alert('Данный лид уже взят в работу');
                setIsOpen(true);
            }else{
                onChangeValue(clientId, 'block', !currentBlocked);
                onChangeValue(clientId, 'user', !currentBlocked? {id: userId, username: userData.username}: {} );
            }
            setCurrentBlocked(!currentBlocked);
        } catch (error) {
            console.error('Error blocking the client:', error);
        }
    };


    return (
        <DisabledBox disabled={!isNil(clientUserId) && userId !== clientUserId}>
                <Button
                    variant="contained"
                    color={currentBlocked ? "error" : "success"}
                    onClick={handleBlock}
                    style={{ borderRadius: '50%', minWidth: '32px', padding: '6px 12px',
                        width: '32px',
                        height: '32px'}}
                >
                    {currentBlocked ? "✓" : "×"}
                </Button>
        </DisabledBox>
    );
};

