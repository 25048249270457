import {FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {filter, includes, join, map} from "lodash";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {IFilter} from "./Models";
import {useGetBrandsGroupQuery} from "../../../../store/protected/clients/clients.api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IProps {
    data: number[];
    changeFilter: (filterElement: Partial<IFilter>) => void;
}

export const FilterBrand = ({data, changeFilter}: IProps): React.ReactElement => {
    const {
        data: clientBrands, isSuccess: isSuccessBrands
    } = useGetBrandsGroupQuery(null, {refetchOnMountOrArgChange: true});

    const handleChange = (e: SelectChangeEvent<number[]>): void => {
        changeFilter({
            [e.target.name]: e.target.value
        })
    }

    const renderValue = (selected: number[]): string => {
        const result = filter(clientBrands, (element) => {
            return includes(selected, element.id);
        })
        return join(map(result, (brand) => brand.name), ', ');
    }

    if (!isSuccessBrands) {
        return null;
    }

    return (
        <FormControl sx={{m: 1, width: 300}} size="small">
            <InputLabel id="brand-checkbox-label">Бренд</InputLabel>
            <Select
                labelId="brand-checkbox-label"
                id="brand-checkbox"
                multiple
                size="small"
                name="brands"
                onChange={handleChange}
                value={data}
                input={<OutlinedInput label="Статус"/>}
                renderValue={renderValue}
                MenuProps={MenuProps}
            >
                {map(clientBrands, (brand) => {
                    return (
                        <MenuItem key={brand.id} value={brand.id}>
                            <Checkbox checked={includes(data, brand.id)}/>
                            <ListItemText primary={brand.name}/>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

