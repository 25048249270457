import { createApi } from '@reduxjs/toolkit/query/react';
import {IAsyncTaskConverterProgress, IAsyncTaskResponse} from "./models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";
export const asyncTaskApi = createApi({
    reducerPath: 'asyncTaskApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        asyncTaskList: build.query<IAsyncTaskResponse[], any>({
            query: () => ({
                url: 'async-task',
                method: 'GET',
            }),
        }),
        asyncTaskListConverter: build.query<IAsyncTaskResponse[], any>({
            query: () => ({
                url: 'images-converter/list',
                method: 'GET',
            }),
        }),
        asyncTaskListConverterInProgress: build.query<IAsyncTaskConverterProgress, any>({
            query: () => ({
                url: 'images-converter/progress',
                method: 'GET',
            }),
        }),
        asyncTaskListQr: build.query<IAsyncTaskResponse[], any>({
            query: () => ({
                url: 'qr/list',
                method: 'GET',
            }),
        }),
        asyncTaskCatalogUpload: build.query<IAsyncTaskResponse[], any>({
            query: () => ({
                url: 'product/upload-list',
                method: 'GET',
            }),
        }),
    }),
});

export const { useAsyncTaskListQuery,
    useAsyncTaskListConverterQuery,
    useAsyncTaskCatalogUploadQuery,
    useAsyncTaskListQrQuery,
    useAsyncTaskListConverterInProgressQuery
} = asyncTaskApi;
