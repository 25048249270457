import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const converterApi = createApi({
    reducerPath: 'converterApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        uploadImagesConverterDataBase: build.query<any, FormData>({
            query: (file) => ({
                url: 'images-converter/upload',
                method: 'POST',
                body: file,
            }),
        }),

        uploadQrDatabase: build.query<any, FormData>({
            query: (file) => ({
                url: 'qr/upload',
                method: 'POST',
                body: file,
            }),
        }),
    }),
});

export const { useLazyUploadImagesConverterDataBaseQuery, useLazyUploadQrDatabaseQuery } = converterApi;
