import {useGetStatisticsQuery, useLazyGetStatisticsDateQuery} from "../../../store/protected/clients/clients.api";
import {AdminHeader} from "../../header";
import React, {useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment";
import {BlockButton} from "../list/Block";
import {join, map, noop} from "lodash";
import IconButton from "@mui/material/IconButton";
import {copyToClipboard} from "../../../utils/OrderColumns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


const theme = createTheme(
    {},
    ruRU,
);


const columns: GridColDef[] = [
    {
        field: 'phones',
        headerName: 'Тел.',
        width: 160,
        valueGetter: (params: any) => params.row.phones.map((p: any) => p.phone),
        renderCell: (params: any) => (
            <div>
                {params.value.map((phone: string, index: number) => (
                    <div key={index}>
                        <a href={`tel:+${phone}`} style={{textDecoration: 'none', color: 'blue'}}>
                            +{phone}
                        </a>
                        <IconButton onClick={() => copyToClipboard(`+${phone}`)} color="default"
                                    size="small" sx={{ml: 1}}>
                            <ContentCopyIcon fontSize="small"/>
                        </IconButton>
                    </div>
                ))}
            </div>
        ),
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'check',
        headerName: 'Проверено через бота',
        width: 250,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: any) => (<BlockButton
            isBlocked={params.row.check}
            key={'block_'+params.row.id}
            userId={1}
            clientId={2}
            onChangeValue={noop}
            setIsOpen={noop}
        />)
    },
]

export function SkipPhones(): JSX.Element {
    const [getList, {data, isSuccess}] = useLazyGetStatisticsDateQuery();
    const [selectedDate, handleDateChange] = useState(moment().format("YYYY-MM-DD"));
    const changeDate = (e: React.ChangeEvent<HTMLInputElement>): void => {
        handleDateChange(moment(e.target.value).format("YYYY-MM-DD"));
    }
    const handleRefreshData = (): void => {
        getList({ date: selectedDate });
    }

    return (
        <>
            <AdminHeader/>
            <Box sx={{height: '600px'}}>
                <Box sx={{display: 'flex', alignItems: 'center', marginY: '20px'}}>
                    Дата
                    <input type="date"
                           value={selectedDate}
                           onChange={changeDate}
                    />
                    <Button variant="contained" color="primary" size="small" onClick={handleRefreshData}
                            sx={{marginLeft: '10px'}}>
                        Получить данные
                    </Button>
                </Box>
                {!!data?.notFound && (<Box>Вне базы: {join(data.notFound,', ')}</Box>)}
                {!!data?.found &&
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            disableRowSelectionOnClick={true}
                            rows={data?.found }
                            getRowHeight={() => 'auto'}
                            columns={columns}
                            slotProps={{
                                panel: {
                                    sx: {
                                        top: '-120px !important',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                }
                            }}
                        />
                    </ThemeProvider>}
            </Box>
        </>
    )
}
