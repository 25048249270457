import Box from "@mui/material/Box";
import {Alert, Button, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IEmailCommonDetails} from "./models";
import {useLazyCreateQuery, useLazyGetOneQuery, useLazySendQuery, useLazyUpdateQuery} from "./email.common.api";
import isNil from "lodash/isNil";

const INITIAL_STATE: IEmailCommonDetails = {
    email: '',
    password: '',
    description: '',
    signature: '',
    mailProvider: 'mail',
}

export const EmailCommonForm = (): JSX.Element => {
    const {id} = useParams();
    const [emailData, setEmailData] = useState<IEmailCommonDetails>(INITIAL_STATE);
    const [create] = useLazyCreateQuery();
    const [update] = useLazyUpdateQuery();
    const [getOne] = useLazyGetOneQuery();
    const [sendEmail] = useLazySendQuery();
    const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [emailSend, setEmailSend] = useState<string>('');
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const loadData = async (id: string) => {
            const result = await getOne(id);

            setEmailData(result.data);
        }

        if (!isNil(id)) {
            loadData(id);
        } else {

            setEmailData(INITIAL_STATE);
        }
    }, [id]);

    const onChangeText = (key: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmailData({
                ...emailData as IEmailCommonDetails,
                [key]: e.target.value
            })
        }
    }

    const handleChange = (data: SelectChangeEvent) => {
        setEmailData({
            ...emailData as IEmailCommonDetails,
            mailProvider: data.target.value
        })
    }

    const saveEmail = async () => {
        try {
            if(!emailData?.id) {
                await create(emailData).unwrap();
            }else{
                await update(emailData).unwrap();
            }
            navigate('/admin/emails/common/list');

        } catch (e: any) {
            console.log(e)
            if (e?.data?.error) {
                setError(e?.data?.message?.join(', ') as string)
            }
        }
        //
    }

    const setEmailSendHandler = (data:React.ChangeEvent<HTMLInputElement>): void =>{
        setEmailSend(data.target.value);
    }

    const sendEmailHandler = async () =>{
        try {
            setDisabled(true);
            const data = await sendEmail({id: emailData.id, email: emailSend}).unwrap();
            setIsSnackbarVisible(true);
            console.log(data);
            //navigate('/admin/emails/common/list');

        } catch (e: any) {
            console.log(e)
            if (e?.data?.error) {
                setError(e?.data?.message as string)
            }
        }finally {
            setDisabled(false);
        }
    }


    return (<Box sx={{display: 'flex', justifyContent: 'space-between', mt: 5, flexDirection: 'column'}}>
        {error && (<Alert severity="error" sx={{marginBottom: '30px'}}>{error}</Alert>)}
        <Box>
            <TextField
                name="email"
                onChange={onChangeText('email')}
                id="email"
                label="Email"
                value={emailData.email}
                sx={{mr: 2}}
                autoFocus
            />
            <TextField
                name="signature"
                onChange={onChangeText('signature')}
                id="signature"
                label="Имя при отправке"
                type="text"
                sx={{mr: 2}}
                value={emailData.signature}
            />
            <TextField
                name="password"
                onChange={onChangeText('password')}
                id="password"
                label="Пароль"
                type="password"
                sx={{mr: 2}}
                value={emailData.password}
            />
            <TextField
                name="description"
                onChange={onChangeText('description')}
                id="description"
                label="Описание"
                type="text"
                sx={{mr: 2}}
                value={emailData.description}
            />
            <Select value={emailData.mailProvider} onChange={handleChange}>
                <MenuItem value="mail">
                    mail.ru
                </MenuItem>
                <MenuItem value="yandex">
                    yandex.ru
                </MenuItem>
            </Select>

        </Box>
        <Box>
            <Button variant="contained" sx={{marginTop: '40px'}} onClick={saveEmail}>Сохранить</Button>
        </Box>

        {emailData?.id && (
            <Box>
                <Typography variant="h5" component="h2" sx={{my: 5}}>
                    Тест письма
                </Typography>
                <Box sx={{alignItems: 'center', display: 'flex'}}>
                    <TextField
                        name="sendEmail"
                        onChange={setEmailSendHandler}
                        id="sendEmail"
                        label="Email"
                        value={emailSend}
                        sx={{mr: 2, width: '400px'}}
                        autoFocus
                    />
                    <Button variant="contained" disabled={ disabled } onClick={sendEmailHandler}>Тест письма</Button>
                </Box>
            </Box>
        )}
        <Snackbar
            open={isSnackbarVisible}
            autoHideDuration={2000}
            onClose={() => {
                setIsSnackbarVisible(false)
            }}
            sx={{top: 0, right: 0}}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <Alert severity="success" sx={{width: '100%'}}>
                Отправлено
            </Alert>
        </Snackbar>
    </Box>)
}
