import {TextField} from "@mui/material";
import {IFilter} from "./Models";
import {ChangeEvent} from "react";

interface IProps {
    phone: string;
    changeFilter: (filterElement: Partial<IFilter>) => void;
}
export const PhoneFilter = ({phone, changeFilter}: IProps): React.ReactElement => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        changeFilter({
            [e.target.name]: e.target.value
        })
    }

    return (
        <TextField
            id="phone"
            label="Телефон"
            placeholder="79091234567"
            name="phone"
            variant="outlined"
            value={phone}
            size="small"
            sx={{m: 1, width: '100%'}}
            onChange={handleChange}
        />
    );
}
