import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import moment from "moment/moment";
import {IFilter} from "./Models";
import {FilterStatus} from "./Status";
import {FilterBrand} from "./Brand";
import {PhoneFilter} from "./Phone";
import {BlockFilter} from "./Block";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {FilterUser} from "./User";
import {downloadData} from "../../../../utils/downloadData";
import {SourceFilter} from "./Source";

interface IProps {
    filter: IFilter;
    changeFilter: (data: IFilter) => void;
    handleRefreshData: () => void;
}


type IFilterKey = keyof IFilter;

export const FilterClients = ({filter, changeFilter, handleRefreshData}: IProps): React.ReactElement => {
    const changeHandler = (filterElement: Partial<IFilter>): void => {
        changeFilter({
            ...filter,
            ...filterElement
        })
    }

    // @ts-ignore
    const changeDateHandler = (name): (e) => void => {
        // @ts-ignore
        return (e): void => {
            changeFilter({
                ...filter,
                [name]: e.format("YYYY-MM-DD"),
            })
        }
    }

    const downloadFull = () => {
        // @ts-ignore
        const result = '?' + new URLSearchParams(filter).toString();
        downloadData('https://api.my-manuals.ru/clients/download/fullV2'+result);
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'start', marginY: '20px'}}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box sx={{display: 'flex', flexWrap: 'wrap', borderRight: 1, width: '300px'}}>
                    <DatePicker
                        defaultValue={moment(filter.fromDate, 'YYYY-MM-DD')}
                        label={"От"}
                        onChange={changeDateHandler('fromDate')}
                        sx={{width: '100%', m: 1}}
                        slotProps={{textField: {size: 'small'}}}
                    />
                    <DatePicker
                        defaultValue={moment(filter.toDate, 'YYYY-MM-DD')}
                        label={"От"}
                        onChange={changeDateHandler('toDate')}
                        sx={{width: '100%', m: 1}}
                        slotProps={{textField: {size: 'small'}}}
                    />
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', borderRight: 1, width: '300px'}}>
                    <FilterStatus changeFilter={changeHandler} data={filter?.statuses}/>
                    <FilterBrand changeFilter={changeHandler} data={filter?.brands}/>
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', borderRight: 1, width: '300px'}}>
                    <PhoneFilter changeFilter={changeHandler} phone={filter?.phone}/>
                    <BlockFilter changeFilter={changeHandler} block={filter?.block}/>
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', borderRight: 1, width: '300px', justifyContent: 'start'}}>
                    <SourceFilter changeFilter={changeHandler} source={filter?.source}/>
                    <FilterUser  changeFilter={changeHandler} data={filter?.user}/>
                    <Button variant="contained" color="primary" size="small" onClick={handleRefreshData}
                            sx={{marginLeft: '10px'}}>
                        Обновить
                    </Button>
                    <Button variant="contained" color="primary" size="small" onClick={downloadFull}
                            sx={{marginLeft: '10px'}}>
                        Скачать
                    </Button>
                </Box>
            </LocalizationProvider>
        </Box>
    );
}
