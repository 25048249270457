import React from 'react';
import {Button} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {useRole} from "../hooks/auth";
import {includes} from "lodash";
import {useAppDispatch, useAppSelector} from "../hooks/store";
import {authSlice} from "../store/protected/auth/auth.slice";

/** Компонент  */
export function AdminHeader(): JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoggedIn} = useAppSelector(state => state.auth);

    const handleLogoutClick = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        await dispatch(authSlice.actions.setIsLoggedOut());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role');
        navigate('/login');
    }

    const {role} = useRole();

    return (
        <Grid item xs={12} marginBottom={2}>
            {(includes(role, 'goodsCatalog') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/catalog">Каталог</Link>
                </Button>
            )}
            {(includes(role, 'loadCatalog') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/catalog-list">Работа с
                        каталогом</Link>
                </Button>)
            }

            {(includes(role, 'shippingCars') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/logistics">Логистика</Link>
                </Button>
            )}
            {(includes(role, 'remains') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/upload-remains">Остатки</Link>
                </Button>)}
            {(includes(role, 'imageConverter') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}}
                          to="/admin/upload-image-converter">Конвертер</Link>
                </Button>
            )}
            {(includes(role, 'qrConverter') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/upload-qr">Qr</Link>
                </Button>
            )}
            {(includes(role, 'orders') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0 }}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/orders">Заказы</Link>
                </Button>
            )}
            {(includes(role, 'asyncTask') || includes(role, 'admin')) && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/async-task">AsyncTask</Link>
                </Button>)}
            {(includes(role, 'clients') || includes(role, 'admin')) && (
                <>
                    <Button variant="contained" size="small" sx={{mr: 1, p: 0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/clients/new">Клиенты V2</Link>
                </Button>
                    <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                        <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/emails">Рассылки</Link>
                    </Button>
                </>
            )}
            {(includes(role, 'sendForm') || includes(role, 'admin')) && (
                <>
                    <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                        <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/send-list">Почты</Link>
                    </Button>
                    <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                        <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/jacking">jacking</Link>
                    </Button>
                </>)}
            {includes(role, 'admin') && (
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/users">Пользователи</Link>
                </Button>)}
            {isLoggedIn && (
                <Button variant="contained" size="small" onClick={handleLogoutClick} sx={{my: 2, color: 'white'}}>
                    Выйти
                </Button>
            )}
        </Grid>
    )
}
