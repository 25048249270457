import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {eurFormatter, rubFormatter} from "../../../utils/Currency";
import IconButton from "@mui/material/IconButton";
import {copyToClipboard} from "../../../utils/OrderColumns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useState} from "react";

interface IProps {
    total: number;
    totalBr: number;
    currency: number;
}

export function Result({total, currency, totalBr}: IProps): JSX.Element {
    const [coefficient, setCoefficient] = useState<number>(1);
    const [coefficientString, setCoefficientString] = useState<string>("");
    const [isCoefficientApplied, setIsCoefficientApplied] = useState<boolean>(false);

    // Обработчик ввода коэффициента
    const handleInputCoefficient = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;

        if (event.target.value === "" || regex.test(event.target.value)) {
            setCoefficient(Number(event.target.value));
            setCoefficientString(event.target.value);
        }
    }

    // Обработчик выбора учета коэффициента при пересчете евро в рубли
    const handleChangeIsCoefficientApplied = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCoefficientApplied(event.target.checked);
    };

    return (
            <Box>
                {/* Таблица итогов */}
                <Table aria-label="spanning table" sx={{maxWidth: 500, ml: 'auto'}}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Итог брутто</TableCell>
                            <TableCell align="right">
                                {eurFormatter.format(totalBr)}
                                <IconButton onClick={() => copyToClipboard(totalBr)} color="default" size="small"
                                            sx={{ml: 1}}>
                                    <ContentCopyIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                {rubFormatter.format(isCoefficientApplied ? totalBr * currency * coefficient : totalBr * currency)}
                                <IconButton
                                    onClick={() => copyToClipboard(isCoefficientApplied ? totalBr * currency * coefficient : totalBr * currency)}
                                    color="default" size="small" sx={{ml: 1}}>
                                    <ContentCopyIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Итог</TableCell>
                            <TableCell align="right">
                                {eurFormatter.format(total)}
                                <IconButton onClick={() => copyToClipboard(total)} color="default" size="small"
                                            sx={{ml: 1}}>
                                    <ContentCopyIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                {rubFormatter.format(isCoefficientApplied ? total * currency * coefficient : total * currency)}
                                <IconButton
                                    onClick={() => copyToClipboard(isCoefficientApplied ? total * currency * coefficient : total * currency)}
                                    color="default" size="small" sx={{ml: 1}}>
                                    <ContentCopyIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {/* Коэффициент */}
                <Box sx={{mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <TextField
                        sx={{mr: 2}}
                        label="Коэффициент"
                        type="text"
                        value={coefficientString}
                        onChange={handleInputCoefficient}
                    />

                    <FormControlLabel
                        control={<Checkbox checked={isCoefficientApplied}
                                           onChange={handleChangeIsCoefficientApplied}/>}
                        label="Учитывать коэффициент"
                    />
                </Box>
            </Box>
    )
}
