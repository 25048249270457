import { createApi } from '@reduxjs/toolkit/query/react';
import { IOrderResponse } from './models';
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const getOrderApi = createApi({
    reducerPath: 'getOrderApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getOrder: build.query<IOrderResponse, { id: string }>({
            query: ({ id }) => ({
                url: `order/${id}`,
            })
        }),
    }),
});

export const {
    useLazyGetOrderQuery,
} = getOrderApi;
