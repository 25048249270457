import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React from "react";
import {useListQuery} from "./email.chain.api";
import {useNavigate} from "react-router-dom";


const theme = createTheme(
    {},
    ruRU,
);

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'title',
        headerName: 'Заголовок',
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
];

export function EmailChainList() {
    const navigate = useNavigate();

    const {
        data,
    } = useListQuery(null, {refetchOnMountOrArgChange: true});

    return (<Box sx={{height: '600px'}}>
        {!!data?.length &&
            <ThemeProvider theme={theme}>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data}
                    onRowClick={ (data) => {
                        navigate(`/admin/emails/chain/${data.row.id}`);
                    } }
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    slotProps={{
                        panel: {
                            sx: {
                                top: '-120px !important',
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />
            </ThemeProvider>}
    </Box>)
}
