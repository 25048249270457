import React, {useState} from 'react';
import Button from '@mui/material/Button';
import isNil from "lodash/isNil";
import {useLazyUpdateFieldValueQuery} from "../../../../store/protected/clients/clients.api";
import {DisabledBox} from "../Disabled";
import {Snackbar} from "@mui/material";

interface BlockButtonProps {
    isBlocked: boolean;
    clientId: number;
    clientUserId?: number;
    userId: number;
    onChangeValue: () => void;
}

export const BlockButton: React.FC<BlockButtonProps> = ({isBlocked, clientUserId, userId, clientId, onChangeValue}) => {
    const [currentBlocked, setCurrentBlocked] = useState(isBlocked);
    const [updateField] = useLazyUpdateFieldValueQuery();
    const [open, setIsOpen] = useState(false);

    const handleBlock = async () => {
        try {
            const result = await updateField({clientId, field: 'block', value: !currentBlocked});

            // @ts-ignore
            if (!result.data.success) {
                alert('Данный лид уже взят в работу');
                setIsOpen(true);
            }
            setCurrentBlocked(!currentBlocked);
            onChangeValue();
        } catch (error) {
            console.error('Error blocking the client:', error);
        }
    };

    const handleClose = (): void => {
        setIsOpen(false);
    }


    return (
        <>
            <DisabledBox disabled={!isNil(clientUserId) && userId !== clientUserId}>
                <Button
                    variant="contained"
                    color={currentBlocked ? "error" : "success"}
                    onClick={handleBlock}
                    style={{
                        borderRadius: '50%', minWidth: '32px', padding: '6px 12px',
                        width: '32px',
                        height: '32px'
                    }}
                >
                    {currentBlocked ? "✓" : "×"}
                </Button>
            </DisabledBox>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Уже в работе!!"
            />
        </>
    );
};

