import React from 'react';
import {TextInput} from "../../../components/TextInput";
import {object, string, TypeOf} from "zod";
import {useForm, FormProvider, SubmitHandler} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import {Button, Container, Typography} from "@mui/material";
import {useLazyCreateQuery} from "../store/api";
import {AdminHeader} from "../../header";
import {useNavigate} from "react-router-dom";


const validateSchema = object({
    name: string().max(255).optional(),
});

type TInput = TypeOf<typeof validateSchema>;

export function ShippingCarsForm(): JSX.Element {
    const navigate = useNavigate();
    const [create] = useLazyCreateQuery();
    const methods = useForm<TInput>({
        resolver: zodResolver(validateSchema),
        defaultValues: {
            name: '',
        }
    });

    const {handleSubmit} = methods;

    const submitHandler: SubmitHandler<TInput> = async (values) => {
        await create({
            name: values.name,
        });

        navigate('/admin/shipping');
    }


    return (
        <>
            <AdminHeader/>
            <Container maxWidth="lg">
                <Box sx={{maxWidth: '600px'}}>
                    <FormProvider {...methods}>
                        <Typography variant="h5" component="h2">
                            Добавить новый маршрут
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit(submitHandler)} autoComplete="off" noValidate
                             sx={{mt: 1}}>
                            <TextInput name="name" id="name" label="Название" autoFocus/>
                            <Button type="submit" variant="contained" fullWidth sx={{mt: 3, mb: 2}}>Добавить</Button>
                        </Box>
                    </FormProvider>
                </Box>
            </Container>
        </>
    )
}
