import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IAuthState } from '../../../models';

const initialState: IAuthState = {
    isLoggedIn: false,
    role: [],
};

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setIsLoggedIn(state) {
            state.isLoggedIn = true;
        },

        setRole(state, action: PayloadAction<string[]>) {
            state.role = action.payload;
        },

        setIsLoggedOut(state) {
            state.isLoggedIn = false;
        },
    },
});

export const authReducer = authSlice.reducer;
