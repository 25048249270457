import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, ruRU} from "@mui/x-data-grid";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useLazyGetOrdersQuery} from "../../../store/protected/orders/orders.api";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useGetMultiOrderQuery, useLazyAddOrderToMultiOrderQuery} from "../store/api";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

const theme = createTheme(
    {},
    ruRU,
);

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 130,
        filterable: true,
    },
    {
        field: 'manager',
        headerName: 'Менеджер',
        width: 250,
        filterable: true,
    },
    {
        field: 'title',
        headerName: 'Заказ',
        flex: 1,
        filterable: true,
    },
];

const AddButton = (cellValues: GridRenderCellParams): JSX.Element => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {data: multiOrder} = useGetMultiOrderQuery({id: id as string});
    const [addOrderToMultiOrder] = useLazyAddOrderToMultiOrderQuery();

    const onClickHandler = async (): Promise<void> => {
        await addOrderToMultiOrder({
            orderId: cellValues.row.id,
            // @ts-ignore
            id: multiOrder.id.toString(),
        })
        navigate(0);
    }

    return (
        <IconButton onClick={onClickHandler} color="default" size="small" sx={{ml: 1}}>
            <AddCircleIcon fontSize="small"/>
        </IconButton>
    )
};

/** Компонент  */
export function OrdersList(): JSX.Element {
    const [getOrders, {data}] = useLazyGetOrdersQuery();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isOpen && !data?.data.length) {
            getOrders({limit: 0, page: 1});
        }
    }, [isOpen]);

    const openOrders = (): void => {
        setIsOpen(!isOpen);
    }

    const getColumns = useCallback((): GridColDef[] => {
        const d = [...columns];
        d.push({field: 'addNew', headerName: 'Добавить', flex: 1, renderCell: AddButton} as GridColDef)

        return d;
    }, []);

    return (
        <Box sx={{height: '600px', width: '50%'}}>
            <Button variant="contained" onClick={openOrders} color="primary" component="span" sx={{mx: 2}}>Добавить
                заказ</Button>

            {isOpen && !!data?.data.length && (<ThemeProvider theme={theme}>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data.data}
                    getRowHeight={() => 'auto'}
                    slotProps={{
                        panel: {
                            sx: {
                                top: '-120px !important',
                            },
                        },
                    }}
                    columns={getColumns()}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />
            </ThemeProvider>)}
        </Box>
    )
}
