import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {IFilter} from "./Models";
import React from "react";

interface IProps {
    source: number;
    changeFilter: (filterElement: Partial<IFilter>) => void;
}

export const SourceFilter = ({source, changeFilter}: IProps): React.ReactElement => {
    const handleChange = (e: SelectChangeEvent<string>): void => {
        changeFilter({
            [e.target.name]: +e.target.value
        })
    }

    return (
        <FormControl sx={{m: 1, width: '100%'}} size="small">
            <InputLabel id="source-label">Источник</InputLabel>
            <Select
                size="small"
                labelId="source-label"
                id="source-label-select"
                name="source"
                value={source.toString()}
                label="Источник"
                onChange={handleChange}
            >
                <MenuItem value="0">Все</MenuItem>
                <MenuItem value="1">Номера</MenuItem>
                <MenuItem value="2">Сайты</MenuItem>
            </Select>
        </FormControl>
    );
}
