import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useGetRolesQuery, useLazyGetUserQuery, useLazyUpdateUserQuery} from "../store/protected/users/users.api";
import {AdminHeader} from "./header";
import Box from "@mui/material/Box";
import {Button, TextField, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {IUsersDetailsResponse} from "../store/protected/users/users.dto";
import {includes, map, reject, trim} from "lodash";

const INITIAL_STATE: IUsersDetailsResponse = {
    id: null,
    role: [],
    password: '',
    username: '',
    active: true,
}

export function UserDetailPage(): JSX.Element {
    const {id} = useParams();
    const [userData, setUserData] = useState<IUsersDetailsResponse>(INITIAL_STATE);
    const [updateUser] = useLazyUpdateUserQuery();
    const {data: roles} = useGetRolesQuery();
    const [getUser, {
        isSuccess: isDataSuccess
    }] = useLazyGetUserQuery();
    const navigate = useNavigate();

    const getUserHandler = async (id: string) => {
        const data = (await getUser(+id)).data;
        setUserData(data as IUsersDetailsResponse);
    }

    const onChangeActive = () => {
        setUserData(
            {
                ...userData as IUsersDetailsResponse,
                active: !userData?.active
            }
        );
    }

    const onChangeText = (key: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setUserData({
                ...userData as IUsersDetailsResponse,
                [key]: e.target.value
            })
        }
    }

    const onChangeRoles = (key: string) => {
        return () => {
            let roles = userData.role;
            roles = (includes(roles, key)) ? reject(roles, (role) => role === key) : [...roles, key];

            setUserData({
                ...userData as IUsersDetailsResponse,
                role: roles
            })
        }
    }

    const saveUser = async () => {
        let userDataSend = {...userData};

        if(trim(userDataSend.password)===''){
            delete userDataSend.password;
        }
        await updateUser({
            id: userDataSend.id,
            formData: userDataSend,
        })
        navigate('/admin/users/');
    }

    useEffect(() => {
        if (id) {
            getUserHandler(id);
        }
    }, [id]);

    return (
        <>
            <AdminHeader/>
            {isDataSuccess && userData && (<Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <Box>
                    <Typography variant="h4">
                        {userData.username}
                    </Typography>
                    <Typography variant="body2">
                        Если нет необходимости обновления пароля, оставить пустым!
                    </Typography>
                    <TextField
                        name="password"
                        onChange={onChangeText('password')}
                        id="password"
                        label="Пароль"
                        type="password"
                        value={userData.password}
                    /><br/>

                    <FormControlLabel
                        value={userData.active}
                        onChange={onChangeActive}
                        checked={userData.active}
                        control={<Checkbox/>}
                        label="Активный"
                        labelPlacement="end"
                    />

                    <Typography variant="h5">
                        Доступ
                    </Typography>
                    {roles && map(roles.roles, role => (
                        <Button
                            onClick={onChangeRoles(role.key)}
                            variant={includes(userData.role, role.key) ? 'contained' : 'outlined'}
                            sx={{marginRight: '10px'}}
                            size="small"
                        >
                            {role.label}
                        </Button>
                    ))}

                </Box>
                <Box>
                    <Button variant="contained" sx={{marginTop: '40px'}} onClick={saveUser}>Сохранить</Button>
                </Box>
            </Box>)}

        </>
    )
}
