import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {
    IEmailDistribution,
    IEmailDistributionRequestDetails,
    IEmailDistributionResponseDetails,
    IEmailDistributionUpdateRequestDetails
} from "./models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";


export const emailDistributionApi = createApi({
    reducerPath: 'emailDistributionApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        list: build.query<IEmailDistribution[], void>({
            query: () => ({
                url: 'email/distribution'
            })
        }),
        create: build.query<IEmailDistributionResponseDetails, IEmailDistributionRequestDetails>({
            query: ({formData}) => ({
                url: 'email/distribution',
                method: 'POST',
                body: formData
            })
        }),
        send: build.query<any, { id: number; email: string; } >({
            query: (data) => ({
                url: `email/distribution/${data.id}`,
                method: 'POST',
                body: data
            })
        }),
        sendAll: build.query<any, { id: number } >({
            query: (data) => ({
                url: `email/distribution/send/${data.id}`,
                method: 'POST'
            })
        }),
        update: build.query<IEmailDistributionResponseDetails, IEmailDistributionUpdateRequestDetails>({
            query: (data) => ({
                url: `email/distribution/${data.id}`,
                method: 'PUT',
                body: data.formData
            })
        }),
        getOne: build.query<IEmailDistributionResponseDetails, string>({
            query: (id) => ({
                url: `email/distribution/${id}`,
            })
        }),
    })
});

export const {
    useLazyCreateQuery,
    useLazyUpdateQuery,
    useLazySendAllQuery,
    useListQuery,
    useLazySendQuery,
    useLazyGetOneQuery
} = emailDistributionApi;
