import {Route, Routes} from 'react-router-dom';
import {LoginPage} from './pages/login';
import {ProtectedRoute} from './components/ProtectedRoute';
import {AdminPage} from './pages/admin';
import {CreatePage} from './pages/create';
import {EditPage} from './pages/edit';
import {UploadFile} from './pages/upload';
import {OrderPage} from './pages/Order';
import {OrderListPage} from './pages/order-list';
import {UploadRemainsFile} from "./pages/upload-remains";
import {AsyncTaskPage} from "./pages/async-task";
import {UploadConverterFile} from "./pages/converter";
import {UploadQrFile} from "./pages/qr";
import {includes} from "lodash";
import {useRole} from "./hooks/auth";
import {AdminHeader} from "./pages/header";
import {UserListPage} from "./pages/user-list";
import {UserDetailPage} from "./pages/user-details";
import {UserCreatePage} from "./pages/user-create";
import {SendFormPage} from "./pages/send-form";
import {MultipleOrder} from "./pages/MultiOrder";
import {ShippingCarsList} from "./pages/ShippingCars/Components/ShippingCarsList";
import {ShippingCarsForm} from "./pages/ShippingCars/Components/ShippingCarsForm";
import {OrderLogistics} from "./pages/Order/Components/Logistics";
import {CatalogLost} from "./pages/Catalog/Components/Lost";
import {CatalogUploadData} from "./pages/Catalog/Components/Upload";
import {JackingList} from "./pages/Jacking";
import {ClientsPhonePage} from "./pages/Clients/phone";
import {EmailMainPage} from "./pages/Emails";
import {ClientsStatisticsPage} from "./pages/Clients/Statistics";
import {SkipPhones} from "./pages/Clients/skipPhones";
import {ClientListV2} from "./pages/Clients/list/ClientListV2";
import {LogisticsPage} from "./pages/logistics/orders";
import {DemandPage} from "./pages/logistics/demand";

const App = (): JSX.Element => {
    const {role} = useRole();

    return (
        <Routes>
            <Route path="/login" element={<LoginPage/>}/>

            <Route element={<ProtectedRoute/>} path="/admin/*">
                {(includes(role, 'remains') || includes(role, 'admin')) && (
                    <Route path="upload-remains" element={<UploadRemainsFile/>}/>
                )}
                {(includes(role, 'imageConverter') || includes(role, 'admin')) && (
                    <Route path="upload-image-converter" element={<UploadConverterFile/>}/>
                )}
                {(includes(role, 'qrConverter') || includes(role, 'admin')) && (
                    <Route path="upload-qr" element={<UploadQrFile/>}/>
                )}
                {(includes(role, 'goodsCatalog') || includes(role, 'admin')) && (<>
                    <Route path="catalog" element={<AdminPage/>}/>
                    <Route path="catalog-lost" element={<CatalogLost/>}/>
                    <Route path="catalog-list" element={<CatalogUploadData/>}/>
                    <Route path="create-product" element={<CreatePage/>}/>
                    <Route path="edit-product/:id" element={<EditPage/>}/>
                </>)}
                {(includes(role, 'loadCatalog') || includes(role, 'admin')) && (
                    <Route path="upload-file" element={<UploadFile/>}/>
                )}
                {(includes(role, 'asyncTask') || includes(role, 'admin')) && (
                    <Route path="async-task" element={<AsyncTaskPage/>}/>
                )}
                {(includes(role, 'sendForm') || includes(role, 'admin')) && (
                    <>
                        <Route path="jacking" element={<JackingList/>}/>
                        <Route path="send-list" element={<SendFormPage/>}/>
                    </>
                )}
                {(includes(role, 'shippingCars') || includes(role, 'admin')) && (
                    <>
                        <Route path="shipping/create" element={<ShippingCarsForm/>}/>
                        <Route path="shipping" element={<ShippingCarsList/>}/>
                        <Route path="logistics/demand" element={<DemandPage/>}/>
                        <Route path="logistics" element={<LogisticsPage/>}/>
                    </>
                )}
                {(includes(role, 'clients') || includes(role, 'admin')) && (
                    <>
                        <Route path="clients/phones" element={<ClientsPhonePage/>}/>
                        <Route path="clients/skip" element={<SkipPhones/>}/>
                        <Route path="clients/statistics" element={<ClientsStatisticsPage/>}/>
                        <Route path="clients/new" element={<ClientListV2/>}/>
                    </>
                )}
                {(includes(role, 'orders') || includes(role, 'admin')) && (
                    <>
                        <Route path="order/:id/logistics" element={<OrderLogistics/>}/>
                        <Route path="order/:id" element={<OrderPage/>}/>
                        <Route path="multiple/:id" element={<MultipleOrder/>}/>
                        <Route path="orders" element={<OrderListPage/>}/>
                    </>
                )}
                {(includes(role, 'emails') || includes(role, 'admin')) && (
                    <Route path="emails/*" element={<EmailMainPage/>}/>
                )}
                {(includes(role, 'admin')) && (
                    <>
                        <Route path="users/create" element={<UserCreatePage/>}/>
                        <Route path="users/:id" element={<UserDetailPage/>}/>
                        <Route path="users" element={<UserListPage/>}/>
                    </>
                )}
                <Route index element={<AdminHeader/>}/>
            </Route>

            <Route path="*" element={<LoginPage/>}/>
        </Routes>
    );
};

export default App;
