import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IProduct {
    id: number;
    brDepth: number;
    brHeight: number;
    brWeight: number;
    brWidth: number;
}

interface CounterState {
    isOpen: boolean;
    product: IProduct | null;
}

const initialState = { isOpen: false, product: null } as CounterState

export const counterSlice = createSlice({
    name: 'saveProduct',
    initialState,
    reducers: {
        openModal(state) {
            state.isOpen = true;
        },
        closeModal(state) {
            state.isOpen = false;
        },
        saveProduct(state, action: PayloadAction<IProduct>) {
            state.product = action.payload
        },
        clearProduct(state) {
            state.product = null;
        }
    },
})

export const { openModal, closeModal, saveProduct, clearProduct } = counterSlice.actions
