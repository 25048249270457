import {useLazyGetStatisticsManagerQuery} from "../../../store/protected/clients/clients.api";
import {AdminHeader} from "../../header";
import React, {useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import moment from "moment/moment";
import {find, map, reduce} from "lodash";
import {IClientManager, IClientManagerResponse} from "../../../store/protected/clients/models";
import {useGetUsersQuery} from "../../../store/protected/users/users.api";


const theme = createTheme(
    {},
    ruRU,
);

const columnsAll: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Название статуса',
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'counter',
        headerName: 'Количество',
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
]

export function ClientsStatisticsPage(): JSX.Element {
    const [getClientStatistics, {
        isSuccess: isDataSuccess, data
    }] = useLazyGetStatisticsManagerQuery();
    const [selectedDate, handleDateChange] = useState(moment().format("YYYY-MM-DD"));

    // @ts-ignore
    const {data: users} = useGetUsersQuery({page: 1, limit: 200}, {refetchOnMountOrArgChange: true});
    const changeDate = (e: React.ChangeEvent<HTMLInputElement>): void => {
        handleDateChange(moment(e.target.value).format("YYYY-MM-DD"));
    }

    const handleRefreshData = (): void => {
        getClientStatistics({date: selectedDate});
    }

    const getAllCount = (data: IClientManager[]): number => {
        return reduce(data, (acc, element) => {
            return acc + (+element.counter);
        }, 0);
    }

    const resultData = !!data?.all.length ? [
        ...data.all,
        {counter: getAllCount(data.all), id: 9999, name: 'Всего'}
    ] : false;

    return (
        <>
            <AdminHeader/>
            <Box sx={{display: 'flex', alignItems: 'center', marginY: '20px'}}>
                Дата
                <input type="date"
                       value={selectedDate}
                       onChange={changeDate}
                />
                <Button variant="contained" color="primary" size="small" onClick={handleRefreshData}
                        sx={{marginLeft: '10px'}}>
                    Получить данные
                </Button>
            </Box>
            <Box sx={{height: '600px'}}>
                {resultData &&
                    <>
                        <Typography component="div" variant="h6" sx={{marginBottom: 2}}>Всего</Typography>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                disableRowSelectionOnClick={true}
                                rows={resultData}
                                hideFooter
                                getRowHeight={() => 'auto'}
                                columns={columnsAll}
                                slotProps={{
                                    panel: {
                                        sx: {
                                            top: '-120px !important',
                                        },
                                    },
                                }}
                                sx={{
                                    '& .MuiDataGrid-row:hover': {
                                        cursor: 'pointer',
                                    },
                                    height: 'auto'
                                }}
                            />
                        </ThemeProvider>
                        {data.managers && map(data.managers, (manager, key) => {
                            const resultData = !!manager.length ? [
                                ...manager,
                                {counter: getAllCount(manager), id: 9999, name: 'Всего'}
                            ] : null;

                            if (!resultData) {
                                return null;
                            }
                            let managerName = 'Общий';
                            const user = find(users.data, (user) => {
                                return user.id === +key
                            })
                            if (user) {
                                managerName = user.username;
                            }

                            return (
                                <>
                                    <Typography component="div" variant="h6"
                                                sx={{mb: 2, mt: 2}}>{managerName}</Typography>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            disableRowSelectionOnClick={true}
                                            rows={resultData}
                                            hideFooter
                                            getRowHeight={() => 'auto'}
                                            columns={columnsAll}
                                            slotProps={{
                                                panel: {
                                                    sx: {
                                                        top: '-120px !important',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiDataGrid-row:hover': {
                                                    cursor: 'pointer',
                                                },
                                                height: 'auto'
                                            }}
                                        />
                                    </ThemeProvider>
                                </>
                            )
                        })}
                    </>}
            </Box>
        </>
    )
}
