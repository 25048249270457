import {useGetProductsQuery} from "../store/protected/products/products.api";
import {ProductList} from "./product-list";
import {IProductDataResponse} from "../models";
import {ChangeEvent, useState} from "react";
import {SelectChangeEvent} from "@mui/material";

const LIMIT = 50;
const HEADER = 'Список продуктов';

export interface IFilter {
    _catalog: number | null;
    _image: number | null;
    _brand: string | null;
    _sku: string | null;
}

export function AdminPage(): JSX.Element {
    const [filter, setFilter] = useState<IFilter>({ _catalog: -1, _image: -1, _brand: null, _sku: null});
    const [page, setPage] = useState<number>(1);
    const {
        data = {} as IProductDataResponse,
        isSuccess,
        isError, isLoading
    } = useGetProductsQuery({limit: LIMIT.toString(), page, filter}, {refetchOnMountOrArgChange: true});

    const onClick = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    }

    const onChange = (event: SelectChangeEvent<number | string | null>) =>{
        setFilter({
            ...filter,
            [event.target.name]: event.target.value
        })
    }

    const onChangeText = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        setFilter({
            ...filter,
            [event.target.name]: event.target.value
        })
    }

    return <ProductList
        data={data.data}
        isSuccess={isSuccess}
        handleChange={onChange}
        handleTextChange={onChangeText}
        filter={filter}
        isError={isError}
        header={HEADER}
        onClick={onClick}
        isLoading={ isLoading }
        pages={ data.pages }
    />
}
