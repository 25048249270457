import React, {useState} from "react";
import {Alert, Button, Snackbar, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {useLazySendQuery} from "./email.chain.api";

interface IProps {
    id: number;
}

export const SendEmail = ({id}: IProps): JSX.Element => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [emailSend, setEmailSend] = useState<string>('');
    const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
    const [sendEmail] = useLazySendQuery();
    const [error, setError] = useState<string>('');
    const setEmailSendHandler = (data: React.ChangeEvent<HTMLInputElement>): void => {
        setEmailSend(data.target.value);
    }

    const sendEmailHandler = async () => {
        try {
            setDisabled(true);
            const data = await sendEmail({id, email: emailSend}).unwrap();
            setIsSnackbarVisible(true);
            console.log(data);
            //navigate('/admin/emails/common/list');

        } catch (e: any) {
            if (e?.data?.error) {
                setError(e?.data?.message as string)
            }
            console.log(e)
        } finally {
            setDisabled(false);
        }
    }

    return (<Box sx={{alignItems: 'center', display: 'flex', flexWrap: 'wrap'}}>
        {error && (<Alert severity="error" sx={{marginBottom: '30px', width:'100px'}}>{error}</Alert>)}
        <TextField
            name="sendEmail"
            onChange={setEmailSendHandler}
            id="sendEmail"
            label="Email"
            value={emailSend}
            sx={{mr: 2, width: '400px'}}
            autoFocus
        />
        <Button variant="contained" disabled={disabled} onClick={sendEmailHandler}>Тест письма</Button>
        <Snackbar
            open={isSnackbarVisible}
            autoHideDuration={2000}
            onClose={() => {
                setIsSnackbarVisible(false)
            }}
            sx={{top: 0, right: 0}}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <Alert severity="success" sx={{width: '100%'}}>
                Отправлено
            </Alert>
        </Snackbar>
    </Box>)
}
