import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {store} from './store';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {authApi} from './store/protected/auth/auth.api';
import {authSlice} from './store/protected/auth/auth.slice';

async function main(): Promise<void> {
    if (localStorage.getItem('token')) {
        const {isSuccess} = await store.dispatch(authApi.endpoints.checkToken.initiate());

        if (isSuccess && localStorage.getItem('role')) {
            const role: string[] = JSON.parse(localStorage.getItem('role') as string);
            await store.dispatch(authSlice.actions.setIsLoggedIn());
            await store.dispatch(authSlice.actions.setRole(role));
        }
    }

    /* === Recalculate 1vh === */
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    /* === Recalculate 1vh === */

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
}

main();


