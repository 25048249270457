import React, {useState} from 'react';
import {Select, MenuItem, SelectChangeEvent, InputLabel, FormControl} from '@mui/material';
import isNil from "lodash/isNil";
import {useGetListStatusesQuery, useLazyUpdateFieldValueQuery} from "../../../../store/protected/clients/clients.api";
import {DisabledBox} from "../Disabled";

export interface Status {
    id: number;
    name: string;
}

interface StatusSelectProps {
    currentStatus: Status;
    clientId: number;
    clientUserId?: number;
    userId: number;
}

export const StatusSelect = (props: StatusSelectProps): React.ReactElement => {
    const {clientUserId, userId, currentStatus, clientId} = props;
    const [updateField, {error}] = useLazyUpdateFieldValueQuery();
    const {
        data: clientStatuses,
    } = useGetListStatusesQuery(null, {refetchOnMountOrArgChange: true});
    const [componentStatus, setComponentStatus] = useState(currentStatus);
    const handleChange = async (event: SelectChangeEvent) => {
        // @ts-ignore
        const newStatusId = event.target.value as number;
        const newStatus = clientStatuses.find(s => s.id === newStatusId);

        if (newStatus) {
            updateField({
                value: newStatus.id,
                field: 'statusId',
                clientId
            });
            setComponentStatus(newStatus);
        }
    };


    return (
        <DisabledBox disabled={ userId !== clientUserId}
                     sx={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <>
                <FormControl sx={{mb: 1, width: '100%',}} size="small">
                    <InputLabel id="status-checkbox-label-details">Статус</InputLabel>
                    <Select
                        value={componentStatus.id.toString()}
                        size="small"
                        label="Статус"
                        sx={{width:'100%'}}
                        labelId="status-checkbox-label-details"
                        onChange={handleChange}
                    >
                        {clientStatuses.map(status => (
                            <MenuItem key={status.id} value={status.id}>
                                {status.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {error}
            </>
        </DisabledBox>
    );
}
