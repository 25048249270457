import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React from "react";
import {IEmailElement} from "./models";
import {reduce, size} from "lodash";
import {DateTime} from "luxon";
import isNil from "lodash/isNil";


const theme = createTheme(
    {},
    ruRU,
);


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 60,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'status',
        headerName: 'Статус',
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'answer',
        headerName: 'Отправлен',
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: any) => {
            if (isNil(params.value)) {
                return null;
            }
            const data = JSON.parse(params.value);
            const answer = size(data.accepted) > 0 ? 'Отправлен' : 'Отклонен'
            return (<span>{answer}</span>);
        },

    },
];

interface IProps {
    data: IEmailElement[];
}

export const EmailsList = ({data}: IProps): JSX.Element => {
    console.log(data);
    if (!data || size(data) === 0) {
        return null;
    }
    const resultStat = reduce(data, (acc, row) => {
        try {
            const decoded = JSON.parse(row.answer);
            if (size(decoded.accepted) > 0) {
                acc.accepted++;
            } else {
                acc.reject++;
            }
        } catch (e) {
            console.log(e);
        }
        return acc;
    }, {accepted: 0, reject: 0})

    return (<Box sx={{height: '300px', width: '100%'}}>
        <ThemeProvider theme={theme}>
            <DataGrid
                disableRowSelectionOnClick={true}
                rows={data}
                getRowHeight={() => 'auto'}
                columns={columns}
                slotProps={{
                    panel: {
                        sx: {
                            top: '-120px !important',
                        },
                    },
                }}
                sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    }
                }}
            />
        </ThemeProvider>
        {`Отправлен: ${resultStat.accepted} - Отклонен: ${resultStat.reject}`}
    </Box>)
}
