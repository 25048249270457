import {createApi} from '@reduxjs/toolkit/query/react';
import {IShippingCars, IShippingCarStatus} from "../models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const shippingCarsApi = createApi({
    reducerPath: 'shippingCarsApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getList: build.query<IShippingCars[], void>({
            query: () => ({
                url: `shipping-cars`,
            })
        }),
        getStatuses: build.query<IShippingCarStatus[], void>({
            query: () => ({
                url: `shipping-cars/statuses`,
            })
        }),
        updateStatus: build.query<void, { id: number, status: number }>({
            query: ({id, status}) => ({
                url: `shipping-cars/${id}`,
                method: 'PATCH',
                body: {
                    status,
                },
            }),
        }),
        create: build.query<void, { name: string }>({
            query: ({name}) => ({
                url: `shipping-cars`,
                method: 'POST',
                body: {
                    name,
                },
            }),
        }),
    })
})

export const {
    useGetListQuery,
    useGetStatusesQuery,
    useLazyUpdateStatusQuery,
    useLazyCreateQuery
} = shippingCarsApi;
