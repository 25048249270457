import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {copyToClipboard, renderCellWithCopy, RenderCellWithLink} from "../../../utils/OrderColumns";

export const getMainColumns = (brandFilterValues: string[]): GridColDef[] => {
    return [
        // ID
        {
            field: 'index',
            headerName: 'ID',
            flex: 0.3,
            filterable: false,
            disableColumnMenu: true,
        },

        // Артикул внутренний
        {
            field: 'productNumber',
            headerName: 'Арт. вн.',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Артикул
        {
            field: 'sku',
            headerName: 'Артикул',
            flex: 1,
            renderCell: RenderCellWithLink
        },

        // Бренд
        {
            field: 'brand',
            headerName: 'Бренд',
            flex: 0.7,
            type: 'singleSelect',
            valueOptions: brandFilterValues,
        },

        {
            field: 'priceRrp',
            headerName: 'Цена (Р)',
            type: 'number',
            filterable: false,
            sortable: true,
            disableColumnMenu: true,
        },

        {
            field: 'quantity',
            headerName: 'Кол-во',
            type: 'number',
            flex: 0.2,
            filterable: false,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (cellValues.value ? cellValues.value : 1)
            },
        },

        // Ширина
        {
            field: 'brWidth',
            headerName: 'Ш. (m)',
            editable: true,
            type: 'number',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Высота
        {
            field: 'brHeight',
            headerName: 'В. (m)',
            editable: true,
            type: 'number',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Глубина
        {
            field: 'brDepth',
            headerName: 'Г. (m)',
            editable: true,
            type: 'number',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Вес
        {
            field: 'brWeight',
            headerName: 'Вес (kg)',
            editable: true,
            type: 'number',
            flex: 0.5,
            renderCell: (cellValues: GridRenderCellParams) => (
                <>
                    {cellValues.row.quantity ? cellValues.value * cellValues.row.quantity : cellValues.value}
                    <IconButton onClick={() => copyToClipboard(cellValues.value)} color="default" size="small"
                                sx={{ml: 1}}>
                        <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                </>
            ),
        },

        // Объем
        {
            field: 'grossVolume',
            headerName: 'О. (m³)',
            type: 'number',
            flex: 0.5,
            renderCell: (cellValues: GridRenderCellParams) => (
                <>
                    {cellValues.row.quantity ? (cellValues.value * cellValues.row.quantity).toFixed(2) : cellValues.value.toFixed(2)}
                    <IconButton onClick={() => copyToClipboard(cellValues.value)} color="default" size="small"
                                sx={{ml: 1}}>
                        <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                </>
            ),
        },
        // Объем 2
        {
            field: 'gross',
            headerName: 'О. Бр (m³)',
            type: 'number',
            flex: 0.5,
            renderCell: (cellValues: GridRenderCellParams) => (
                <>
                    {cellValues.row?.gross?.toFixed(2)}
                    <IconButton onClick={() => copyToClipboard(cellValues.row?.gross?.toFixed(2))} color="default" size="small"
                                sx={{ml: 1}}>
                        <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                </>
            ),
        },

        // Перевозка Европа - РФ
        {
            field: 'transferEuroRussia',
            headerName: 'EUR - РФ (€)',
            editable: true,
            type: 'number',
            flex: 0.5,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellWithCopy,
        },

        // Перевозка Европа - РФ
        {
            field: 'transferEuroRussiaBr',
            headerName: 'EUR - РФ БР(€)',
            editable: true,
            type: 'number',
            flex: 0.5,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellWithCopy,
        },

        // Перевозка по Европе
        {
            field: 'transferEuro',
            headerName: 'По Европе (€)',
            editable: true,
            type: 'number',
            flex: 0.5,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },
    ];
};

export const getMainTotalColumns = (): GridColDef[] => {
    return [
        {field: 'index', flex: 0.3, renderCell: () => (<></>)},
        {field: 'productNumber', flex: 0.4},
        {field: 'sku', flex: 1},
        {field: 'brand', flex: 0.7},
        {field: 'priceRrp'},
        {field: 'quantity', flex: 0.2},
        {field: 'brWidth', flex: 0.4},
        {field: 'brHeight', flex: 0.4},
        {field: 'brDepth', flex: 0.4},
        {field: 'brWeight', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
        {field: 'grossVolume', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
        {field: 'grossBr', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
        {field: 'transferEuroRussia', flex: 0.5},
        {field: 'transferEuroRussiaBr', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
        {field: 'transferEuro', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
    ];
};
