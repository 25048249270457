import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {
    IBrand,
    IClientDateRequest,
    IClientDateResponse,
    IClientDetailsRequest,
    IClientManagerRequest,
    IClientManagerResponse,
    IClientRequest, IClients,
    IClientsResponse,
    IUpdateFieldValueRequest,
    Status
} from "./models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const clientsApi = createApi({
    reducerPath: 'clientsApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getList: build.query<IClientsResponse, IClientRequest>({
            query: (params) => ({
                url: 'clients',
                params
            })
        }),
        getClient: build.query<IClients, IClientDetailsRequest>({
            query: (params) => ({
                url: `clients/data/${params.id}`,
            })
        }),
        getListV2: build.query<IClientsResponse, IClientRequest>({
            query: (params) => ({
                url: 'clients/list/new',
                params
            })
        }),
        getStatistics: build.query<IClientsResponse, IClientRequest>({
            query: (params) => ({
                url: 'clients/statistics',
                params
            })
        }),
        getStatisticsManager: build.query<IClientManagerResponse, IClientManagerRequest>({
            query: (params) => ({
                url: 'clients/statistics-manager',
                params
            })
        }),
        getStatisticsDate: build.query<IClientDateResponse, IClientDateRequest>({
            query: (params) => ({
                url: 'clients/statistics-date',
                params
            })
        }),
        getListStatuses: build.query<Status[], void>({
            query: () => ({
                url: 'clients/statuses',
            })
        }),
        getBrands: build.query<IBrand[], void>({
            query: () => ({
                url: 'clients/brands',
            })
        }),
        getBrandsGroup: build.query<IBrand[], void>({
            query: () => ({
                url: 'clients/brands/group',
            })
        }),
        updateFieldValue: build.query<IUpdateFieldValueRequest, IUpdateFieldValueRequest>({
            query: (params) => ({
                url: 'clients/field',
                body: params,
                method: 'POST',
            })
        }),
    })
});

export const {
    useLazyGetListQuery,
    useLazyGetListV2Query,
    useLazyUpdateFieldValueQuery,
    useGetListStatusesQuery,
    useLazyGetClientQuery,
    useLazyGetStatisticsManagerQuery,
    useGetBrandsQuery,
    useGetBrandsGroupQuery,
    useGetStatisticsQuery,
    useLazyGetStatisticsDateQuery
} = clientsApi;
