import React, {useState} from 'react';
import {
    Alert,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useLazyUpdateWithCodeProductQuery} from "../../../store/protected/products/products.api";
import {useAppDispatch, useAppSelector} from "../../../hooks/store";
import {clearProduct, closeModal} from "../store/api";

export function SaveProduct(): JSX.Element {
    const dispatch = useAppDispatch()
    const [saveProduct] = useLazyUpdateWithCodeProductQuery();
    const isOpenPrompt = useAppSelector(state => state.saveProduct.isOpen);
    const row = useAppSelector(state => state.saveProduct.product);
    const [code, setCode] = useState<string>(null);
    const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);
    const [isErrorSnack, setIsErrorSnack] = useState<boolean>(false);

    const setCodeHandler = (e: any) => {
        setCode(e.target.value);
    }


    const handleCloseModal = (): void => {
        dispatch(closeModal())
        setCode(null);
    };

    const handleCloseSnack = (): void => {
        setIsOpenSnack(false);
        setIsErrorSnack(false);
    }


    const saveProductHandler = async (): Promise<void> => {
        const data = await saveProduct({
            formData: row,
            id: row.id.toString(),
            code: code || '',
        });
        console.log(data);
        if (data?.error) {
            setIsErrorSnack(true);
        }
        dispatch(clearProduct());
        setIsOpenSnack(true);
        handleCloseModal();
    }

    return (
        <>
            <Dialog
                open={isOpenPrompt}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id={`alert-dialog-title_save-product`}>
                    Сохранить данные по продукту?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id={`alert-dialog-description_save-product`}
                        sx={{display: 'flex', flexDirection: 'column'}}
                    >
                        <Typography sx={{flexGrow: 1}} component="span">
                            Данные будут применены для будущих заказов, изменения нельзя будет отменить?
                        </Typography>
                        <TextField
                            label="Пароль"
                            variant="standard"
                            name={`alert-input-code-save-product`}
                            value={code}
                            onChange={setCodeHandler}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Отмена</Button>
                    <Button onClick={saveProductHandler} autoFocus>
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={isOpenSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <div>
                    {!isErrorSnack && (<Alert onClose={handleCloseSnack} severity="success" sx={{width: '100%'}}>
                        Сохранение прошло успешно
                    </Alert>)}

                    {isErrorSnack && (<Alert onClose={handleCloseSnack} severity="error" sx={{width: '100%'}}>
                        Что-то пошло не так!
                    </Alert>)}
                </div>
            </Snackbar>
        </>
    )
};
