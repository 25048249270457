import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {
    IEmailChain,
    IEmailChainElementsRequestDetails,
    IEmailChainResponseDetails,
    IEmailChainUpdateRequestDetails
} from "./models";
import {IBrand} from "../../../store/protected/clients/models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";


export const emailChainApi = createApi({
    reducerPath: 'emailChainApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        list: build.query<IEmailChain[], void>({
            query: () => ({
                url: 'email/chain'
            })
        }),
        createMain: build.query<IEmailChainResponseDetails, IEmailChain>({
            query: (data) => ({
                url: 'email/chain',
                method: 'POST',
                body: data
            })
        }),
        getBrands: build.query<IBrand[], void>({
            query: () => ({
                url: 'clients/brands',
            })
        }),
        updateMain: build.query<IEmailChainResponseDetails, IEmailChain>({
            query: (data) => ({
                url: `email/chain/${data.id}`,
                method: 'PUT',
                body: data
            })
        }),
        send: build.query<any, { id: number; email: string; } >({
            query: (data) => ({
                url: `email/chain/${data.id}`,
                method: 'POST',
                body: data
            })
        }),
        sendAll: build.query<any, { id: number } >({
            query: (data) => ({
                url: `email/chain/send/${data.id}`,
                method: 'POST'
            })
        }),
        update: build.query<IEmailChainResponseDetails, IEmailChainUpdateRequestDetails>({
            query: (data) => ({
                url: `email/chain/${data.chain}/${data.id}`,
                method: 'PUT',
                body: data.formData
            })
        }),
        create: build.query<IEmailChainResponseDetails, IEmailChainElementsRequestDetails>({
            query: (data) => ({
                url: `email/chain/${data.chain}`,
                method: 'POST',
                body: data.formData
            })
        }),
        getOne: build.query<IEmailChainResponseDetails, string>({
            query: (id) => ({
                url: `email/chain/${id}`,
            })
        }),
    })
});

export const {
    useGetBrandsQuery,
    useLazyCreateQuery,
    useLazyUpdateQuery,
    useLazyCreateMainQuery,
    useLazyUpdateMainQuery,
    useLazySendAllQuery,
    useListQuery,
    useLazySendQuery,
    useLazyGetOneQuery
} = emailChainApi;
