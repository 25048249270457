import { createApi } from '@reduxjs/toolkit/query/react';
import {IMultiOrderResponse} from "../Models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const multiOrderApi = createApi({
    reducerPath: 'multiOrderApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getMultiOrder: build.query<IMultiOrderResponse, { id: string }>({
            query: ({ id }) => ({
                url: `multi-order/${id}`,
            })
        }),
        addOrderToMultiOrder: build.query<{multi: number}, { id: string, orderId: string }>({
            query: ({ id, orderId }) => ({
                url: `multi-order`,
                method: 'PATCH',
                body: {
                    _orderId: orderId,
                    _multiOrderId: id,
                },
            }),
        }),
        removeOrderFromMultiOrder: build.query<{multi: number}, { id: string, orderId: string }>({
            query: ({ id, orderId }) => ({
                url: `multi-order`,
                method: 'DELETE',
                body: {
                    _orderId: orderId,
                    _multiOrderId: id,
                },
            }),
        }),
    }),
});

export const {
    useLazyGetMultiOrderQuery,
    useGetMultiOrderQuery,
    useLazyAddOrderToMultiOrderQuery,
    useLazyRemoveOrderFromMultiOrderQuery,
} = multiOrderApi;
