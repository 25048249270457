import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {useEffect} from "react";
import moment from "moment";
import 'moment/locale/ru';
import {AdminHeader} from "../header";
import {useLazyGetSendQuery} from "./store/jacking.api";
import {map} from "lodash";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
    },
    {
        field: 'create_at', headerName: 'Дата', width: 200,
        valueGetter: (params) => moment(params.value).locale('ru').format('L LTS')
    },
    {
        width: 600,
        field: 'data',
        renderCell: (cellValues: GridRenderCellParams): JSX.Element => {
            try {
                const data = JSON.parse(cellValues.value);
                const string = [];
                for (const key in data) {
                    string.push(`${key} - ${data[key]}`);
                }

                return (<div>{
                    map(string, (element) => {
                        return (
                            <div>{element}</div>
                        )
                    })
                }</div>);
            }catch (e){
                return <div/>;
            }
        }
    }
];

export const JackingList = (): JSX.Element => {
    const [getSend, {data, isSuccess}] = useLazyGetSendQuery();

    useEffect(() => {
        getSend({limit: 5000, page: 1});
    }, [getSend]);

    return isSuccess && !!data?.data.length ? (
        <>
            <AdminHeader/>
            <Box sx={{height: 631, width: '100%'}}>
                {!!data?.data.length && <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data.data}
                    columns={columns}
                    getRowHeight={() => 'auto'}

                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />}
            </Box>
        </>

    ) : <div>Нет данных</div>;
}
