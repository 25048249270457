import { createApi } from '@reduxjs/toolkit/query/react';
import { ILoginResponse, ILoginRequest } from '../../../models';
import {IUsersRegistration} from "../users/users.dto";
import {ICheckToken} from "./models";
import {baseQuery} from "../../../consts/baseQuery";

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQuery,
    endpoints: (build) => ({
        login: build.mutation<ILoginResponse, ILoginRequest>({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials,
            }),
        }),
        register: build.query<any, IUsersRegistration>({
            query: (credentials) => ({
                url: 'auth/register',
                method: 'POST',
                body: credentials,
            }),
        }),

        checkToken: build.query<ICheckToken, void>({
            query: () => ({
                url: 'auth/check',
            }),
        }),
    }),
});

export const { useLoginMutation, useCheckTokenQuery, useLazyRegisterQuery } = authApi;
