import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {authApi} from './protected/auth/auth.api';
import {authReducer} from './protected/auth/auth.slice';
import {ordersApi} from './protected/orders/orders.api';
import {productsApi} from './protected/products/products.api';
import {productPublicApi} from './public/product.api';
import {asyncTaskApi} from "./protected/async-task/async-task.api";
import {converterApi} from "./protected/converter/converter.api";
import {remainsApi} from "./protected/remains/remains.api";
import {getOrderApi} from "./protected/orders/getOrder.api";
import {usersApi} from "./protected/users/users.api";
import {sendApi} from "./protected/send/send.api";
import {multiOrderApi} from "../pages/MultiOrder/store/api";
import {counterSlice} from "../pages/Order/store/api";
import {shippingCarsApi} from "../pages/ShippingCars/store/api";
import {orderLostApi} from "../pages/Catalog/Components/Lost/store/api";
import {jackingApi} from "../pages/Jacking/store/jacking.api";
import {clientsApi} from "./protected/clients/clients.api";
import {emailCommonApi} from "../pages/Emails/Common/email.common.api";
import {emailDistributionApi} from "../pages/Emails/Distribution/email.distrubution.api";
import {emailChainApi} from "../pages/Emails/Сhain/email.chain.api";
import {logisticsApi} from "../pages/logistics/store/logistics.api";

export const store = configureStore({
    reducer: {
        [productPublicApi.reducerPath]: productPublicApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [counterSlice.name]: counterSlice.reducer,
        [asyncTaskApi.reducerPath]: asyncTaskApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [ordersApi.reducerPath]: ordersApi.reducer,
        [clientsApi.reducerPath]: clientsApi.reducer,
        [emailCommonApi.reducerPath]: emailCommonApi.reducer,
        [emailDistributionApi.reducerPath]: emailDistributionApi.reducer,
        [emailChainApi.reducerPath]: emailChainApi.reducer,
        [getOrderApi.reducerPath]: getOrderApi.reducer,
        [multiOrderApi.reducerPath]: multiOrderApi.reducer,
        [converterApi.reducerPath]: converterApi.reducer,
        [remainsApi.reducerPath]: remainsApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [sendApi.reducerPath]: sendApi.reducer,
        [orderLostApi.reducerPath]: orderLostApi.reducer,
        [shippingCarsApi.reducerPath]: shippingCarsApi.reducer,
        [jackingApi.reducerPath]: jackingApi.reducer,
        [logisticsApi.reducerPath]: logisticsApi.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        productPublicApi.middleware,
        productsApi.middleware,
        asyncTaskApi.middleware,
        authApi.middleware,
        ordersApi.middleware,
        clientsApi.middleware,
        emailCommonApi.middleware,
        emailDistributionApi.middleware,
        emailChainApi.middleware,
        getOrderApi.middleware,
        multiOrderApi.middleware,
        converterApi.middleware,
        remainsApi.middleware,
        usersApi.middleware,
        sendApi.middleware,
        orderLostApi.middleware,
        jackingApi.middleware,
        logisticsApi    .middleware,
        shippingCarsApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
