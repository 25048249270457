import {Link, Route, Routes} from "react-router-dom";
import {EmailCommonList} from "./Common/List";
import {AdminHeader} from "../header";
import React from "react";
import Grid from "@mui/material/Grid";
import {Button} from "@mui/material";
import {EmailCommonForm} from "./Common/Form";
import {EmailDistributionForm} from "./Distribution/Form";
import {EmailDistributionList} from "./Distribution/List";
import {EmailChainList} from "./Сhain/List";
import {EmailChainForm} from "./Сhain/Form";

export const EmailMainPage = (): JSX.Element => {
    return (
        <>
            <AdminHeader/>
            <Grid item xs={12} marginBottom={2}>
                <Button variant="contained" size="small" sx={{mr: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/emails/common/list">Список почт</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/emails/common/create">Создать почту</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/emails/distribution/list">Список рассылок</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/emails/distribution/create">Создать рассылку</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/emails/chain/list">Список цепочек</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/emails/chain/create">Создать чепочку</Link>
                </Button>
            </Grid>
            <Routes>
                <Route path="common/list" element={<EmailCommonList/>}/>
                <Route path="common/create" element={<EmailCommonForm/>}/>
                <Route path="common/:id" element={<EmailCommonForm/>}/>

                <Route path="distribution/list" element={<EmailDistributionList/>}/>
                <Route path="distribution/create" element={<EmailDistributionForm/>}/>
                <Route path="distribution/:id" element={<EmailDistributionForm/>}/>

                <Route path="chain/list" element={<EmailChainList/>}/>
                <Route path="chain/create" element={<EmailChainForm/>}/>
                <Route path="chain/:id" element={<EmailChainForm/>}/>
            </Routes>
        </>)
}
