import Box from '@mui/material/Box';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {useEffect} from "react";
import {AdminHeader} from "./header";
import {useLazyGetSendQuery} from "../store/protected/send/send.api";
import {Link} from "react-router-dom";
import moment from "moment";
import 'moment/locale/ru';
import {REMOTE_SERVER} from "../consts";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
    },
    {field: 'email', headerName: 'Email', width: 250},
    {
        field: 'productId', headerName: 'Продукт', width: 500,
        renderCell: (params) => {
            console.log(params.value)
            return (
                <Link target="_blank" to={`/admin/edit-product/${params.value.id}`}>
                    {`${params.value?.brand} ${params.value?.productName} ${params.value?.sku}`}
                </Link>
            )
        }
    },
    {
        field: 'create_at', headerName: 'Дата', width: 200,
        valueGetter: (params) => moment(params.value).locale('ru').format('L LTS')
    }
];

export const SendFormPage = (): JSX.Element => {
    const [getSend, {data, isSuccess}] = useLazyGetSendQuery();

    useEffect(() => {
        getSend({limit: 5000, page: 1});
    }, [getSend]);

    return isSuccess && !!data?.data.length ? (
        <>
            <AdminHeader/>
            <Link to={`${REMOTE_SERVER}send/excel`} style={ { marginBottom:'10px', display: 'inline-block' } } target="_blank">Скачать excel выгрузку</Link>
            <Box sx={{height: 631, width: '100%'}}>
                {!!data?.data.length && <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data.data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />}
            </Box>
        </>

    ) : <div>Нет данных</div>;
}
