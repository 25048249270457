import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useGetRolesQuery} from "../store/protected/users/users.api";
import {AdminHeader} from "./header";
import Box from "@mui/material/Box";
import {Alert, Button, TextField, Typography} from "@mui/material";
import {IUsersRegistration} from "../store/protected/users/users.dto";
import {includes, map, reject} from "lodash";
import {useLazyRegisterQuery} from "../store/protected/auth/auth.api";

const INITIAL_STATE: IUsersRegistration = {
    role: [],
    username: '',
    password: '',
    active: true,
}

export function UserCreatePage(): JSX.Element {
    const [userData, setUserData] = useState<IUsersRegistration>(INITIAL_STATE);
    const {data: roles} = useGetRolesQuery();
    const [register] = useLazyRegisterQuery();
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');

    const onChangeRoles = (key: string) => {
        return () => {
            let roles = userData.role;
            roles = (includes(roles, key)) ? reject(roles, (role) => role === key) : [...roles, key];

            setUserData({
                ...userData as IUsersRegistration,
                role: roles
            })
        }
    }

    const onChangeText = (key: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setUserData({
                ...userData as IUsersRegistration,
                [key]: e.target.value
            })
        }
    }

    const saveUser = async () => {
        try {
            await register(userData).unwrap();
            navigate('/admin/users/');

        } catch (e: any) {
            console.log(e)
            if (e?.data?.error) {
                setError(e?.data?.message?.join(', ') as string)
            }
        }
        //
    }

    return (
        <>
            <AdminHeader/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                {error && (<Alert severity="error" sx={{marginBottom: '30px'}}>{error}</Alert>)}
                <Box>
                    <TextField
                        name="username"
                        onChange={onChangeText('username')}
                        id="username"
                        label="Логин"
                        value={userData.username}
                        sx={{mr: 2}}
                        autoFocus
                    />
                    <TextField
                        name="password"
                        onChange={onChangeText('password')}
                        id="password"
                        label="Пароль"
                        type="password"
                        value={userData.password}
                    />


                    <Typography variant="h5" sx={{mb: 2, mt: 2}}>
                        Доступ
                    </Typography>
                    {roles && map(roles.roles, role => (
                        <Button
                            onClick={onChangeRoles(role.key)}
                            variant={includes(userData.role, role.key) ? 'contained' : 'outlined'}
                            sx={{marginRight: '10px'}}
                            size="small"
                        >
                            {role.label}
                        </Button>
                    ))}

                </Box>
                <Box>
                    <Button variant="contained" sx={{marginTop: '40px'}} onClick={saveUser}>Сохранить</Button>
                </Box>
            </Box>

        </>
    )
}
