import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import isNil from "lodash/isNil";
import {useAsyncTaskListQuery} from "../store/protected/async-task/async-task.api";
import {IAsyncTaskResponse} from "../store/protected/async-task/models";
import {AdminHeader} from "./header";

export function AsyncTaskPage(): JSX.Element {
    const {
        data = {} as IAsyncTaskResponse[],
        isSuccess,
    } = useAsyncTaskListQuery({}, {refetchOnMountOrArgChange: true});

    console.log(data);
    return (
        <>
            <AdminHeader/>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                UTC
                            </TableCell>
                            <TableCell>
                                Название
                            </TableCell>
                            <TableCell>
                                Статус
                            </TableCell>
                            <TableCell>
                                Лог
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Loader mb */}
                        {!isNil(data) && isSuccess && data.map(({creationTime, status, title, logs}) => (
                            <TableRow>
                                <TableCell>{creationTime}</TableCell>
                                <TableCell>{title}</TableCell>
                                <TableCell>{status}</TableCell>
                                <TableCell>{logs}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
