import { useMemo } from 'react';
import { useAppSelector } from './store';

export const useAuth = () => {
    const { isLoggedIn } = useAppSelector(state => state.auth);

    return useMemo(() => ({ isLoggedIn }), [isLoggedIn]);
};

export const useRole = () => {
    const {role} = useAppSelector(state => state.auth);

    return useMemo(() => ({ role }), [role]);
}
