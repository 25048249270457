import React, {ReactElement} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import {useGetDataQuery} from "../store/logistics.api";
import {AdminHeader} from "../../header";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";


const theme = createTheme(
    {},
    ruRU,
);

const columns: GridColDef[] = [
    {
        field: 'projectNumber',
        headerName: 'Проект',
        width: 60,

        sortable: false,
    },
    {
        field: 'agent',
        headerName: 'Клиент',
        width: 150,

        sortable: false,
    },
    {
        field: 'productName',
        headerName: 'Наименование',
        width: 350,
        sortable: false,
    },
    {
        field: 'planingDate',
        headerName: 'План. дата приемки',
        width: 150,
        sortable: false,
    },
    {
        field: 'countPosition',
        headerName: 'Кол-во всего',
        width: 150,
        sortable: false,
    },
    {
        field: 'supplyPositions',
        headerName: 'Кол-во принято',
        width: 150,
        sortable: false,
    },
    {
        field: 'supplyStatus',
        headerName: 'Статус приемки',
        width: 150,
        sortable: false,
    },
    {
        field: 'supplierId',
        headerName: '№поставщику',
        width: 150,

        sortable: false,
    },
    {
        field: 'supplier',
        headerName: 'Контрагент',
        width: 100,

        sortable: false,
    },

    {
        field: 'race',
        headerName: 'Рейс',
        width: 100,

        sortable: false,
    },

    {
        field: 'proForm',
        headerName: '№проформы',
        width: 100,

        sortable: false,
    },

    {
        field: 'deliveryState',
        headerName: 'Статус поставщика',
        width: 130,

        sortable: false,
    },
    {
        field: 'orderState',
        headerName: 'Статус покупателя',
        width: 130,

        sortable: false,
    },
    {
        field: 'things',
        headerName: 'Серийный номер',
        width: 100,

        sortable: false,
    },
    {
        field: 'store',
        headerName: 'Склад',
        width: 120,

        sortable: false,
    },

];

export function LogisticsPage(): ReactElement {
    const {
        data, isLoading
    } = useGetDataQuery(null, {refetchOnMountOrArgChange: true});

    return (
        <Box>
            <AdminHeader/>
            <Grid item xs={12} marginBottom={2}>
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/logistics">Заказы</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/logistics/demand">Отгрузка</Link>
                </Button>
            </Grid>

            {isLoading && <Typography display="block">Загрузка...</Typography>}

            {data && (<ThemeProvider theme={theme}>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    slotProps={{
                        panel: {
                            sx: {
                                top: '-120px !important',
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-row': {
                            fontSize: '12px'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />
            </ThemeProvider>)}

        </Box>
    )
}
