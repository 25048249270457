import {Button, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import {map} from "lodash";
import React, {useEffect, useState} from "react";
import {useLazyCreateQuery, useLazySendAllQuery, useLazyUpdateQuery} from "./email.chain.api";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {IEmailChainElement} from "./models";
import {useListQuery} from "../Common/email.common.api";
import isNil from "lodash/isNil";

interface IProps {
    email: IEmailChainElement;
    chain: number;
    reloadData: (id: string) => void;
    setEmailChainElement: (element: any) => void;
}

export const ChainEmailForm = ({email, chain, reloadData, setEmailChainElement}: IProps): JSX.Element => {
    const [emailChain, setEmailChain] = useState(null);
    const {
        data: emailsProviders,
    } = useListQuery(null, {refetchOnMountOrArgChange: true});

    const [update] = useLazyUpdateQuery();
    const [create] = useLazyCreateQuery();

    const [selectedZip, setSelectedZip] = useState<File>();
    const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedZip(e.target.files[0])
        }
    };

    useEffect(() => {
        setEmailChain(email);
        setSelectedZip(null);
    }, [email]);

    const saveElementChain = async (): Promise<void> => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(emailChain)) {
            formData.append(key, value.toString());
        }

        if (selectedZip) {
            formData.append('zip', selectedZip);
        }
        formData.append('chain', chain.toString());

        try {
            if(emailChain?.id){
              await update({formData, chain: chain, id: emailChain.id});

                //
            }else{
               const data =  await create({formData, chain: chain});
               // reload!!!!
            }
        } catch (e) {
            console.log(e);

        }finally {
            reloadData(chain.toString());
            setEmailChainElement(null);
        }
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setEmailChain({
            ...emailChain,
            email: event.target.value,
        });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void =>{
        setEmailChain({
            ...emailChain,
            [e.target.name]: e.target.value,
        });
    }

    if(isNil(emailChain)){
        return null;
    }


    return (<>

        <input
            type="file"
            onChange={handleZipChange}
            accept=".zip"
            style={{display: "none"}}
            id="select-zip"
        />
        <label htmlFor="select-zip" style={{width: '100%', display: 'flex', marginBottom: '16px'}}>
            <Typography sx={{flexGrow: 1}} component="span">ZIP Архив
                рассылки с картинками: {selectedZip ? selectedZip.name : "не выбран"}</Typography>
            <Button variant="outlined" color="primary" component="span">
                {selectedZip ? "Выбрать другой файл" : "Выбрать файл"}
            </Button>
        </label>
        <Select
            id="select-provider"
            value={emailChain.email.toString()}
            name="provider"
            size="small"
            onChange={handleSelectChange}
            sx={{width: '100%', mb: '16px'}}
        >
            {map(emailsProviders, (email) => (
                <MenuItem value={email.id}>{email.email} ({email.signature})</MenuItem>))}
        </Select>

        <TextField id="title" name="title" onChange={handleChange} value={emailChain.title}
                   sx={{width: '100%', mb: '16px'}} label="Заголовок рассылки" variant="outlined"/>
        <TextField type="time" id="time" name="time" onChange={handleChange} value={emailChain.time}
                   sx={{width: '100%', mb: '16px'}} label="Время отправки" variant="outlined"/>
        <TextField type="number" id="days" name="days" onChange={handleChange} value={emailChain.days}
                   sx={{width: '100%', mb: '16px'}} label="Через сколько дней (от самого начала)" variant="outlined"/>
        <Box>
            <Button variant="contained" onClick={saveElementChain}>Сохранить элемент</Button>
        </Box>
    </>)
}
