import axios from "axios";

export const downloadData = async (urlRequest: string, params = {}): Promise<void> => {
    try {
        const token = localStorage.getItem('accessToken');

        const response = await axios({
            url: urlRequest,
            method: 'GET',
            responseType: 'blob',
            headers: {'Authorization': `Bearer ${token}`},
            params
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'default-filename.xlsx';

        if (contentDisposition) {
            const match = contentDisposition.match(/filename\*=['"]?UTF-8''(.+?)['"]?($|;)/i);

            if (match && match[1]) {
                filename = decodeURIComponent(match[1]);
            }
        }

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();

    } catch (error) {
        console.error('There was an error while downloading the file:', error);
    }
};
