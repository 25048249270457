import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Divider,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {includes, map, reject} from "lodash";
import {
    useLazyCreateMainQuery,
    useLazyGetOneQuery, useLazyUpdateMainQuery,
} from "./email.chain.api";
import isNil from "lodash/isNil";
import {IEmailChainElement, IEmailChainResponseDetails} from "./models";
import {PreviewEmail} from "./PreviewEmail";
import {ChainEmailForm} from "./EmailForm";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useGetBrandsQuery} from "../../../store/protected/clients/clients.api";

const INITIAL_STATE: IEmailChainResponseDetails = {
    title: '',
    emails: [],
    sites: [],
}

const INITIAL_EMAIL_STATE: IEmailChainElement = {
    title: '',
    email: '',
    days: '',
    time: '',
};

export const EmailChainForm = (): JSX.Element => {
    const {id} = useParams();
    const [update] = useLazyUpdateMainQuery();
    const [create] = useLazyCreateMainQuery();
    const {
        data: brands,
    } = useGetBrandsQuery(null, {refetchOnMountOrArgChange: true});
    const [emailChain, setEmailChain] = useState(INITIAL_STATE);
    const [emailChainElement, setEmailChainElement] = useState(null);
    const [getOne] = useLazyGetOneQuery();
    const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const loadData = async (id: string) => {
        const result = await getOne(id);

        setEmailChain(result.data);
    }

    useEffect(() => {

        if (!isNil(id)) {
            loadData(id);
        } else {

            setEmailChain(INITIAL_STATE);
        }
    }, [id]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEmailChain({
            ...emailChain,
            [e.target.name]: e.target.value,
        })
    }

    const saveChain = async (): Promise<void> => {
        try {
            if (emailChain?.id) {
                await update(emailChain);
                //
            } else {
                const data = await create(emailChain).unwrap();
                navigate('/admin/emails/chain/' + data.id);
            }
        } catch (e) {
            console.log(e);

        }
    }

    const changeChain = (data: IEmailChainElement): () => void => {
        return (): void => {
            setEmailChainElement(data)
        }
    }

    const handleChangeBrands = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let sites = emailChain.sites ?[...emailChain.sites]: [];
        if(includes(emailChain.sites,  +e.target.value)){
            sites = reject(sites, (el)=> el ===  +e.target.value);
        }
        else{
            sites.push(+e.target.value);
        }

        setEmailChain({
            ...emailChain,
            sites
        })
    }

    const addChain = (): void => {
        setEmailChainElement(INITIAL_EMAIL_STATE);
    }


    return (
        <>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                mt: 5,
                justifyContent: 'space-between'
            }}>
                <Box sx={{width: '48%'}}>
                    <Typography component="h1" variant="h5" sx={{mb: 5}}>Цепочка</Typography>
                    <TextField id="title" name="title" onChange={handleChange} value={emailChain.title}
                               sx={{width: '100%', mb: '16px'}} label="Название цепочки" variant="outlined"/>
                    <Box sx={{height: '300px', overflow: 'hidden', overflowY: 'auto'}}>
                        {map(brands, (brand) => (
                            <FormControlLabel
                                control={<Checkbox checked={includes(emailChain?.sites, brand.id)}
                                                   value={brand.id}
                                                   onChange={handleChangeBrands}/>}
                                label={brand.name}
                            />
                        ))}
                    </Box>


                    <Box>
                        <Button variant="contained" sx={{mb: 2}} onClick={saveChain}>Сохранить</Button>
                    </Box>


                    {emailChain.id && (
                        <Button variant="contained" sx={{mb: 2}} onClick={addChain}>Добавить новый элемент</Button>)}
                    {emailChain.id && emailChainElement && (
                        <ChainEmailForm setEmailChainElement={setEmailChainElement} reloadData={loadData} chain={emailChain.id} email={emailChainElement}/>)}
                    {map(emailChain.emails, (email) => (
                        <Box key={email.id} sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                            <Divider sx={{width: '100%', mb: 2}}/>
                            <Box sx={{width: '50%'}}>
                                <Typography variant="body1">Заголовок отправки - "{email.title}"</Typography>
                                <Typography variant="body1">Количество дней перед отправкой после добавления
                                    -{email.days}</Typography>
                                <Typography variant="body1">Время отправки - {email.time}</Typography>
                            </Box>
                            <Box sx={{width: '50%'}}>
                                <Button variant="contained" sx={{ml: 2}}
                                        onClick={changeChain(email)}>Редактировать</Button>
                            </Box>

                            <PreviewEmail emailChain={email} id={id}/>
                        </Box>
                    ))}


                </Box>
            </Box>
            <Snackbar
                open={isSnackbarVisible}
                autoHideDuration={2000}
                onClose={() => {
                    setIsSnackbarVisible(false)
                }}
                sx={{top: 0, right: 0}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%'}}>
                    Отправлено
                </Alert>
            </Snackbar>
        </>
    );
}
