import React from 'react';
import Box, {BoxProps} from "@mui/material/Box";

interface IProps extends BoxProps {
    disabled?: boolean;
}

export function DisabledBox({children, disabled, sx, ...props}: IProps): React.ReactElement {
    return (<Box sx={disabled ? {pointerEvents: 'none', opacity: '0.6', ...sx} : {}} {...props}>
        {children}
    </Box>)
}
