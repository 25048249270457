import React from 'react';
import {useGetProductsQuery} from "./store/api";
import {AdminHeader} from "../../../header";
import Box from "@mui/material/Box";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, GridRenderCellParams, ruRU} from "@mui/x-data-grid";
import {IOrderProductResponse} from "../../../../store/protected/orders/models";
import {Link} from "react-router-dom";


const theme = createTheme(
    {},
    ruRU,
);

const BitrixLink = (cellValues: GridRenderCellParams): JSX.Element => {
    return (<Link to={`https://maestra.bitrix24.ru/crm/catalog/25/product/${cellValues.value}/`}
                  target="_blank">Ссылка</Link>);
}
const OrderLink = (cellValues: GridRenderCellParams): JSX.Element => {
    console.log(cellValues.row);
    return (
        <>
            <Link to={`/admin/order/${cellValues.value}/`}>Manuals</Link>{' - '}
            <Link to={`https://maestra.bitrix24.ru/crm/deal/details/${cellValues.value}/`}
                  target="_blank">Битрикс</Link>
        </>
    );
}

const columns: GridColDef[] = [

    {
        field: 'productName',
        headerName: 'Наименование',
        flex: 1,
        filterable: true,
    },
    {
        field: 'productBitrixId',
        headerName: 'Ссылка битрикс продукт',
        width: 250,
        filterable: true,
        renderCell: BitrixLink,
    },
    {
        field: 'orderId',
        headerName: 'Заказ в системе',
        flex: 1,
        filterable: true,
        renderCell: OrderLink,
    },

]

export function CatalogLost(): JSX.Element {
    const {data = {} as IOrderProductResponse[], isLoading} = useGetProductsQuery();

    return !isLoading && !!data?.length ? (
        <>
            <AdminHeader/>
            <Box sx={{height: '600px'}}>
                {!!data?.length &&
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            disableRowSelectionOnClick={true}
                            rows={data}
                            getRowHeight={() => 'auto'}
                            columns={columns}
                            slotProps={{
                                panel: {
                                    sx: {
                                        top: '-120px !important',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                }
                            }}
                        />
                    </ThemeProvider>}
            </Box>
        </>

    ) : <div>Нет данных</div>;
}
