import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ClearIcon from "@mui/icons-material/Clear";
import {map} from "lodash";
import {ChangeEvent, useEffect, useState} from "react";
import {useLazyAddFileQuery, useLazyDeleteFileQuery, useLazyGetFilesQuery} from "../store/protected/orders/orders.api";
import {Link, useParams} from "react-router-dom";
import {REMOTE_SERVER} from "../consts";
import {IOrderFile} from "../models";

export const FilesTable = (): JSX.Element => {
    const {id} = useParams();
    const [fileData, setFileData] = useState<File | null>(null);
    const [sendFile] = useLazyAddFileQuery();
    const [getFiles, {data: files}] = useLazyGetFilesQuery();
    const [deleteFiles] = useLazyDeleteFileQuery();


    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFileData(e.target.files[0])
        }
    };

    const reloadData = () => {
        getFiles({
            id: id as string,
        });
    }


    const sendFileHandler = async (): Promise<void> => {
        const formData = new FormData();
        formData.append('file', fileData as File);

        await sendFile({
            formData,
            id: id as string,
        });

        setFileData(null);
        reloadData();
    }

    const deleteFileHandler = (fileId: string): () => Promise<void> => {
        return async (): Promise<void> => {
            await deleteFiles({id: id as string, fileId});

            reloadData();
        }
    }

    const getFilePath = (row: IOrderFile): string =>{
        return `${REMOTE_SERVER}files/order-files/${id}/${row.id}.${row.fileName.split('.').pop()}`;
    }

    useEffect(() => {
        if (id) {
            reloadData()
        }
    }, [id]);

    return (
        <Box sx={{mt: 2}}>
            <Typography variant="h6">Файлы</Typography>
            <Table sx={{width: 700}}>
                <TableHead>
                    <TableRow>
                        <TableCell width={350}>Файл</TableCell>
                        <TableCell width={40}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow key="table-row-upload">
                        <TableCell padding="none" colSpan={2}>
                            <Box sx={{display: 'flex', width: '100%', alignItems: 'center', padding: '5px 0'}}>
                                <Button variant="contained" component="label" sx={{mr: 2}} size="small">
                                    {fileData?.name && 'Выбрать другой'}
                                    {!(fileData?.name) && 'Выбрать'}
                                    <input hidden onChange={onChange} multiple type="file"/>
                                </Button>
                                {fileData?.name}
                                {fileData?.name && (
                                    <Button variant="contained" onClick={sendFileHandler} component="label" size="small"
                                            sx={{ml: 'auto'}}>
                                        Отправить данные
                                    </Button>
                                )}
                            </Box>
                        </TableCell>
                    </TableRow>
                    {map(files, (row, i) => (
                            <TableRow key={i}>
                                <TableCell padding="none">
                                    <Link
                                        to={ getFilePath(row) }
                                        target="_blank"
                                    >
                                    {row.fileName}
                                    </Link>
                                </TableCell>
                                <TableCell padding="none">
                                    <Button className="mr10" onClick={deleteFileHandler(row.id.toString())}>
                                        <ClearIcon/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}
