import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from "@mui/icons-material/Create";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {ChangeEvent, useEffect, useState} from "react";
import {copyToClipboard} from "../utils/OrderColumns";
import {eurFormatter, rubFormatter} from '../utils/Currency';
import {IShipping} from "../store/protected/orders/models";
import isNil from "lodash/isNil";
import {size} from "lodash";
import Typography from "@mui/material/Typography";

interface IProps {
    shipping: IShipping[];
    orderId: number;
    setShippingHandler: (shipping: IShipping[]) => void;
}

export const CarrierTable = ({ orderId, shipping, setShippingHandler}: IProps): JSX.Element | null => {
    const [shippingData, setData] = useState<IShipping[]>([]);
    const [isEdit, setEdit] = useState(false);

    useEffect(()=>{
        setData(shipping);
    },[shipping])

    useEffect(()=>{
        if(isNil(shippingData) && size(shippingData) === 0){
            setData([{supplier: "", amountEur: 0, amountRub: 0, orderId: orderId}]);
        }
    },[shippingData]);

    const handleAdd = () => {
        setData([
            ...shippingData,
            {supplier: "", amountEur: 0, amountRub: 0, orderId: orderId},
        ]);
        setEdit(true);
    };

    const handleEdit = () => {
        setEdit(!isEdit);
    };

    const handleSave = () => {
        setEdit(!isEdit);
        setData(shippingData);
        setShippingHandler(shippingData);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const {name, value} = e.target;
        const list = [...shippingData];
        //@ts-ignore
        list[index][name] = value;
        setData(list);
    };

    const handleRemoveClick = (index: number) => {
        const list = [...shippingData];
        list.splice(index, 1);
        setData(list);
    };

    if(isNil(shippingData)){
        return null;
    }

    return (
        <>
            <Box>
                <Typography variant="h6">Итоговая таблица</Typography>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        {isEdit ? (
                            <div>
                                <Button onClick={handleAdd}>
                                    <AddBoxIcon sx={{mr: 1}} onClick={handleAdd}/>
                                    Добавить
                                </Button>
                                {shippingData.length !== 0 && (
                                    <Button onClick={handleSave}>
                                        <DoneIcon sx={{mr: 1}}/>
                                        Сохранить
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <div>
                                <Button onClick={handleAdd}>
                                    <AddBoxIcon sx={{mr: 1}} onClick={handleAdd}/>
                                    Добавить перевозчика
                                </Button>
                                <Button onClick={handleEdit}>
                                    <CreateIcon sx={{mr: 1}}/>
                                    Редактировать
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </Box>

            <Table sx={{width: 700}}>
                <TableHead>
                    <TableRow>
                        <TableCell width={350}>Перевозчик</TableCell>
                        <TableCell width={150}>Сумма (€)</TableCell>
                        <TableCell width={150}>Сумма (₽)</TableCell>
                        <TableCell width={50}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {shippingData.map((row, i) => (
                            <TableRow key={i}>
                                {isEdit ? (
                                    <>
                                        <TableCell padding="none">
                                            <input value={row.supplier} name="supplier"
                                                   onChange={(e) => handleInputChange(e, i)}/>
                                        </TableCell>
                                        <TableCell padding="none">
                                            <input value={row.amountEur} name="amountEur"
                                                   onChange={(e) => handleInputChange(e, i)}/>
                                        </TableCell>
                                        <TableCell padding="none">
                                            <input value={row.amountRub} name="amountRub"
                                                   onChange={(e) => handleInputChange(e, i)}/>
                                        </TableCell>
                                        <TableCell padding="none">
                                            <Button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                <ClearIcon/>
                                            </Button>
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell component="th" scope="row">{row.supplier}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {eurFormatter.format(row.amountEur)}
                                            <IconButton onClick={() => copyToClipboard(row.amountEur)} color="default"
                                                        size="small" sx={{ml: 1}}>
                                                <ContentCopyIcon fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            {rubFormatter.format(row.amountRub)}
                                            <IconButton onClick={() => copyToClipboard(row.amountRub)} color="default"
                                                        size="small" sx={{ml: 1}}>
                                                <ContentCopyIcon fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            <Button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                <DeleteOutlineIcon/>
                                            </Button>
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </>
    );
};
