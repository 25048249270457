import {useGetStatisticsQuery} from "../../../store/protected/clients/clients.api";
import {AdminHeader} from "../../header";
import React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {copyToClipboard} from "../../../utils/OrderColumns";
import {join, map} from "lodash";


const theme = createTheme(
    {},
    ruRU,
);

const columns: GridColDef[] = [
    {
        field: 'phone',
        headerName: 'Телефон',
        width: 150,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'counter',
        headerName: 'Кол-во',
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'sites',
        headerName: 'Сайты и даты',
        width: 400,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
    },
]

export function ClientsPhonePage(): JSX.Element {
    const {data: list} = useGetStatisticsQuery(null, {refetchOnMountOrArgChange: true});

    const copyPhoneHandler = (): void =>{
        // @ts-ignore
        copyToClipboard(join(map(list,(element)=>element.phone),', '));
    }

    return (
        <>
            <AdminHeader/>
            <Box sx={{height: '600px'}}>
                <Button onClick={copyPhoneHandler}>Скопировать телефоны за две недели</Button>
                {!!list?.length &&
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            disableRowSelectionOnClick={true}
                            rows={list}
                            getRowHeight={() => 'auto'}
                            columns={columns}
                            slotProps={{
                                panel: {
                                    sx: {
                                        top: '-120px !important',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                }
                            }}
                        />
                    </ThemeProvider>}
            </Box>
        </>
    )
}
