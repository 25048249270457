import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid} from "@mui/x-data-grid/DataGrid";
import {GridRowModel, ruRU} from "@mui/x-data-grid";
import {IOrderResponse} from "../../../../store/protected/orders/models";
import {useLazyGetOrderQuery} from "../../../../store/protected/orders/getOrder.api";
import {useGetListQuery, useGetStatusesQuery} from "../../../ShippingCars/store/api";
import {AdminHeader} from "../../../header";
import {getLogisticsColumns} from "./utils";
import {useLazyUpdateShippingProductQuery} from "../../../../store/protected/orders/orders.api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link} from "react-router-dom";

const theme = createTheme(
    {},
    ruRU,
);

/** Компонент OrderLogistics */
export function OrderLogistics(): JSX.Element {
    const {id} = useParams();
    const [data, setData] = useState<IOrderResponse>();
    const [fetchOrder] = useLazyGetOrderQuery();
    const [updateOrderProduct] = useLazyUpdateShippingProductQuery();
    const {data: dataShipping, isSuccess: isSuccessShipping} = useGetListQuery();
    const {isSuccess: isSuccessStatuses} = useGetStatusesQuery();


    useEffect(() => {
        if (id && isSuccessShipping && isSuccessStatuses) {
            (async () => {
                await setData((await fetchOrder({id: id as string})).data);
            })()
        }
    }, [id, isSuccessShipping, isSuccessStatuses]);


    // Обработчик изменения ячеек основной таблицы
    const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
        updateOrderProduct({
            id: newRow.id,
            shipping: newRow.shippingCars
        });

        return newRow;
    }, []);

    if (!data) {
        return <CircularProgress/>;
    }

    return (
        <>
            <AdminHeader/>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Typography variant="h5">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography typography="h5"  sx={{mr: 2,display: 'flex', alignItems: 'center'}}>
                                <Link to={`/admin/order/${data.id}`}>
                                    <ArrowBackIcon/>
                                </Link>
                            </Typography>
                            <Typography variant="h5" sx={{mr: 2}}>
                                <Link
                                    target="_blank"
                                    to={`https://maestra.bitrix24.ru/crm/deal/details/${data.id}/`}
                                >
                                    № {data.id}
                                </Link>
                            </Typography>
                            {data.title}
                        </Box>
                    </Typography>
                </Box>
            </Box>
            {/* Основная таблица */}
            <Box sx={{height: data.products.length * 52 + 58, width: '100%'}}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        columns={getLogisticsColumns(dataShipping)}
                        processRowUpdate={processRowUpdate}
                        rows={data.products}
                        checkboxSelection
                        disableRowSelectionOnClick
                        hideFooter
                        showCellVerticalBorder
                        showColumnVerticalBorder
                    />
                </ThemeProvider>
            </Box>
        </>
    )
}
