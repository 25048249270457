import {Box, Button, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import {useState} from 'react';
import Grid from "@mui/material/Grid";
import {AdminHeader} from "./header";
import {map} from "lodash";
import {REMOTE_SERVER} from "../consts";
import {useLazyUploadImagesConverterDataBaseQuery} from "../store/protected/converter/converter.api";
import {IAsyncTaskConverterProgress, IAsyncTaskResponse} from "../store/protected/async-task/models";
import {
    useAsyncTaskListConverterInProgressQuery,
    useAsyncTaskListConverterQuery
} from "../store/protected/async-task/async-task.api";

export function UploadConverterFile(): JSX.Element {
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [idTask, setIdTask] = useState<string>('');
    const {
        data = {} as IAsyncTaskResponse[],
    } = useAsyncTaskListConverterQuery({}, {refetchOnMountOrArgChange: true});
    const {
        data: response = {} as IAsyncTaskConverterProgress,
    } = useAsyncTaskListConverterInProgressQuery({}, {refetchOnMountOrArgChange: true});
    const [upload, {isLoading, isSuccess}] = useLazyUploadImagesConverterDataBaseQuery();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const changeDownloadBrand = (event: SelectChangeEvent): void => {
        setIdTask(event.target.value);
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Заполните все поля');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        await upload(formData);
        setSelectedFile(null);
    }

    return (
        <Box sx={{flexGrow: 1, m: 2}}>
            <Grid container spacing={2}>
                <AdminHeader/>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h2">
                        Скачивание и конвертация изображений
                    </Typography>
                    <Typography variant="h5" component="h2">
                        Загрузить файл для конвертации
                    </Typography>
                    <Typography variant="body1" component="p">
                        Выберите xlsx файл с одним листом
                    </Typography>
                    <Box textAlign="center">
                        {!response?.result && (<Typography display="block">Загрузка...</Typography>)}
                        {response?.result && (<Box sx={{display: 'flex', alignItems: 'center'}}>
                            <input
                                type="file"
                                onChange={handleChange}
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                                style={{display: "none"}}
                                id="select-file"
                            />
                            <label htmlFor="select-file">
                                <Button variant="outlined" color="primary" component="span">
                                    {selectedFile ? "Выбрать другой файл (.xls, .xlsx)" : "Выбрать файл (.xls, .xlsx)"}
                                </Button>
                            </label>
                            {selectedFile && (
                                <Button variant="contained" color="primary" component="span" sx={{mx: 2}}
                                        onClick={handleUpload}>
                                    {`Загрузить ${selectedFile.name}`}
                                </Button>
                            )}
                        </Box>)}

                        {isLoading && <Typography display="block">Загрузка...</Typography>}
                        {isSuccess && <Typography display="block">Файл отправлен в обработку</Typography>}
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <Typography variant="h5" component="h2">
                            Скачать файл результат
                        </Typography>
                        <div style={{marginRight: '10px'}}>
                            <InputLabel>Файл дата </InputLabel>
                            <Select
                                id="select-brand-name"
                                value={idTask}
                                name="_brand"
                                size="small"
                                onChange={changeDownloadBrand}
                                sx={{width: '100%'}}
                            >
                                {map(data, element => (<MenuItem value={element.id}>{element.title}</MenuItem>))}
                            </Select>

                            {idTask && (<div style={{marginTop: '10px'}}>
                                <Link
                                    href={`${REMOTE_SERVER}images-converter/${idTask}`}
                                    color="inherit">
                                    Скачать каталог
                                </Link>
                            </div>)}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
