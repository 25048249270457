import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {IEmailCommon, IEmailCommonDetails} from "./models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";


export const emailCommonApi = createApi({
    reducerPath: 'emailCommonApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        list: build.query<IEmailCommon[], void>({
            query: () => ({
                url: 'email/common'
            })
        }),
        create: build.query<IEmailCommonDetails, IEmailCommonDetails>({
            query: (data) => ({
                url: 'email/common',
                method: 'POST',
                body: data
            })
        }),
        update: build.query<IEmailCommonDetails, IEmailCommonDetails>({
            query: (data) => ({
                url: `email/common/${data.id}`,
                method: 'PUT',
                body: data
            })
        }),
        send: build.query<any, { id: number; email: string; } >({
            query: (data) => ({
                url: `email/common/${data.id}`,
                method: 'POST',
                body: data
            })
        }),
        getOne: build.query<IEmailCommonDetails, string>({
            query: (id) => ({
                url: `email/common/${id}`,
            })
        }),
    })
});

export const {
    useLazyCreateQuery,
    useLazyUpdateQuery,
    useListQuery,
    useLazyGetOneQuery,
    useLazySendQuery
} = emailCommonApi;
