import {
    AppBar,
    Dialog,
    Toolbar,
    Typography,
    Slide, CircularProgress
} from "@mui/material";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {TransitionProps} from "@mui/material/transitions";
import CloseIcon from '@mui/icons-material/Close';
import isNil from "lodash/isNil";
import Box from "@mui/material/Box";
import {useCheckTokenQuery} from "../../../../store/protected/auth/auth.api";
import {map, size} from "lodash";
import {useLazyGetClientQuery} from "../../../../store/protected/clients/clients.api";
import {copyToClipboard} from "../../../../utils/OrderColumns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Address, Email, Site} from "../../../../store/protected/clients/models";
import moment from "moment/moment";
import {Comment} from "./Comment";
import {StatusSelect} from "./StatusSelect";
import {BlockButton} from "./Block";

interface IProps {
    clientId?: number;
    clearData: () => void;

}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const ClientDetails = ({clientId, clearData}: IProps): React.ReactElement => {
    const [open, setOpen] = useState(false);
    const {data: userData} = useCheckTokenQuery(null, {refetchOnMountOrArgChange: true,});
    const [getClient, {data: clientData, isSuccess, isLoading: isLoadingClient, status}] = useLazyGetClientQuery();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (clearData) {
            clearData();
        }
    };

    const reloadData = (): void => {
        getClient({id: clientId, date: moment().format("YYYY-MM-DD H:i:s")});
    }


    useEffect(() => {
        if (!isNil(clientId)) {
            reloadData();
            handleOpen();
        }
    }, [clientId]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {status === 'pending' && (
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                    Происходит загрузка данных <CircularProgress/>
                </Box>)}
            {isSuccess && status !== 'pending' && (
                <Box sx={{width: 'calc(100% - 32px)', display: 'flex', flexWrap: 'wrap', p: 2}}>
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Ф.И.О</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            {map(clientData.names, (name) => {
                                return (
                                    <Box>{name.name}</Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Телефон</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            {map(clientData.phones, (phone, index: number) => (
                                <Box key={index}>
                                    <a href={`tel:+${phone.phone}`} style={{textDecoration: 'none', color: 'blue'}}>
                                        +{phone.phone}
                                    </a>
                                    <IconButton onClick={() => copyToClipboard(`+${phone.phone}`)} color="default"
                                                size="small" sx={{ml: 1}}>
                                        <ContentCopyIcon fontSize="small"/>
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Бренд</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            {map(clientData.sites, (s: Site, index: number) => (
                                <Box key={index}>
                                    {s.site} - {moment(s.date).locale('ru').format('L LTS')}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Email</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            {map(clientData.emails, (s: Email, index: number) => (
                                <Box key={index}>
                                    {s.email}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    {(size(clientData.contactsBitrix) > 0 || size(clientData.dealsBitrix) > 0) && (
                        <Box sx={{width: '300px', m: 1}}>
                            <Typography variant="h6">BITRIX</Typography>
                            <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                                {size(clientData.contactsBitrix) > 0 && map(clientData.contactsBitrix, (s) => (
                                    <>
                                        <a href={`https://maestra.bitrix24.ru/crm/contact/details/${s.contactId}/`}
                                           target="_blank">Ссылка на клиента</a>
                                        <br/>
                                    </>
                                ))}
                                {size(clientData.dealsBitrix) > 0 && map(clientData.dealsBitrix, (s) => (
                                    <>
                                        <a href={`https://maestra.bitrix24.ru/crm/deal/details/6925/${s.dealId}/`}
                                           target="_blank">Ссылка на сделку</a>
                                        <br/>
                                    </>
                                ))}
                            </Box>
                        </Box>
                    )}
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Email</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            {map(clientData.emails, (s: Email, index: number) => (
                                <Box key={index}>
                                    {s.email}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Адрес</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            {map(clientData.addresses, (s: Address, index: number) => (
                                <Box key={index}>
                                    {s.address}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{width: '300px', m: 1}}>
                        <Typography variant="h6">Действия</Typography>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            <Comment
                                clientId={clientData.id}
                                clientUserId={clientData.user?.id}
                                userId={userData?.userId}
                                comment={clientData.comment}/>
                        </Box>
                    </Box>
                    <Box sx={{width: '300px', m: 1}}>
                        <Box sx={{height: '150px', overflow: 'hidden', overflowY: 'auto',}}>
                            <BlockButton
                                isBlocked={clientData.block}
                                onChangeValue={reloadData}
                                clientId={clientData.id}
                                clientUserId={clientData.user?.id}
                                userId={userData?.userId}
                            />
                            <br/>
                            <StatusSelect
                                clientId={clientData.id}
                                clientUserId={clientData.user?.id}
                                userId={userData?.userId}
                                currentStatus={clientData.status}
                            />
                            {clientData.user?.id && (<>Ответственный - {clientData.user.username}</>)}

                        </Box>
                    </Box>
                </Box>)}
        </Dialog>
    )
}
