import SyncIcon from '@mui/icons-material/Sync';
import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {GridRowId, GridRowModel, ruRU} from '@mui/x-data-grid';
import {DataGrid} from '@mui/x-data-grid/DataGrid';
import {forEach, has, isNumber, map, replace, uniq} from 'lodash';
import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Link} from "react-router-dom";
import {IOrderResponse} from "../../store/protected/orders/models";
import {
    useGetBankExchangeValueQuery,
} from "../../store/protected/orders/orders.api";
import {Alert, CircularProgress, Snackbar} from '@mui/material';
import {AdminHeader} from '../header';
import {useLazyGetOrderQuery} from '../../store/protected/orders/getOrder.api';
import {getMainColumns, getMainTotalColumns} from "./Components/OrderColumns";
import * as React from "react";
import {GridApiCommunity} from "@mui/x-data-grid/models/api/gridApiCommunity";
import {Result} from "./Components/Result";
import {
    useLazyGetMultiOrderQuery,
    useLazyRemoveOrderFromMultiOrderQuery
} from "./store/api";
import {OrdersList} from "./Components/OrdersList";
import DeleteIcon from '@mui/icons-material/Delete';

const theme = createTheme(
    {},
    ruRU,
);

interface ITotalTable {
    weight: number;
    grossVolume: number;
    grossBr: number;
    transferEuro: number;
    transferEuroBr: number;

}

export const MultipleOrder = (): JSX.Element => {
    const {id} = useParams();
    const navigate = useNavigate();
    const myRefs = useRef<GridApiCommunity[]>([]);
    const [orders, setOrders] = useState<IOrderResponse[]>();
    const [isRowUpdated, setIsRowUpdated] = useState<boolean>(false);
    const [needTotalUpdate, setNeedTotalUpdate] = useState<boolean>(false);
    const [removeOrderFromMultiOrder] = useLazyRemoveOrderFromMultiOrderQuery();

    // Объем бруто 2
    const [gross, setGross] = useState<number>(0);

    // Выбор валюты
    const [currency, setCurrency] = useState<number>(0);
    const [ownCurrency, setOwnCurrency] = useState<number>(0);
    const [ownCurrencyString, setOwnCurrencyString] = useState<string>("");
    const [currencyType, setCurrencyType] = useState<"own" | "bank">("bank");

    // Стоимость перевозки
    const [transferEuroRussiaPrice, setTransferEuroRussiaPrice] = useState<number>(0);
    const [transferEuroPrice, setTransferEuroPrice] = useState<number>(0);
    const [isTransferEuroApplied, setIsTransferEuroApplied] = useState<boolean>(false);

    // Строка суммы для основной таблицы
    const [totalTable, setTotalTable] = useState<Record<string, ITotalTable>>({});

    // Таблица итогов
    const [total, setTotal] = useState<{ total: number; totalBr: number; }>({
        total: 0,
        totalBr: 0,
    });

    const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);

    const {
        data: bankCurrency,
        isSuccess: isBankCurrencySuccess,
        isLoading: isCurrencyLoading,
        isError: isCurrencyError
    } = useGetBankExchangeValueQuery(null);
    const [fetchOrder, {isLoading: isDataLoading, isError: isDataError}] = useLazyGetOrderQuery();
    const [fetchMultiOrder, {isLoading: isMultiLoading, isError: isMultiError}] = useLazyGetMultiOrderQuery();

    // setGross
    const setGrossHandler = (e: any): void => {
        setGross(e.target.value);
    }

    const handleGrossUpdate = (): void => {
        let sumResultGrossValue = 0;
        let selectedRow: Record<string, { data: any; id: GridRowId }[]> = {};

        forEach(myRefs.current, (element, index) => {
            const rowIds = element.getAllRowIds();
            forEach(rowIds, (rowId) => {
                if (element.isRowSelected(rowId)) {
                    if (!has(selectedRow, index)) {
                        selectedRow = {...selectedRow, [index]: []};
                    }
                    const rowData = element.getRow(rowId);
                    sumResultGrossValue += rowData.grossVolume;
                    selectedRow[index].push({data: rowData, id: rowId});
                }
            });
        });

        const proc = gross / sumResultGrossValue;
        forEach(myRefs.current, (element, index) => {
            forEach(selectedRow[index], (row) => {
                element.updateRows([{
                    id: row.id,
                    gross: proc * row.data.grossVolume
                }]);
            });
        });
        setIsRowUpdated(true);
    };

    // Функция для пересчета сумм для главной таблицы
    const updateFieldsTotal = useCallback(() => {
        let result: Record<string, ITotalTable> = {}
        forEach(myRefs.current, (element, index) => {

            let totalWeight = 0;
            let totalGrossVolume = 0;
            let totalGrossBr = 0;
            let totalTransferEuro = 0;
            let totalTransferEuroBr = 0;
            const rowIds = element.getAllRowIds();

            forEach(rowIds, (rowId) => {
                const row = element.getRow(rowId);
                totalWeight += row.quantity ? row.brWeight * row.quantity : row.brWeight;
                totalGrossVolume += row.quantity ? row.grossVolume * row.quantity : row.grossVolume;
                totalGrossBr += isNumber(row.gross) ? row.gross : 0;
                totalTransferEuro += row.transferEuro;
                totalTransferEuroBr += row.transferEuroBr;
            });
            result = {
                ...result, [index]: {
                    weight: totalWeight,
                    grossVolume: totalGrossVolume,
                    grossBr: totalGrossBr,
                    transferEuro: totalTransferEuro,
                    transferEuroBr: totalTransferEuroBr,
                }
            }
        })


        return result;
    }, [myRefs]);

    const updateGrossVolume = (): void =>{
        forEach(myRefs.current, (element, index) => {

            forEach(element.getAllRowIds(), (rowId) => {
                const row = element.getRow(rowId);
                element.updateRows([{
                    id: rowId,
                    grossVolume: Number((row.brWidth * row.brHeight * row.brDepth).toFixed(2))
                }])
            })
        });
    }

    // Функция рассчитывает итог в евро
    const getTotal = (isTransferEuroApplied: boolean) => {
        let total = 0;
        let totalBr = 0;
        forEach(myRefs.current, (element, index) => {

            const rowIds = element.getAllRowIds();

            forEach(rowIds, (rowId) => {
                const row = element.getRow(rowId);
                console.log(row);
                total += row.transferEuroRussia;
                totalBr += isNumber(row.transferEuroRussiaBr) ? row.transferEuroRussiaBr : 0;

                if (isTransferEuroApplied) {
                    total += row.transferEuro;
                    totalBr += row.transferEuro;
                }
            })
            console.log(total, totalBr)
        });

        return {total, totalBr};
    };

    // Обработчик выбора типа применяемого курса валюты
    const handleSelectCurrencyType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        event.persist();

        if (isBankCurrencySuccess) {
            setCurrencyType(value === "own" ? "own" : "bank");
            setCurrency(value === "own" ? ownCurrency : bankCurrency);
        }
    };

    // Обработчик ввода собственного курса валюты
    const handleInputOwnCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;
        const value = replace(event.target.value, /^0+/, '')

        if (value === "" || regex.test(value)) {
            setOwnCurrency(Number(value));
            setOwnCurrencyString(value);

            if (currencyType === "own") {
                setCurrency(Number(value));
            }
        }
    }

    // Обработчик ввода стоимости перевозки Европа - РФ
    const handleInputTransferEuroRussiaPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;
        const value = replace(event.target.value, /^0+/, '')

        if (value === "" || regex.test(value)) {
            setTransferEuroRussiaPrice(+value);
        }
    }

    // Обработчик применения стоимости перевозки Европа - РФ для выделенных строк
    const handleSubmitTransferEuroRussia = () => {
        forEach(myRefs.current, (element, index) => {
            const rowIds = element.getAllRowIds();

            forEach(rowIds, (rowId) => {
                if (element.isRowSelected(rowId)) {
                    const row = element.getRow(rowId);
                    element.updateRows([{
                        id: rowId,
                        transferEuroRussia: row.quantity
                            ? Number((transferEuroRussiaPrice * row.grossVolume * row.quantity).toFixed(2))
                            : Number((transferEuroRussiaPrice * row.grossVolume).toFixed(2)),
                        transferEuroRussiaBr: row.quantity
                            ? Number((transferEuroRussiaPrice * row.gross * row.quantity).toFixed(2))
                            : Number((transferEuroRussiaPrice * row.gross).toFixed(2)),
                    }])
                }
            })
        });
        setNeedTotalUpdate(true);
    }

    // Обработчик ввода стоимости перевозки по Европе
    const handleInputTransferEuroPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;
        const value = replace(event.target.value, /^0+/, '')

        if (value === "" || regex.test(value)) {
            setTransferEuroPrice(+value);
        }
    }

    const handleDeleteOrder = (orderId: number): ()=> Promise<void> => {
        return async (): Promise<void> => {
            await removeOrderFromMultiOrder({
                orderId: orderId.toString(),
                id: id,
            })
            navigate(0);
        }
    }

    // Обработчик применения стоимости перевозки по Европе
    const handleSubmitTransferEuro = () => {
        forEach(myRefs.current, (element, index) => {

            const rowIds = element.getAllRowIds();

            forEach(rowIds, (rowId) => {
                if (element.isRowSelected(rowId)) {
                    const row = element.getRow(rowId);
                    element.updateRows([{
                        id: rowId,
                        transferEuro: row.quantity
                            ? Number((transferEuroPrice * row.quantity).toFixed(2))
                            : Number((transferEuroPrice).toFixed(2)),
                    }])
                }
            })
        });
        setNeedTotalUpdate(true);
    }

    // Обработчик выбора учета стоимости перевозки по Европе в итоговой сумме
    const handleChangeIsTransferEuroApplied = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTransferEuroApplied(event.target.checked);
    }

    // Обработчик изменения ячеек основной таблицы
    const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
        setIsRowUpdated(true);
        return newRow;
    }, []);

    // Загрузка данных заказа
    useEffect(() => {
        if (id) {
            (async () => {
                const multiOrder = (await fetchMultiOrder({id})).data;
                // @ts-ignore
                const list = map(multiOrder.orders, order => order.order.id);

                const orders = [];
                for (const orderId of list) {
                    orders.push((await fetchOrder({id: orderId.toString()})).data);
                }
                // @ts-ignore
                await setOrders(orders);
            })()
        }
    }, [id]);

    useEffect(() => {
        if(isRowUpdated) {
            updateGrossVolume();
            setNeedTotalUpdate(true);
            setIsRowUpdated(false);
        }
    }, [myRefs, ownCurrency, currencyType, isTransferEuroApplied, orders, isRowUpdated]);

    useEffect(()=>{
        if(needTotalUpdate){
            setTotalTable(updateFieldsTotal());
            setTotal(getTotal(isTransferEuroApplied));
            setNeedTotalUpdate(false);
        }
    },[needTotalUpdate]);

    // Начальная установка значений
    useEffect(() => {
        if (isBankCurrencySuccess) {
            setCurrency(bankCurrency);
        }

    }, [isBankCurrencySuccess]);

    if (isCurrencyLoading || isDataLoading) {
        return <CircularProgress/>
    }

    if (isCurrencyError || isDataError) {
        return <div>Что-то пошло не так, перезагрузите страницу</div>
    }

    // @ts-ignore
    return orders ? (
        <>
            <Snackbar
                open={isUpdateSuccess}
                autoHideDuration={2000}
                onClose={() => {
                    setIsUpdateSuccess(false)
                }}
                sx={{top: 0, right: 0}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%'}}>
                    Изменения сохранены
                </Alert>
            </Snackbar>

            <AdminHeader/>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                {/* Выбор курса валюты */}
                <RadioGroup row onChange={handleSelectCurrencyType}>
                    <FormControlLabel
                        value="own"
                        control={<Radio checked={currencyType === "own"}/>}
                        label={(
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography display="inline" mr={1}>Свой курс</Typography>
                                <TextField
                                    size="small"
                                    sx={{mr: 2, width: 100}}
                                    type="text"
                                    value={ownCurrencyString}
                                    onChange={handleInputOwnCurrency}
                                />
                            </Box>
                        )}
                    />
                    <FormControlLabel
                        sx={{mr: 0}}
                        value="bank"
                        control={<Radio checked={currencyType === "bank"}/>}
                        label={(
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography display="inline" mr={1}>Курс ЦБРФ</Typography>
                                <TextField
                                    size="small"
                                    sx={{width: 100}}
                                    value={bankCurrency || 0}
                                    disabled
                                />
                            </Box>
                        )}
                    />
                </RadioGroup>

                {/* Установка стоимости перевозки */}
                <Table
                    aria-label="spanning table"
                    size="small"
                    sx={{
                        maxWidth: 410,
                        ml: 'auto',
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <TableCell>V бр 2</TableCell>
                            <TableCell>Стоимость Европа - РФ</TableCell>
                            <TableCell>Стоимость по Европе</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    size="small"
                                    sx={{width: 100}}
                                    type="text"
                                    value={gross}
                                    onChange={setGrossHandler}
                                />
                                <IconButton onClick={handleGrossUpdate} color="default" size="small"
                                            sx={{ml: 1, p: '9px'}}>
                                    <SyncIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    sx={{width: 100}}
                                    type="text"
                                    value={transferEuroRussiaPrice}
                                    onChange={handleInputTransferEuroRussiaPrice}
                                />
                                <IconButton onClick={handleSubmitTransferEuroRussia} color="default" size="small"
                                            sx={{ml: 1, p: '9px'}}>
                                    <SyncIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    sx={{width: 100}}
                                    type="text"
                                    value={transferEuroPrice}
                                    onChange={handleInputTransferEuroPrice}
                                />
                                <IconButton onClick={handleSubmitTransferEuro} color="default" size="small"
                                            sx={{ml: 1}}>
                                    <SyncIcon fontSize="small"/>
                                </IconButton>
                                <Checkbox checked={isTransferEuroApplied}
                                          onChange={handleChangeIsTransferEuroApplied}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>

            {/* Вывод заказов */}
            {map(orders, (order, index) => (
                <Box sx={{mb: 3}}>
                    <Box>
                        <Typography variant="h5">
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Link to={`/admin/order/${order.id}`} target="_blank">№ {order.id}</Link> - {" "}
                                {order.title}
                            </Box>
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography variant="subtitle1">
                                {order.manager} - Сумма {order.amount}р.
                            </Typography>
                            <IconButton onClick={handleDeleteOrder(order.id)} color="default" size="small" sx={{ml: 1, p: '9px'}}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {/* Основная таблица */}
                    <Box sx={{height: order.products.length * 52 + 58, width: '100%'}}>
                        <ThemeProvider theme={theme}>
                            {/* @ts-ignore */}
                            <DataGrid apiRef={(el) => {
                                return myRefs.current[index] = el;
                            }}
                                      columns={getMainColumns(uniq(map(order.products, ({brand}) => brand)))}
                                      processRowUpdate={processRowUpdate}
                                      rows={order.products}
                                      sx={{borderBottom: 'none'}}
                                      checkboxSelection
                                      disableRowSelectionOnClick
                                      hideFooter
                                      showCellVerticalBorder
                                      showColumnVerticalBorder
                            />
                        </ThemeProvider>
                    </Box>
                    {/* Строка суммы для основной таблицы */}
                    {totalTable && has(totalTable, index) && (
                        <Box sx={{height: 53}}>
                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    checkboxSelection
                                    isRowSelectable={() => false}
                                    rows={[{
                                        id: -1,
                                        brWeight: totalTable[index].weight,
                                        grossVolume: totalTable[index].grossVolume,
                                        grossBr: totalTable[index].grossBr,
                                        transferEuro: totalTable[index].transferEuro,
                                        transferEuroBr: totalTable[index].transferEuroBr,
                                    }]}
                                    columns={getMainTotalColumns()}
                                    columnHeaderHeight={0}
                                    hideFooter
                                    sx={{
                                        mt: '-2px',
                                        borderTop: 'none',
                                        "& .MuiDataGrid-columnHeaders": {
                                            borderBottom: 'none',
                                        },
                                        "& .MuiCheckbox-root": {
                                            visibility: "hidden"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: 'none',
                                        },
                                    }}
                                />
                            </ThemeProvider>
                        </Box>
                    )}
                </Box>
            ))}
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <OrdersList />

                <Result total={total.total} totalBr={total.totalBr} currency={currency}/>
            </Box>

        </>
    ) : <CircularProgress/>;
};
