import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REMOTE_SERVER } from '../../consts';
import {IProductDataResponse, IProductResponse} from '../../models';

export const productPublicApi = createApi({
    reducerPath: 'productPublicApi',
    tagTypes: ['Products'],
    baseQuery: fetchBaseQuery({
        baseUrl: REMOTE_SERVER
    }),
    endpoints: (build) => ({
        getProducts: build.query<IProductDataResponse, { page?: string, limit?: string, name?: string, brand?: string, type?: string[] }>({
            query: ({ limit, name, brand, type, page }) => {
                const params: { _limit?: string, _name?: string, _brand?: string, _type?: string[], _page?: string } = {};
                if (limit) {params._limit = limit}
                if (page) {params._page = page}
                if (name) {params._name = name}
                if (brand) {params._brand = brand}
                if (type && !!type.length) {params._type = type}
                return {
                    url: 'product',
                    params
                }
            },
        }),

        getProduct: build.query<IProductResponse, { id: string }>({
            query: ({ id }) => ({
                url: `product/${id}`,
            }),
        }),

        getBrands: build.query<string[], null>({
            query: () => ({
                url: 'product/brand',
            }),
        }),

        getBrandTypes: build.query<string[], { brand: string }>({
            query: ({ brand }) => ({
                url: 'product/brand-type',
                params: {
                    _brand: brand
                }
            }),
        }),

        sendInstruction: build.mutation<IProductResponse, { productId: number, email: string }>({
            query: (data) => ({
                url: 'send',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useGetProductsQuery, useGetProductQuery, useGetBrandsQuery, useGetBrandTypesQuery, useSendInstructionMutation } = productPublicApi;
