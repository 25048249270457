import { Box, Container, Typography } from '@mui/material';
import { CreateForm } from '../components/CreateForm';

export function CreatePage(): JSX.Element {
    return (
        <Container maxWidth="lg">
            <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>Добавить продукт</Typography>

                <CreateForm />
            </Box>
        </Container>
    );
};