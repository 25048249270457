import { FormControl, FormHelperText, InputLabel, InputProps, OutlinedInput } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type TTextInputProps = {
    id: string;
    name: string;
    label: string;
} & InputProps

export const NumberInput: React.FC<TTextInputProps> = ({ id, name, label, ...props }) => {
    const { control, formState: { errors } } = useFormContext();

    return (
        <Controller
            control={ control }
            name={ name }
            render={ ({ field }) => (
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel htmlFor={ id }>{ label }</InputLabel>

                    <OutlinedInput
                        { ...field }
                        id={ id }
                        label={ label }
                        error={ !!errors[name] }
                        onChange={ (e) => {
                            field.onChange(parseFloat(e.target.value));
                        } }
                        { ...props }
                    />

                    <FormHelperText error={ !!errors[name] }>
                        { errors[name] ? (errors[name]?.message as unknown as string) : "" }
                    </FormHelperText>
                </FormControl>
            ) }
        />
    );
};
