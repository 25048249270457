import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const sendApi = createApi({
    reducerPath: 'sendApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({

        getSend: build.query({
            query({limit, page}) {
                return {
                    url: 'send',
                    params: {
                        limit, page,
                    }
                }
            }
        }),
    })
});

export const { useLazyGetSendQuery } = sendApi;
