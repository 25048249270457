import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SyncIcon from '@mui/icons-material/Sync';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {GridRowModel, ruRU, useGridApiRef} from '@mui/x-data-grid';
import {DataGrid} from '@mui/x-data-grid/DataGrid';
import {forEach, map, reverse, startsWith, uniq} from 'lodash';
import {useCallback, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CarrierTable} from '../../components/CarrierTable';
import {IOrderResponse, IShipping} from "../../store/protected/orders/models";
import {
    useGetBankExchangeValueQuery, useLazyAddMultiOrderQuery,
    useLazyBitrixUpdateQuery,
    useLazyGetOrderHistoryQuery,
    useLazyGetOrderHistoryRecordQuery,
    useLazyUpdateOrderQuery
} from "../../store/protected/orders/orders.api";
import {eurFormatter, rubFormatter} from '../../utils/Currency';
import {copyToClipboard, getMainColumns, getMainTotalColumns} from '../../utils/OrderColumns';
import moment from 'moment';
import {Alert, CircularProgress, Link as LinkMui, Paper, Snackbar} from '@mui/material';
import {AdminHeader} from '../header';
import {useAppDispatch} from '../../hooks/store';
import {getOrderApi, useLazyGetOrderQuery} from '../../store/protected/orders/getOrder.api';
import {getFormatDate} from "../../utils/getFormatDate";
import {FilesTable} from "../../components/FilesTable";
import {MultiOrderList} from "./Components/MultiOrderList";
import {SaveProduct} from "./Components/SaveProduct";

const theme = createTheme(
    {},
    ruRU,
);

const updateLocalStorage = (data: Partial<IOrderResponse>, id: string) => {
    localStorage.setItem(`order-${id}`, JSON.stringify({
        ...JSON.parse(localStorage.getItem(`order-${id}`) as string),
        ...data,
    }));
};

export const OrderPage = (): JSX.Element => {
    const navigate = useNavigate();
    const {id} = useParams();
    const apiRef = useGridApiRef();
    const [data, setData] = useState<IOrderResponse>();
    const dispatch = useAppDispatch();

    // Выбор валюты
    const [currency, setCurrency] = useState<number>(0);
    const [ownCurrency, setOwnCurrency] = useState<number>(0);
    const [ownCurrencyString, setOwnCurrencyString] = useState<string>("");
    const [currencyType, setCurrencyType] = useState<"own" | "bank">("bank");
    const [isCurrencyUpdated, setIsCurrencyUpdated] = useState<boolean>(false);

    // Стоимость перевозки
    const [transferEuroRussiaPrice, setTransferEuroRussiaPrice] = useState<number>(0);
    const [transferEuroRussiaPriceString, setTransferEuroRussiaPriceString] = useState<string>("");
    const [transferEuroPrice, setTransferEuroPrice] = useState<number>(0);
    const [transferEuroPriceString, setTransferEuroPriceString] = useState<string>("");
    const [isTransferEuroApplied, setIsTransferEuroApplied] = useState<boolean>(false);
    const [isTransferEuroUpdated, setIsTransferEuroUpdated] = useState<boolean>(false);

    // Основная таблица
    const [isRowUpdated, setIsRowUpdated] = useState<boolean>(false);

    // Строка суммы для основной таблицы
    const [totalWeight, setTotalWeight] = useState<number>(0);
    const [totalGrossVolume, setTotalGrossVolume] = useState<number>(0);
    const [totalTransferEuro, setTotalTransferEuro] = useState<number>(0);

    // Таблица итогов
    const [subtotal, setSubtotal] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    //
    const [shipping, setShipping] = useState<IShipping[]>([]);

    // Коэффициент
    const [coefficient, setCoefficient] = useState<number>(0);
    const [coefficientString, setCoefficientString] = useState<string>("");
    const [isCoefficientApplied, setIsCoefficientApplied] = useState<boolean>(false);
    const [isCoefficientUpdated, setIsCoefficientUpdated] = useState<boolean>(false);

    const [isRequestedDBData, setIsRequestedDBData] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");
    const [isComment, setIsComment] = useState<boolean>(true);
    const [isSnackbarVisible, setisSnackbarVisible] = useState<boolean>(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);

    const {
        data: bankCurrency,
        isSuccess: isBankCurrencySuccess,
        isLoading: isCurrencyLoading,
        isError: isCurrencyError
    } = useGetBankExchangeValueQuery(null);
    const [fetchOrder, {isLoading: isDataLoading, isError: isDataError}] = useLazyGetOrderQuery();
    const [update] = useLazyUpdateOrderQuery();
    const [getHistory, {data: historyData}] = useLazyGetOrderHistoryQuery();
    const [fetchOrderHistoryRecord] = useLazyGetOrderHistoryRecordQuery();
    const [bitrixUpdate] = useLazyBitrixUpdateQuery();
    const [addMultiOrder] = useLazyAddMultiOrderQuery();

    // Функция для пересчета сумм для главной таблицы
    const updateFieldsTotal = useCallback(() => {
        let totalWeight = 0;
        let totalGrossVolume = 0;
        let totalTransferEuro = 0;
        const rowIds = apiRef.current.getAllRowIds();

        forEach(rowIds, (rowId) => {
            const row = apiRef.current.getRow(rowId);
            totalWeight += row.quantity ? row.brWeight * row.quantity : row.brWeight;
            totalGrossVolume += row.quantity ? row.grossVolume * row.quantity : row.grossVolume;
            totalTransferEuro += row.transferEuro;
        })

        return {totalWeight, totalGrossVolume, totalTransferEuro}
    }, [apiRef]);

    // Функция рассчитывает предварительный итог в евро
    const getSubtotal = useCallback(() => {
        let subtotal = 0;
        const rowIds = apiRef.current.getAllRowIds();

        forEach(rowIds, (rowId) => {
            if (apiRef.current.isRowSelected(rowId)) {
                const row = apiRef.current.getRow(rowId);
                subtotal += row.transferEuroRussia;
            }
        })

        return subtotal;
    }, [apiRef])

    // Функция рассчитывает итог в евро
    const getTotal = useCallback((isTransferEuroApplied: boolean) => {
        let total = 0;
        const rowIds = apiRef.current.getAllRowIds();

        forEach(rowIds, (rowId) => {
            const row = apiRef.current.getRow(rowId);
            total += row.transferEuroRussia;

            if (isTransferEuroApplied) {
                total += row.transferEuro;
            }
        })

        return total;
    }, [apiRef])

    // Обработчик выбора типа применяемого курса валюты
    const handleSelectCurrencyType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        event.persist();

        if (isBankCurrencySuccess) {
            setCurrencyType(value === "own" ? "own" : "bank");
            setCurrency(value === "own" ? ownCurrency : bankCurrency);
            setIsCurrencyUpdated(true);
        }
    };

    // Обработчик ввода собственного курса валюты
    const handleInputComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
        setIsComment(true);
    }

    // Обработчик ввода собственного курса валюты
    const handleInputOwnCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;

        if (event.target.value === "" || regex.test(event.target.value)) {
            setOwnCurrency(Number(event.target.value));
            setOwnCurrencyString(event.target.value);

            if (currencyType === "own") {
                setCurrency(Number(event.target.value));
                setIsCurrencyUpdated(true);
            }
        }
    }

    // Обработчик ввода стоимости перевозки Европа - РФ
    const handleInputTransferEuroRussiaPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;

        if (event.target.value === "" || regex.test(event.target.value)) {
            setTransferEuroRussiaPrice(Number(event.target.value));
            setTransferEuroRussiaPriceString(event.target.value);
        }
    }

    // Обработчик применения стоимости перевозки Европа - РФ для выделенных строк
    const handleSubmitTransferEuroRussia = () => {
        const rowIds = apiRef.current.getAllRowIds();

        forEach(rowIds, (rowId) => {
            if (apiRef.current.isRowSelected(rowId)) {
                const row = apiRef.current.getRow(rowId);
                apiRef.current.updateRows([{
                    id: rowId,
                    transferEuroRussia: row.quantity
                        ? Number((transferEuroRussiaPrice * row.grossVolume * row.quantity).toFixed(2))
                        : Number((transferEuroRussiaPrice * row.grossVolume).toFixed(2)),
                }])
            }
        })

        setIsRowUpdated(true);
    }

    // Обработчик ввода стоимости перевозки по Европе
    const handleInputTransferEuroPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;

        if (event.target.value === "" || regex.test(event.target.value)) {
            setTransferEuroPrice(Number(event.target.value));
            setTransferEuroPriceString(event.target.value);
        }
    }

    // Обработчик применения стоимости перевозки по Европе
    const handleSubmitTransferEuro = () => {
        const rowIds = apiRef.current.getAllRowIds();

        forEach(rowIds, (rowId) => {
            if (apiRef.current.isRowSelected(rowId)) {
                const row = apiRef.current.getRow(rowId);
                apiRef.current.updateRows([{
                    id: rowId,
                    transferEuro: row.quantity
                        ? Number((transferEuroPrice * row.quantity).toFixed(2))
                        : Number((transferEuroPrice).toFixed(2)),
                }])
            }
        })

        setIsRowUpdated(true);
    }

    // Обработчик выбора учета стоимости перевозки по Европе в итоговой сумме
    const handleChangeIsTransferEuroApplied = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTransferEuroApplied(event.target.checked);

        setIsTransferEuroUpdated(true);
    }

    // Обработчик ввода коэффициента
    const handleInputCoefficient = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[1-9][0-9]*\.?[0-9]{0,3}$/;

        if (event.target.value === "" || regex.test(event.target.value)) {
            setCoefficient(Number(event.target.value));
            setCoefficientString(event.target.value);
            setIsCoefficientUpdated(true);
        }
    }

    // Обработчик выбора учета коэффициента при пересчете евро в рубли
    const handleChangeIsCoefficientApplied = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCoefficientApplied(event.target.checked);

        setIsCoefficientUpdated(true);
    };

    // Обработчик изменения ячеек основной таблицы
    const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
        setIsRowUpdated(true);

        return newRow;
    }, []);

    // Обработчик выбора строки
    const handleRowSelectionChange = () => {
        let subtotal = 0;
        const rowIds = apiRef.current.getAllRowIds();
        forEach(rowIds, (rowId) => {
            if (apiRef.current.isRowSelected(rowId)) {
                const row = apiRef.current.getRow(rowId);
                subtotal += row.transferEuroRussia;
            }
        })

        setSubtotal(subtotal);
    }

    // Сохранение заказа
    const handleUpdateOrder = async () => {
        if (!comment) {
            setIsComment(false);
        } else {
            const order = {
                ...JSON.parse(localStorage.getItem(`order-${id}`) as string),
                comment
            } as IOrderResponse;

            update({
                order,
                id: id as string,
            })
                .then(async () => {
                    localStorage.removeItem(`order-${id}`);
                    await dispatch(getOrderApi.util.resetApiState());

                    (async () => {
                        await setData((await fetchOrder({id: id as string})).data);
                    })()
                })
                .then(() => {
                    setIsUpdateSuccess(true);
                })
                .catch((e) => {
                    console.log(e)
                })
        }

    }

    const setShippingHandler = (shipping: IShipping[]): void => {
        setShipping(shipping);
        updateLocalStorage({shipping}, id as string);
    }

    const handleBitrixUpdate = async () => {
        (async () => {
            bitrixUpdate({id: id as string})
                .then((res) => {
                    setisSnackbarVisible(true);
                })
        })()
    }

    const handleAddMulti = async () => {
        await (async () => {
            const data = await addMultiOrder({id: id as string});
            // @ts-ignore
            navigate('/admin/multiple/' + data.data.multi);
        })()
    }

    const handleGetDatabaseData = async () => {
        localStorage.removeItem(`order-${id}`);
        await dispatch(getOrderApi.util.resetApiState());

        (async () => {
            fetchOrder({id: id as string})
                .unwrap()
                .then((data) => {
                    setData(data);
                })
                .then(() => {
                    setIsRequestedDBData(true);
                })
        })()
    }

    const handleGetHistory = () => {
        getHistory({id: id as string})
    }

    const handleGetHistoryRecord = async (historyId: string) => {
        localStorage.removeItem(`order-${id}`);
        await dispatch(getOrderApi.util.resetApiState());

        (async () => {
            await setData((await fetchOrderHistoryRecord({id: id as string, historyId})).data);
        })()
    }

    // Загрузка данных заказа
    useEffect(() => {
        if (id) {
            if (localStorage.getItem(`order-${id}`)) {
                setData(JSON.parse(localStorage.getItem(`order-${id}`) as string));
            } else {
                (async () => {
                    await setData((await fetchOrder({id})).data);
                })()
            }
        }
    }, [fetchOrder, id])

    // Начальная установка значений
    useEffect(() => {
        if (data && isBankCurrencySuccess && !isRequestedDBData) {
            localStorage.setItem(`order-${id}`, JSON.stringify(data));
            setCurrency(data.currencyType === "bank" ? bankCurrency : data.currency);
            setOwnCurrency(data.currencyType === "own" ? data.currency : 0);
            setOwnCurrencyString(data.currencyType === "own" ? data.currency.toString() : "");
            setCurrencyType(data.currencyType);

            setIsTransferEuroApplied(data.isTransferEuroApplied);

            setTotalGrossVolume(updateFieldsTotal().totalGrossVolume);
            setTotalTransferEuro(updateFieldsTotal().totalTransferEuro);
            setTotalWeight(updateFieldsTotal().totalWeight);

            setIsCoefficientApplied(data.isCoefficientApplied);
            setCoefficient(data.coefficient);
            setCoefficientString(data.coefficient.toString())

            setTotal(getTotal(data.isTransferEuroApplied));
            setIsRequestedDBData(false);

            setShipping(data.shipping);
        }

        if (data && isBankCurrencySuccess && isRequestedDBData) {
            let totalWeight = 0;
            let totalGrossVolume = 0;
            let totalTransferEuro = 0;

            forEach(data.products, (product) => {
                totalWeight += product.brWeight;
                totalGrossVolume += product.grossVolume;
                totalTransferEuro += product.transferEuro;
            })

            localStorage.setItem(`order-${id}`, JSON.stringify(data));
            setCurrency(data.currencyType === "bank" ? bankCurrency : data.currency);
            setOwnCurrency(data.currencyType === "own" ? data.currency : 0);
            setOwnCurrencyString(data.currencyType === "own" ? data.currency.toString() : "");
            setCurrencyType(data.currencyType);

            setIsTransferEuroApplied(data.isTransferEuroApplied);

            setTotalGrossVolume(totalGrossVolume);
            setTotalTransferEuro(totalTransferEuro);
            setTotalWeight(totalWeight);

            setIsCoefficientApplied(data.isCoefficientApplied);
            setCoefficient(data.coefficient);
            setCoefficientString(data.coefficient.toString())

            setTotal(getTotal(data.isTransferEuroApplied));
            setIsRequestedDBData(false);

            setShipping(data.shipping);
        }
    }, [bankCurrency, data, getTotal, id, isBankCurrencySuccess, isRequestedDBData, updateFieldsTotal]);

    // Обновление курса валюты в локалсторадж
    useEffect(() => {
        if (isCurrencyUpdated) {
            updateLocalStorage({currency, currencyType}, id as string);

            setIsCurrencyUpdated(false);
        }
    }, [currency, currencyType, id, isCurrencyUpdated]);

    // Обновление информации о применении стоимости перевозки по Европе в локалсторадж
    useEffect(() => {
        if (isTransferEuroUpdated) {
            updateLocalStorage({isTransferEuroApplied}, id as string);

            setTotal(getTotal(isTransferEuroApplied));

            setIsTransferEuroUpdated(false);
        }
    }, [getTotal, id, isTransferEuroApplied, isTransferEuroUpdated]);

    // Обновление информации о применении стоимости перевозки по Европе в локалсторадж
    useEffect(() => {
        if (isCoefficientUpdated) {
            updateLocalStorage({isCoefficientApplied, coefficient}, id as string);

            setTotal(getTotal(isTransferEuroApplied));

            setIsCoefficientUpdated(false);
        }
    }, [coefficient, getTotal, id, isCoefficientApplied, isCoefficientUpdated, isTransferEuroApplied]);

    // Обновление информации о строках после изменения в локалсторадж
    useEffect(() => {
        if (isRowUpdated) {
            forEach(apiRef.current.getAllRowIds(), (rowId) => {
                const row = apiRef.current.getRow(rowId);
                apiRef.current.updateRows([{
                    id: rowId,
                    grossVolume: Number((row.brWidth * row.brHeight * row.brDepth).toFixed(2))
                }])
            })

            const products = map(apiRef.current.getAllRowIds(), (rowId) => apiRef.current.getRow(rowId));
            updateLocalStorage({products}, id as string);

            setSubtotal(getSubtotal());
            setTotal(getTotal(isTransferEuroApplied));

            setTotalGrossVolume(updateFieldsTotal().totalGrossVolume);
            setTotalTransferEuro(updateFieldsTotal().totalTransferEuro);
            setTotalWeight(updateFieldsTotal().totalWeight);

            setIsRowUpdated(false);
        }
    }, [apiRef, getSubtotal, getTotal, id, isRowUpdated, isTransferEuroApplied, updateFieldsTotal]);

    // Удаляем из локалсторадж записи с момента обновления которых прошло больше 3 дней
    useEffect(() => {
        if (id) {
            for (let i: number = 0; i < localStorage.length; ++i) {
                if (startsWith(localStorage.key(i) as string, 'order-')) {
                    const item = JSON.parse(localStorage.getItem(localStorage.key(i) as string) as string);
                    const date = moment(Date.now());
                    const lastUpdate = moment(item.updated_at);

                    if (date.diff(lastUpdate, 'days') > 1 && localStorage.key(i) !== `order-${id}`) {
                        console.log('deleting item')
                        localStorage.removeItem(localStorage.key(i) as string)
                    }
                }
            }
        }
    }, [id]);

    if (isCurrencyLoading || isDataLoading) {
        return <CircularProgress/>
    }

    if (isCurrencyError || isDataError) {
        return <div>Что-то пошло не так, перезагрузите страницу</div>
    }

    return data ? (
        <>
            <Snackbar
                open={isSnackbarVisible}
                autoHideDuration={2000}
                onClose={() => {
                    setisSnackbarVisible(false)
                }}
                sx={{top: 0, right: 0}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%'}}>
                    Задача на обновление из bitrix поставлена
                </Alert>
            </Snackbar>

            <Snackbar
                open={isUpdateSuccess}
                autoHideDuration={2000}
                onClose={() => {
                    setIsUpdateSuccess(false)
                }}
                sx={{top: 0, right: 0}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%'}}>
                    Изменения сохранены
                </Alert>
            </Snackbar>

            <AdminHeader/>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Typography variant="h5">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <LinkMui
                                target="_blank"
                                href={`https://maestra.bitrix24.ru/crm/deal/details/${data.id}/`}
                                sx={{mr: 1}}
                            >
                                № {data.id}
                            </LinkMui>
                            {data.title}
                        </Box>
                        {data.clientName && (<Box>
                            <Typography variant="h6">({data.clientName})</Typography>
                        </Box>)}
                        <Button variant="outlined" size="small" onClick={handleBitrixUpdate}>
                            Обновить данные из Bitrix
                        </Button>
                        <Button variant="outlined" size="small" sx={{ml: 1}} onClick={handleAddMulti}>
                            Добавить в новый мульти-заказ
                        </Button>
                        <Button variant="outlined" size="small" sx={{ml: 1}}>
                            <Link to={`/admin/order/${data.id}/logistics`}>
                                Логистика
                            </Link>
                        </Button>
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="subtitle1">
                            {data.manager} - Сумма {data.amount}р.
                        </Typography>
                        <Typography variant="subtitle1">
                            Дата создания {getFormatDate(data.create_at)}
                        </Typography>
                    </Box>

                    <Button variant="outlined" size="small" sx={{marginTop: 2}} onClick={handleGetDatabaseData}>
                        Загрузить данные из БД
                    </Button>
                    <Typography variant="caption" display="block" gutterBottom>
                        Все не сохраненные изменения будут утеряны
                    </Typography>
                </Box>

                <Box>
                    {/* Выбор курса валюты */}
                    <Box sx={{mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <RadioGroup row onChange={handleSelectCurrencyType}>
                            <FormControlLabel
                                value="own"
                                control={<Radio checked={currencyType === "own"}/>}
                                label={(
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography display="inline" mr={1}>Свой курс</Typography>
                                        <TextField
                                            size="small"
                                            sx={{mr: 2, width: 100}}
                                            type="text"
                                            value={ownCurrencyString}
                                            onChange={handleInputOwnCurrency}
                                        />
                                    </Box>
                                )}
                            />
                            <FormControlLabel
                                sx={{mr: 0}}
                                value="bank"
                                control={<Radio checked={currencyType === "bank"}/>}
                                label={(
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography display="inline" mr={1}>Курс ЦБРФ</Typography>
                                        <TextField
                                            size="small"
                                            sx={{width: 100}}
                                            value={bankCurrency || 0}
                                            disabled
                                        />
                                    </Box>
                                )}
                            />
                        </RadioGroup>
                    </Box>

                    {/* Установка стоимости перевозки */}
                    <Table
                        aria-label="spanning table"
                        size="small"
                        sx={{
                            maxWidth: 410,
                            ml: 'auto',
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none"
                            }
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>Стоимость Европа - РФ</TableCell>
                                <TableCell>Стоимость по Европе</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        sx={{width: 100}}
                                        type="text"
                                        value={transferEuroRussiaPriceString}
                                        onChange={handleInputTransferEuroRussiaPrice}
                                    />
                                    <IconButton onClick={handleSubmitTransferEuroRussia} color="default" size="small"
                                                sx={{ml: 1, p: '9px'}}>
                                        <SyncIcon fontSize="small"/>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        sx={{width: 100}}
                                        type="text"
                                        value={transferEuroPriceString}
                                        onChange={handleInputTransferEuroPrice}
                                    />
                                    <IconButton onClick={handleSubmitTransferEuro} color="default" size="small"
                                                sx={{ml: 1}}>
                                        <SyncIcon fontSize="small"/>
                                    </IconButton>
                                    <Checkbox checked={isTransferEuroApplied}
                                              onChange={handleChangeIsTransferEuroApplied}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Box>

            {/* Основная таблица */}
            <Box sx={{height: data.products.length * 52 + 58, width: '100%'}}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        apiRef={apiRef}
                        columns={getMainColumns(uniq(map(data.products, ({brand}) => brand)))}
                        onRowSelectionModelChange={handleRowSelectionChange}
                        processRowUpdate={processRowUpdate}
                        rows={data.products}
                        sx={{borderBottom: 'none'}}
                        checkboxSelection
                        disableRowSelectionOnClick
                        hideFooter
                        showCellVerticalBorder
                        showColumnVerticalBorder
                    />
                </ThemeProvider>
            </Box>

            {/* Строка суммы для основной таблицы */}
            <Box sx={{height: 53}}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        checkboxSelection
                        isRowSelectable={() => false}
                        rows={[{
                            id: -1,
                            brWeight: totalWeight,
                            grossVolume: totalGrossVolume,
                            transferEuro: totalTransferEuro
                        }]}
                        columns={getMainTotalColumns()}
                        columnHeaderHeight={0}
                        hideFooter
                        sx={{
                            mt: '-2px',
                            borderTop: 'none',
                            "& .MuiDataGrid-columnHeaders": {
                                borderBottom: 'none',
                            },
                            "& .MuiCheckbox-root": {
                                visibility: "hidden"
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: 'none',
                            },
                        }}
                    />
                </ThemeProvider>
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                <Box>
                    {/* Таблица итогов */}
                    <Table aria-label="spanning table" sx={{maxWidth: 500, ml: 'auto'}}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>Предварительный итог</TableCell>
                                <TableCell align="right">
                                    {eurFormatter.format(subtotal)}
                                    <IconButton onClick={() => copyToClipboard(subtotal)} color="default" size="small"
                                                sx={{ml: 1}}>
                                        <ContentCopyIcon fontSize="small"/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Итог</TableCell>
                                <TableCell align="right">
                                    {eurFormatter.format(total)}
                                    <IconButton onClick={() => copyToClipboard(total)} color="default" size="small"
                                                sx={{ml: 1}}>
                                        <ContentCopyIcon fontSize="small"/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    {rubFormatter.format(isCoefficientApplied ? total * currency * coefficient : total * currency)}
                                    <IconButton
                                        onClick={() => copyToClipboard(isCoefficientApplied ? total * currency * coefficient : total * currency)}
                                        color="default" size="small" sx={{ml: 1}}>
                                        <ContentCopyIcon fontSize="small"/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    {/* Коэффициент */}
                    <Box sx={{mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <TextField
                            sx={{mr: 2}}
                            label="Коэффициент"
                            type="text"
                            value={coefficientString}
                            onChange={handleInputCoefficient}
                        />

                        <FormControlLabel
                            control={<Checkbox checked={isCoefficientApplied}
                                               onChange={handleChangeIsCoefficientApplied}/>}
                            label="Учитывать коэффициент"
                        />
                    </Box>


                    <TextField
                        label="Комментарий"
                        sx={{width: '100%', mt: 2}}
                        type="text"
                        value={comment}
                        onChange={handleInputComment}
                    />

                    {!isComment && <Alert severity="error" sx={{mt: 1}}>Комментарий обязателен</Alert>}


                    <Button variant="contained" onClick={handleUpdateOrder} sx={{width: '100%', marginTop: 2}}>
                        Сохранить
                    </Button>
                </Box>

                <Box mt={4}>
                    <CarrierTable shipping={shipping} orderId={data.id} setShippingHandler={setShippingHandler}/>
                    <FilesTable/>
                </Box>
            </Box>

            <Box sx={{mb: 5, mt: 2}}>
                <Typography variant="h6">История заказа</Typography>
                <Button variant="outlined" size="small" sx={{marginTop: 2}} onClick={handleGetHistory}>
                    Получить историю изменения
                </Button>
                {historyData && (
                    <Paper
                        sx={{
                            width: 500,
                            height: 300,
                            overflow: "hidden",
                            overflowY: "scroll",
                            padding: 2,
                            marginTop: 2,
                        }}
                    >
                        {reverse(map(historyData, (item, i) => (
                            <Box key={i} display="flex" alignItems="center" justifyContent="space-between"
                                 marginBottom={2}>
                                <Typography variant="caption" marginRight={1}>
                                    {moment(item.updated_at).utc().format('DD.MM.YYYY HH:mm')}
                                </Typography>
                                <Typography variant="caption" marginRight={1} width={250}>
                                    {item.comment}
                                </Typography>
                                <Button variant="outlined" size="small" onClick={() => {
                                    handleGetHistoryRecord(item.id.toString())
                                }}>
                                    Загрузить
                                </Button>
                            </Box>
                        )))}
                    </Paper>
                )}
                <MultiOrderList/>
                <SaveProduct/>
            </Box>
        </>
    ) : <div>Что-то пошло не так</div>;
};
