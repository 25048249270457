import Box from "@mui/material/Box";
import {Link, Typography} from "@mui/material";
import {SendEmail} from "./SendEmail";
import React from "react";
import {IEmailDistributionResponseDetails} from "./models";

interface IProps {
    emailDistribution: IEmailDistributionResponseDetails;
}

export const PreviewEmail = ({emailDistribution}: IProps): JSX.Element =>{
    if(!emailDistribution.id || !emailDistribution.fileExist){
        return null;
    }

    return (
            <Box sx={{width: '100%'}}>
                <Box sx={{display: 'flex', my: 5, alignItems: 'center'}}>
                    <Typography component="h1" variant="h5" sx={{mr: 3}}>Предпросмотр</Typography>
                    <Link target="_blank" href={`https://email.my-manuals.ru/${emailDistribution.id}/index.html`}>Посмотреть
                        в новом окне</Link>
                </Box>
                <SendEmail id={emailDistribution.id}/>
                <iframe src={`https://email.my-manuals.ru/${emailDistribution.id}/index.html`}
                        style={{width: '100%', height: '600px'}} frameBorder="0"/>
            </Box>
        )
}
