import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReauth} from "../../../consts/baseQuery";


export const logisticsApi = createApi({
    reducerPath: 'logisticsApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getData: build.query({
            query() {
                return {
                    url: 'remote-orders',
                    /*params: {
                        limit, page,
                    }
*/                }
            }
        }),
        getDemandData: build.query({
            query() {
                return {
                    url: 'remote-orders/demand',
                    /*params: {
                        limit, page,
                    }
*/                }
            }
        }),
    })
})

export const { useGetDataQuery, useGetDemandDataQuery } = logisticsApi;
