import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Link, Typography} from "@mui/material";
import {SendEmail} from "./SendEmail";
import React from "react";
import {IEmailChainElement} from "./models";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface IProps {
    emailChain: IEmailChainElement;
    id: string;
}

export const PreviewEmail = ({emailChain, id}: IProps): JSX.Element =>{
    if(!emailChain.id || !emailChain.fileExist){
        return null;
    }

    return (
            <Box sx={{width: '100%'}}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header_${emailChain.id}` }
                    >
                        <Typography>Просмотр</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{display: 'flex', my: 5, alignItems: 'center'}}>
                            <Typography component="h1" variant="h5" sx={{mr: 3}}>Предпросмотр</Typography>
                            <Link target="_blank" href={`https://email.my-manuals.ru/chain/${id}/${emailChain.id}/index.html`}>Посмотреть
                                в новом окне</Link>
                        </Box>
                        <SendEmail id={emailChain.id}/>
                        <iframe src={`https://email.my-manuals.ru/chain/${id}/${emailChain.id}/index.html`}
                                style={{width: '100%', height: '600px', marginTop: '20px'}} frameBorder="0"/>
                    </AccordionDetails>
                </Accordion>

            </Box>
        )
}
