import React, {useEffect} from 'react';
import {useLazyGetUsersQuery} from "../store/protected/users/users.api";
import {AdminHeader} from "./header";
import Box from "@mui/material/Box";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

const LIMIT = 5000;

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
    },
    {field: 'username', headerName: 'Менеджер', width: 250},
];

/** Компонент  */
export function UserListPage(): JSX.Element {
    const [getUsers, {data, isSuccess}] = useLazyGetUsersQuery();
    const navigate = useNavigate();

    useEffect(() => {
        getUsers({limit: LIMIT, page: 1});
    }, [getUsers])

    return isSuccess && !!data?.data.length ? (
        <>
            <AdminHeader/>
            <Box>
                <Button variant="contained" sx={{mb: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/users/create">Создать</Link>
                </Button>
            </Box>
            <Box sx={{height: 631, width: '100%'}}>
                {!!data?.data.length && <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data.data}
                    onRowClick={(rowData) => navigate(`/admin/users/${rowData.row.id}`)}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />}
            </Box>
        </>

    ) : <div>Нет данных</div>;
}
