import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {AdminHeader} from "../../header";
import moment from "moment/moment";
import {useLazyGetListV2Query} from "../../../store/protected/clients/clients.api";
import {CircularProgress} from "@mui/material";
import {findIndex, noop} from "lodash";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {ClientDetails} from "./Details/ClientDetails";
import {FilterClients} from "./Filter/Filter";
import {columns} from "./Consts";
import {IFilter} from "./Filter/Models";
import {IClientsResponse} from "../../../store/protected/clients/models";
import {BlockButton} from "./Block";
import {useCheckTokenQuery} from "../../../store/protected/auth/auth.api";

const theme = createTheme({}, ruRU);

export function ClientListV2(): React.ReactElement {
    const [filter, setFilter] = useState<IFilter>({
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        statuses: [],
        brands: [],
        phone: '',
        block: '',
        source: 0,
        user: null,
    });
    const {data: userData} = useCheckTokenQuery(null, {refetchOnMountOrArgChange: true});
    const [clientIdNow, setClientIdNow] = useState<number>(null);
    const [getList, {data, isSuccess, isLoading: isLoadingList, status}] = useLazyGetListV2Query();
    const [list, setList ] = useState<IClientsResponse>(null);

    const handleRefreshData = (): void => {
        const filterNew = {...filter, date: moment().format("YYYY-MM-DD H:i:s")};
        getList(filterNew);
    }

    useEffect(() => {
        handleRefreshData();
    }, [getList]);

    const clearData = (): void => {
        setClientIdNow(null);
        handleRefreshData();
    }

    useEffect(() => {
        console.log(data);
        setList(data);
    }, [data]);

    const onChangeValue = (id: number, key: string, value: any): void => {
        const newData = [...list];
        const index = findIndex(newData, element => element.id === id);

        newData[index] = {...newData[index], [key]:value};

        setList(newData);

    }

    const getColumns = (): GridColDef[] => {
        const indexOpen = findIndex(columns, (column) => {
            return column.field === 'id';
        });

        const newOpen = {
            ...columns[indexOpen],
            renderCell: (params: any) => {
                const clickHandler = (): void => {
                    setClientIdNow(params.row.id)
                }

                return (
                    <Box onClick={clickHandler} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <OpenInNewIcon/>
                    </Box>
                );
            }
        }

        const newColumn = [...columns];
        newColumn[indexOpen] = newOpen;

        const indexBlock = findIndex(columns, (column) => {
            return column.field === 'block';
        });

        newColumn[indexBlock] = {
            ...columns[indexBlock],
            renderCell: (params: any) => {
                return (<BlockButton
                isBlocked={params.row.block}
                onChangeValue={onChangeValue}
                key={'block_' + params.row.id}
                clientId={params.row.id}
                clientUserId={params.row.user?.id}
                userId={userData?.userId}
                setIsOpen={noop}
            />)}
        };

        return newColumn;
    }


    return (
        <>
            <AdminHeader/>
            <FilterClients filter={filter} changeFilter={setFilter} handleRefreshData={handleRefreshData}/>
            <Box sx={{height: '600px'}}>
                {(status === 'pending') && (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Происходит загрузка данных <CircularProgress/>
                    </Box>
                )}
                {isSuccess && !list?.length && (
                    <Box sx={{display: 'flex'}}>
                        Данные не найдены
                    </Box>
                )}
                {isSuccess && status !== 'pending' && !!list?.length &&
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            disableRowSelectionOnClick={true}
                            rows={list}
                            getRowHeight={() => 'auto'}
                            getRowClassName={(params) => `super-app-theme--${params.row.competitors}`}
                            columns={getColumns()}
                            slotProps={{
                                panel: {
                                    sx: {
                                        top: '-120px !important',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                },
                                '& .super-app-theme--true': {
                                    backgroundColor: 'rgba(255,0,0,0.04)!important',
                                },
                            }}
                        />
                    </ThemeProvider>}
            </Box>
            <ClientDetails clientId={clientIdNow} clearData={clearData}/>
        </>);
}
