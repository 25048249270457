import {FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {filter, includes, join, map} from "lodash";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {IFilter} from "./Models";
import {useGetBrandsGroupQuery} from "../../../../store/protected/clients/clients.api";
import {useGetUsersQuery} from "../../../../store/protected/users/users.api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IProps {
    data: number;
    changeFilter: (filterElement: Partial<IFilter>) => void;
}

export const FilterUser = ({data, changeFilter}: IProps): React.ReactElement => {
    const {
        data: clientUsers, isSuccess: isSuccessUsers
    } = useGetUsersQuery({page: 1, limit: 200}, {refetchOnMountOrArgChange: true});

    const handleChange = (e: SelectChangeEvent<number>): void => {
        changeFilter({
            [e.target.name]: e.target.value
        })
    }


    if (!isSuccessUsers) {
        return null;
    }

    const clientUsersFiltered = filter(clientUsers.data, (user)=>{
        return includes(user.role, 'clients');
    })

    return (
        <FormControl sx={{m: 1, width: 300}} size="small">
            <InputLabel id="user-checkbox-label">Пользователь</InputLabel>
            <Select
                labelId="user-checkbox-label"
                id="user-checkbox"
                size="small"
                name="user"
                label="Пользователь"
                onChange={handleChange}
                value={data}
                MenuProps={MenuProps}
            >
                <MenuItem value="">Не выбран</MenuItem>

                {map(clientUsersFiltered, (user) => {
                    return (
                        <MenuItem value={user.id}>{user.username}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

