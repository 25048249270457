import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridRowModel, ruRU} from '@mui/x-data-grid';
import React, {useCallback} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Button, CircularProgress} from "@mui/material";
import {useGetListQuery, useGetStatusesQuery, useLazyUpdateStatusQuery} from "../store/api";
import {AdminHeader} from "../../header";
import {Link} from "react-router-dom";

const theme = createTheme(
    {},
    ruRU,
);


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 130,
        filterable: true,
    },
    {
        field: 'name',
        headerName: 'Номер перевозки',
        width: 250,
        filterable: true,
    },
];

export const ShippingCarsList = (): JSX.Element => {
    const [updateStatus] = useLazyUpdateStatusQuery();
    const {data: dataShipping, isLoading: isLoadingShipping, isSuccess: isSuccessShipping} = useGetListQuery(null, {refetchOnMountOrArgChange: true});
    const {data: dataStatuses, isLoading: isLoadingStatuses, isSuccess: isSuccessStatuses} = useGetStatusesQuery(null, {refetchOnMountOrArgChange: true});


    const getOptions = (): GridColDef[] => {
        if (!isLoadingStatuses && isSuccessStatuses) {
            return [...columns, {
                getOptionValue: (value: any) => value.id,
                getOptionLabel: (value: any) => value.name,
                field: 'status',
                headerName: 'Статус',
                type: "singleSelect",
                flex: 1,
                valueOptions: dataStatuses,
                filterable: true,
                editable: true,
            }]
        }
        return columns;
    }

    // Обработчик изменения ячеек основной таблицы
    const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
        updateStatus({
            id: newRow.id,
            status: newRow.status,
        })

        return newRow;
    }, []);

    // EDIT AND SAVE

    return !isLoadingShipping && isSuccessShipping ? (
        <>
            <AdminHeader/>
            <Box>
                <Button variant="contained" sx={{mb: 1}}>
                    <Link style={{textDecoration: "none", color: "white"}} to="/admin/shipping/create">Создать</Link>
                </Button>
            </Box>
            <Box sx={{height: '600px'}}>
                {!!dataShipping.length &&
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            disableRowSelectionOnClick={true}
                            rows={dataShipping}
                            getRowHeight={() => 'auto'}
                            processRowUpdate={processRowUpdate}
                            columns={getOptions()}
                            slotProps={{
                                panel: {
                                    sx: {
                                        top: '-120px !important',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                }
                            }}
                        />
                    </ThemeProvider>}
            </Box>
        </>

    ) : <CircularProgress/>;
}
