import {FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {filter, includes, join, map} from "lodash";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {useGetListStatusesQuery} from "../../../../store/protected/clients/clients.api";
import {IFilter} from "./Models";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IProps {
    data: number[];
    changeFilter: (filterElement: Partial<IFilter>) => void;
}

export const FilterStatus = ({data, changeFilter}: IProps): React.ReactElement => {
    const {
        data: clientStatuses, isSuccess: isSuccessStatuses
    } = useGetListStatusesQuery(null, {refetchOnMountOrArgChange: true});

    const handleChange = (e: SelectChangeEvent<number[]>): void => {
        changeFilter({
            [e.target.name]: e.target.value
        })
    }

    const renderValue = (selected: number[]): string => {
        const result = filter(clientStatuses, (element) => {
            return includes(selected, element.id);
        })
        return join(map(result, (status) => status.name), ', ');
    }

    if (!isSuccessStatuses) {
        return null;
    }

    return (
        <FormControl sx={{m: 1, width: 300}} size="small">
            <InputLabel id="status-checkbox-label">Статус</InputLabel>

            <Select
                labelId="status-checkbox-label"
                id="status-checkbox"
                multiple
                size="small"
                name="statuses"
                onChange={handleChange}
                value={data}
                input={<OutlinedInput label="Статус"/>}
                renderValue={renderValue}
                MenuProps={MenuProps}
            >
                {map(clientStatuses, (status) => {
                    return (
                        <MenuItem key={status.id} value={status.id}>
                            <Checkbox checked={includes(data, status.id)}/>
                            <ListItemText primary={status.name}/>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
