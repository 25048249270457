import {Box, Button, Typography} from '@mui/material';
import {useState} from 'react';
import {useLazyUploadProductsDataBaseQuery} from '../store/protected/products/products.api';
import Grid from "@mui/material/Grid";
import {AdminHeader} from "./header";

export function UploadFile(): JSX.Element {
    const [selectedFile, setSelectedFile] = useState<File>();

    const [upload, {isLoading, isSuccess}] = useLazyUploadProductsDataBaseQuery();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('select a file');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        await upload(formData);
    }

    return (
        <Box sx={{flexGrow: 1, m: 2}}>
            <Grid container spacing={2}>
                <AdminHeader/>
                <Grid item xs={12}>
                    <Typography variant="body1" component="p">
                        Выберите xlsx файл с одним листом
                    </Typography>
                    <Box textAlign="center">
                        <Box sx={{flexGrow: 1, m: 2, display: "flex", justifyContent: "center"}}>
                            <input
                                type="file"
                                onChange={handleChange}
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                                style={{display: "none"}}
                                id="select-file"
                            />
                            <label htmlFor="select-file">
                                <Button variant="outlined" color="primary" component="span">
                                    {selectedFile ? "Выбрать другой файл (.xls, .xlsx)" : "Выбрать файл (.xls, .xlsx)"}
                                </Button>
                            </label>

                            {selectedFile && (
                                <Button variant="contained" color="primary" component="span" sx={{mx: 2}}
                                        onClick={handleUpload}>
                                    {`Загрузить ${selectedFile.name}`}
                                </Button>
                            )}
                        </Box>

                        {isLoading && <Typography display="block">Загрузка...</Typography>}
                        {isSuccess && <Typography display="block">Файл отправлен в обработку</Typography>}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
