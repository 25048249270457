import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {REMOTE_SERVER} from "./index";

export const baseQuery = fetchBaseQuery({
    baseUrl: REMOTE_SERVER,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('accessToken');

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    }
});

async function refreshToken(){
    try {
        // Получаем refresh token из локального хранилища
        const refreshToken = localStorage.getItem('refreshToken');

        // Проверяем наличие refresh token
        if (!refreshToken) {
            throw new Error("No refresh token available");
        }

        // Отправляем запрос на эндпоинт обновления токена
        const response = await fetch(`${REMOTE_SERVER}/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });

        // Проверяем ответ
        if (!response.ok) {
            throw new Error("Failed to refresh token");
        }

        // Получаем данные ответа
        const data = await response.json();

        // Возвращаем новый access token
        return {
            token: data.accessToken,
        };
    } catch (error) {
        console.error("Error refreshing token:", error);
        return { token: null };
    }
}

// @ts-ignore
export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // Предполагаем, что у вас есть функция для обновления токена
        //const refreshResult = await refreshToken();

        window.location.href ='/login';

        /*if (refreshResult.token) {
            // Сохранение нового токена
            localStorage.setItem('accessToken', refreshResult.token);
            // Повторный запрос с новым токеном
            result = await baseQuery(args, api, extraOptions);
        } else {
            // Обработка ситуации, когда обновление токена не удалось
            // Например, перенаправление на страницу входа
        }*/
    }

    return result;
};
