export const getFormatDate = (date: string | null = null, removeYear = false): string => {
    const ten = 10;
    const today = date ? new Date(date) : new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < ten) dd = '0' + dd;
    if (mm < ten) mm = '0' + mm;

    if (removeYear) return dd + '.' + mm; // Если нужно убрать год.

    return dd + '.' + mm + '.' + yyyy;
};
