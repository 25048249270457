import { createApi } from '@reduxjs/toolkit/query/react';
import {IProductDataResponse, IProductResponse} from '../../../models';
import {IFilter} from "../../../pages/admin";
import {isNull} from "lodash";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getProducts: build.query<IProductDataResponse, { limit: string, page: number, filter:IFilter }>({
            query: ({ limit, page, filter }) => {
                const newFilter: Record<string, any> = {...filter};
                if(isNull(newFilter._brand)){
                    delete newFilter._brand;
                }
                if(isNull(newFilter._sku)){
                    delete newFilter._sku;
                }

                return {
                url: 'product',
                params: {
                    _limit: limit,
                    _page: page,
                    ...newFilter
                },
            }},
        }),

        createProduct: build.query<IProductResponse, FormData>({
            query: (formData) => ({
                url: 'product',
                method: 'POST',
                body: formData,
            }),
        }),

        getProduct: build.query<IProductResponse, { id: string }>({
            query: ({ id }) => ({
                url: `product/${id}`,
            }),
        }),

        updateProduct: build.query<IProductResponse, { formData: FormData, id: string }>({
            query: ({ formData, id }) => ({
                url: `product/${id}`,
                method: 'PUT',
                body: formData,
            }),
        }),

        updateWithCodeProduct: build.query<IProductResponse, { formData: object, id: string, code: string }>({
            query: ({ formData, id, code }) => ({
                url: `product/with-code/${id}`,
                method: 'PUT',
                body: {
                    product: formData,
                    code,
                },
            }),
        }),

        uploadProductsDataBase: build.query<any, FormData>({
            query: (file) => ({
                url: 'product/excel',
                method: 'POST',
                body: file,
            }),
        }),
    }),
});

export const {
    useGetProductsQuery,
    useLazyCreateProductQuery,
    useGetProductQuery,
    useLazyUpdateProductQuery,
    useLazyUploadProductsDataBaseQuery,
    useLazyUpdateWithCodeProductQuery,
} = productsApi;
