import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import {IProductResponse} from "../models";
import isNil from "lodash/isNil";
import {InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {map} from "lodash";
import {useGetBrandsQuery} from "../store/public/product.api";
import {ChangeEvent} from "react";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface IAdminTableProps {
    data: IProductResponse[] | undefined;
    isSuccess: boolean;
    isError: boolean;
    handleChange: (event: SelectChangeEvent<number | string | null>) => void;
    handleTextChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface IProps extends IAdminTableProps {
    brand: string | null;
}

export function AdminTable({data, isSuccess, isError, brand, handleChange, handleTextChange}: IProps) {
    const {
        data: dataBrand,
    } = useGetBrandsQuery(null, {refetchOnMountOrArgChange: true});
    if (isError || isNil(data)) {
        // Проверить на 403 и редирект
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <TextField
                                id="standard-basic-productName"
                                label="Наименование"
                                variant="standard"
                                onChange={handleTextChange}
                                name="_productName"
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <InputLabel>Бренд</InputLabel>
                            <Select
                                id="select-brand"
                                value={brand}
                                name="_brand"
                                size="small"
                                onChange={handleChange}
                                sx={{width: '100%'}}
                            >
                                {map(dataBrand, (brand) => (<MenuItem value={brand}>{brand}</MenuItem>))}

                            </Select>
                        </StyledTableCell>
                        <StyledTableCell>
                            <TextField
                                id="standard-basic-article"
                                name="_sku"
                                onChange={handleTextChange}
                                label="Артикул"
                                variant="standard"/>
                        </StyledTableCell>
                        <StyledTableCell>
                            <InputLabel>Цена</InputLabel>
                        </StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Loader mb */}
                    {!isNil(data) && isSuccess && data.map(({id, productName, brand, sku, priceRrp}) => (
                        <StyledTableRow key={id}>
                            <StyledTableCell component="th" scope="row">{productName}</StyledTableCell>
                            <StyledTableCell>{brand}</StyledTableCell>
                            <StyledTableCell>{sku}</StyledTableCell>
                            <StyledTableCell>{priceRrp}</StyledTableCell>
                            <StyledTableCell align="right">
                                <Button variant="contained">
                                    <Link style={{textDecoration: "none", color: "white"}}
                                          to={`/admin/edit-product/${id}`}>Редактировать</Link>
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
