import {Box, Button, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TableCell, Typography} from '@mui/material';
import {useState} from 'react';
import Grid from "@mui/material/Grid";
import {AdminHeader} from "./header";
import {map} from "lodash";
import {REMOTE_SERVER} from "../consts";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {IAsyncTaskResponse} from "../store/protected/async-task/models";
import {useAsyncTaskListRemainsQuery, useLazyUploadRemainsDataBaseQuery} from "../store/protected/remains/remains.api";

export enum REMAINS_NAME_DOWNLOAD {
    MIELI = 'Miele',
    NEFF = 'Neff',
}

export enum REMAINS_NAME_UPLOAD {
    NEFF = 'Neff',
}

export function UploadRemainsFile(): JSX.Element {
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [uploadBrand, setUploadUploadBrand] = useState<string>('');
    const [downloadBrand, setDownloadBrand] = useState<string>('');
    const {
        data = {} as IAsyncTaskResponse[],
    } = useAsyncTaskListRemainsQuery({brand: downloadBrand}, {refetchOnMountOrArgChange: true});

    const [upload, {isLoading, isSuccess}] = useLazyUploadRemainsDataBaseQuery();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const changeUploadBrand = (event: SelectChangeEvent): void => {
        setUploadUploadBrand(event.target.value);
    }

    const changeDownloadBrand = (event: SelectChangeEvent): void => {
        setDownloadBrand(event.target.value);
    }

    const handleUpload = async () => {
        if (!selectedFile || uploadBrand === '') {
            alert('Заполните все поля');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('_brand', uploadBrand);

        await upload(formData);
        setUploadUploadBrand('');
        setSelectedFile(null);
    }

    return (
        <Box sx={{flexGrow: 1, m: 2}}>
            <Grid container spacing={2}>
                <AdminHeader/>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h2">
                        Загрузить остатки
                    </Typography>
                    <Typography variant="body1" component="p">
                        Выберите xlsx файл с одним листом
                    </Typography>
                    <Box textAlign="center">

                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <div style={{marginRight: '10px'}}>
                                <InputLabel>Производитель</InputLabel>
                                <Select
                                    id="select-brand"
                                    value={uploadBrand}
                                    name="_brand"
                                    size="small"
                                    onChange={changeUploadBrand}
                                    sx={{width: '100%'}}
                                >
                                    {map(REMAINS_NAME_UPLOAD, name => (<MenuItem value={name}>{name}</MenuItem>))}
                                </Select>
                            </div>
                            <input
                                type="file"
                                onChange={handleChange}
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                                style={{display: "none"}}
                                id="select-file"
                            />
                            <label htmlFor="select-file">
                                <Button variant="outlined" color="primary" component="span">
                                    {selectedFile ? "Выбрать другой файл (.xls, .xlsx)" : "Выбрать файл (.xls, .xlsx)"}
                                </Button>
                            </label>
                            {selectedFile && (
                                <Button variant="contained" color="primary" component="span" sx={{mx: 2}}
                                        onClick={handleUpload}>
                                    {`Загрузить ${selectedFile.name}`}
                                </Button>
                            )}
                        </Box>

                        {isLoading && <Typography display="block">Загрузка...</Typography>}
                        {isSuccess && <Typography display="block">Файл отправлен в обработку</Typography>}
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <Typography variant="h5" component="h2">
                            Скачать остатки
                        </Typography>
                        <div style={{marginRight: '10px'}}>
                            <InputLabel>Производитель</InputLabel>
                            <Select
                                id="select-brand-name"
                                value={downloadBrand}
                                name="_brand"
                                size="small"
                                onChange={changeDownloadBrand}
                                sx={{width: '100%'}}
                            >
                                {map(REMAINS_NAME_DOWNLOAD, name => (<MenuItem value={name}>{name}</MenuItem>))}
                            </Select>
                            {data?.length>0 && (
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Задача
                                                </TableCell>
                                                <TableCell>
                                                    Ссылка на скачивание
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data && map(data, task => (
                                                <TableRow>
                                                    <TableCell>
                                                        {task.title}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link
                                                            href={`${REMOTE_SERVER}remains/${task.id}`}
                                                            color="inherit">
                                                            Скачать каталог
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
