import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {IFilter} from "./Models";
import React from "react";

interface IProps {
    block: string;
    changeFilter: (filterElement: Partial<IFilter>) => void;
}

export const BlockFilter = ({block, changeFilter}: IProps): React.ReactElement => {
    const handleChange = (e: SelectChangeEvent<string>): void => {
        changeFilter({
            [e.target.name]: e.target.value
        })
    }

    return (
        <FormControl sx={{m: 1, width: '100%'}} size="small">
            <InputLabel id="block-label">В работе</InputLabel>
            <Select
                size="small"
                labelId="block-label"
                id="block-label-select"
                name="block"
                value={block}
                label="В работе"
                onChange={handleChange}
            >
                <MenuItem value="none">Все</MenuItem>
                <MenuItem value="false">Не в работе</MenuItem>
                <MenuItem value="true">В работе</MenuItem>
            </Select>
        </FormControl>
    );
}
