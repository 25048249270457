import {GridColDef} from "@mui/x-data-grid";
import {BlockButton} from "./Block";
import {noop, size} from "lodash";
import IconButton from "@mui/material/IconButton";
import {copyToClipboard} from "../../../utils/OrderColumns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Site} from "../../../store/protected/clients/models";
import moment from "moment";
import React from "react";
import Box from "@mui/material/Box";

export const columns: GridColDef[] = [
    {
        field: 'block',
        headerName: 'Резерв',
        width: 70,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: any) => (<BlockButton
            isBlocked={params.row.block}
            onChangeValue={noop}
            key={'block_' + params.row.id}
            clientId={params.row.id}
            clientUserId={50}
            userId={-100}
            setIsOpen={noop}
        />)
    },
    {
        field: 'check',
        headerName: 'Check',
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: any) => {
            return (<Box>
                {(size(params.row.contactsBitrix)>0 || size(params.row.dealsBitrix)>0) && (<div>BITRIX<br/></div>) }
                Email: {size(params.row.emails)}<br/>
                Имена: {size(params.row.names)}
            </Box>)
        }
    },
    {
        field: 'phones',
        headerName: 'Тел.',
        width: 160,
        filterable: false,
        valueGetter: (params: any) => params.row.phones.map((p: any) => p.phone),
        renderCell: (params: any) => (
            <div>
                {params.value.map((phone: string, index: number) => (
                    <div key={index}>
                        <a href={`tel:+${phone}`} style={{textDecoration: 'none', color: 'blue'}}>
                            +{phone}
                        </a>
                        <IconButton onClick={() => copyToClipboard(`+${phone}`)} color="default"
                                    size="small" sx={{ml: 1}}>
                            <ContentCopyIcon fontSize="small"/>
                        </IconButton>
                    </div>
                ))}
            </div>
        ),
        disableColumnMenu: true,
        sortable: false,
    },
    {
        field: 'sites',
        headerName: 'Бренд',
        width: 160,
        valueGetter: (params: any) => params.row.sites.map((s: any) => s.name).join(', '),
        renderCell: (params: any) => {

            return (
                <div>
                    {params.row.sites.map((s: Site, index: number) => (
                        <div key={index}>
                            {s.site} <br/> {moment(s.date).locale('ru').format('L LTS')}
                        </div>
                    ))}
                </div>
            )
        },
        filterable: false,

    },
    {
        field: 'status',
        headerName: 'Статус',
        width: 200,
        valueGetter: (params) => params.row.status?.name,
        filterable: false,
    },

    {
        field: 'comment',
        headerName: 'Комментарий',
        width: 230,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params: any) => params.row?.comment,
        sortable: false,
    },
    {
        field: 'user',
        headerName: 'Ответственный',
        width: 230,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params: any) => params.row.user?.username,
        sortable: false,
    },
    {
        field: 'id',
        headerName: 'Open',
        align: 'center',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,

    },
];

