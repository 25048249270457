import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

export function ProtectedRoute(): JSX.Element {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};
