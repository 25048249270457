import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {IAsyncTaskResponse} from "../async-task/models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const remainsApi = createApi({
    reducerPath: 'remainsApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        asyncTaskListRemains: build.query<IAsyncTaskResponse[], any>({
            query: ({brand}) => ({
                url: `remains/async-task/${brand}`,
                method: 'GET',
            }),
        }),
        uploadRemainsDataBase: build.query<any, FormData>({
            query: (file) => ({
                url: 'remains',
                method: 'POST',
                body: file,
            }),
        }),
    })
});

export const { useLazyUploadRemainsDataBaseQuery, useAsyncTaskListRemainsQuery } = remainsApi;
