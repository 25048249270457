import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {RenderCellWithLink} from "../../../../utils/OrderColumns";
import {IShippingCars} from "../../../ShippingCars/models";
import {useGetListQuery, useGetStatusesQuery} from "../../../ShippingCars/store/api";
import {find} from "lodash";

const CellWithStatus = (cellValues: GridRenderCellParams): JSX.Element => {
    const {data: dataStatuses} = useGetStatusesQuery();
    const {data: dataShipping} = useGetListQuery();

    if (!cellValues.row?.shippingCars) {
        return null;
    }

    const statusId = find(dataShipping, shipping => shipping.id === cellValues.row?.shippingCars).status;
    const statusName = find(dataStatuses, status => status.id === statusId).name;

    return (<div>
            {statusName}
        </div>
    );
}

export const getLogisticsColumns = (dataShipping: IShippingCars[]): GridColDef[] => {
    return [
        // ID
        {
            field: 'index',
            headerName: 'ID',
            flex: 0.2,
            filterable: false,
            disableColumnMenu: true,
        },

        // Артикул внутренний
        {
            field: 'productNumber',
            headerName: 'Арт. вн.',
            flex: 0.3,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Артикул
        {
            field: 'sku',
            headerName: 'Артикул',
            flex: 1,
            renderCell: RenderCellWithLink
        },

        // Бренд
        {
            field: 'brand',
            headerName: 'Бренд',
            flex: 0.5,
        },

        // Маршрут
        {
            getOptionValue: (value: any) => value.id,
            getOptionLabel: (value: any) => value.name,
            field: 'shippingCars',
            headerName: 'Маршрут',
            type: "singleSelect",
            flex: 0.8,
            valueOptions: dataShipping,
            filterable: true,
            editable: true,
        },

        // Статус маршрута
        {
            field: 'logisticsStatus',
            headerName: 'Статус отправки',
            flex: 0.7,
            renderCell: CellWithStatus,
        },
    ];
}
