import React, {ReactElement} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, GridColDef, ruRU} from "@mui/x-data-grid";
import {useGetDemandDataQuery} from "../store/logistics.api";
import {AdminHeader} from "../../header";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";


const theme = createTheme(
    {},
    ruRU,
);


const columns: GridColDef[] = [
    {
        field: 'clientName',
        headerName: 'Клиент',
        width: 150,

        sortable: false,
    },
    {
        field: 'projectName',
        headerName: 'Проект',
        width: 150,


        sortable: false,
    },
    {
        field: 'orderType',
        headerName: 'Вид заказа',
        width: 100,


        sortable: false,
    },
    {
        field: 'positionCount',
        headerName: 'Количество',
        width: 150,


        sortable: false,
    },
    {
        field: 'purchaseOrders',
        headerName: '№поставщику',
        width: 150,


        sortable: false,
    },
    {
        field: 'deliveryPlannedMoment',
        headerName: 'Срок по договору',
        width: 100,


        sortable: false,
    },
    {
        field: 'orderStatus',
        headerName: 'Статус заказа',
        width: 100,


        sortable: false,
    },
    {
        field: 'invoicesOut',
        headerName: 'Статус заказа',
        width: 100,


        sortable: false,
    },
    {
        field: 'dateDelivery',
        headerName: 'Дата доставки',
        width: 130,


        sortable: false,
    },
    {
        field: 'shipmentAddress',
        headerName: 'Адрес доставки',
        width: 130,


        sortable: false,
    },
    {
        field: 'demandState',
        headerName: 'Статус отгрузки',
        width: 100,


        sortable: false,
    },
    {
        field: 'trackNumber',
        headerName: 'Трек-номер',
        width: 120,


        sortable: false,
    },
    {
        field: 'comment',
        headerName: 'Комментарий',
        width: 120,


        sortable: false,
    },
    {
        field: 'storagePeriodAgreement',
        headerName: 'Срок бп/хран по договору',
        width: 120,


        sortable: false,
    },
    {
        field: 'storageStartDate',
        headerName: 'Дата начала хранения',
        width: 120,


        sortable: false,
    },
    {
        field: 'storageEndDate',
        headerName: 'Дата окончания бп/хран',
        width: 120,


        sortable: false,
    },
    {
        field: 'expirationDate',
        headerName: 'Дата окончания',
        width: 120,


        sortable: false,
    },
    {
        field: 'pricePerStorage',
        headerName: 'Цена/день',
        width: 120,


        sortable: false,
    },
    {
        field: 'priceTotalStorage',
        headerName: 'Стоимость хранения ',
        width: 120,


        sortable: false,
    },

];

export function DemandPage(): ReactElement {
    const {
        data, isLoading
    } = useGetDemandDataQuery(null, {refetchOnMountOrArgChange: true});

    return (
        <Box>
            <AdminHeader/>
            <Grid item xs={12} marginBottom={2}>
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/logistics">Заказы</Link>
                </Button>
                <Button variant="contained" size="small" sx={{mr: 1, p:0}}>
                    <Link style={{textDecoration: "none", color: "white", padding: "4px 10px"}} to="/admin/logistics/demand">Отгрузка</Link>
                </Button>
            </Grid>
            {isLoading && <Typography display="block">Загрузка...</Typography>}

            {data && (<ThemeProvider theme={theme}>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={data}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    slotProps={{
                        panel: {
                            sx: {
                                top: '-120px !important',
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-row': {
                            fontSize: '12px'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        }
                    }}
                />
            </ThemeProvider>)}

        </Box>
    )
}
