import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert, Button, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {map} from "lodash";
import {useListQuery} from "../Common/email.common.api";
import {
    useLazyCreateQuery,
    useLazyGetOneQuery,
    useLazySendAllQuery,
    useLazyUpdateQuery
} from "./email.distrubution.api";
import isNil from "lodash/isNil";
import {IEmailDistributionResponseDetails} from "./models";
import {PreviewEmail} from "./PreviewEmail";
import {EmailsList} from "./EmailsList";

const INITIAL_STATE: IEmailDistributionResponseDetails = {
    email: '1',
    emails: [],
    title: '',
    status: 'create',
}

export const EmailDistributionForm = (): JSX.Element => {
    const {id} = useParams();
    const {
        data: emailsProviders,
    } = useListQuery(null, {refetchOnMountOrArgChange: true});

    const [emailDistribution, setEmailDistribution] = useState(INITIAL_STATE);
    const [selectedExcel, setSelectedExcel] = useState<File>();
    const [selectedZip, setSelectedZip] = useState<File>();
    const [create] = useLazyCreateQuery();
    const [update] = useLazyUpdateQuery();
    const [getOne] = useLazyGetOneQuery();
    const [sendData] = useLazySendAllQuery();
    const navigate = useNavigate();
    const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async (id: string) => {
            const result = await getOne(id);

            setEmailDistribution(result.data);
        }

        if (!isNil(id)) {
            loadData(id);
        } else {

            setEmailDistribution(INITIAL_STATE);
        }
    }, [id]);

    const handleExcelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedExcel(e.target.files[0])
        }
    };

    const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedZip(e.target.files[0])
        }
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        setEmailDistribution({
            ...emailDistribution,
            email: event.target.value
        })
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailDistribution({
            ...emailDistribution,
            title: event.target.value
        })
    }

    const sendDistribution = async (): Promise<void> => {
        setEmailDistribution({
            ...emailDistribution,
            status: 'sending',
        })
        await sendData({id: +id});
        setIsSnackbarVisible(true);

    }

    const saveDistribution = async (): Promise<void> => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(emailDistribution)) {
            formData.append(key, value.toString());
        }

        if (selectedZip) {
            formData.append('zip', selectedZip);
        }

        if (selectedExcel) {
            formData.append('excel', selectedExcel);
        }
        try {
            if(emailDistribution?.id){
                await update({formData, id: emailDistribution?.id})
                const result = await getOne(id);

                setEmailDistribution(result.data);

            }else{
               const data =  await create({formData}).unwrap();
               navigate('/admin/emails/distribution/'+ data.id);
            }
        } catch (e) {
            console.log(e);

        }
    }

    const stylish = emailDistribution.status === 'create' ? {} : {opacity: 0.6, pointerEvents: 'none'};

    return (
        <>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                mt: 5,
                justifyContent: 'space-between'
            }}>
                <Box sx={{width: '48%', ...stylish}}>
                    <Typography component="h1" variant="h5" sx={{mb: 5}}>Рассылка</Typography>
                    <input
                        type="file"
                        onChange={handleExcelChange}
                        accept=".xlsx"
                        style={{display: "none"}}
                        id="select-excel"
                    />
                    <label htmlFor="select-excel" style={{width: '100%', display: 'flex', marginBottom: '16px'}}>
                        <Typography sx={{flexGrow: 1}} component="span">Список email для
                            рассылок: {selectedExcel ? selectedExcel.name : "не выбран"}</Typography>
                        <Button variant="outlined" color="primary" component="span">
                            {selectedExcel ? "Выбрать другой файл" : "Выбрать файл"}
                        </Button>
                    </label>

                    <input
                        type="file"
                        onChange={handleZipChange}
                        accept=".zip"
                        style={{display: "none"}}
                        id="select-zip"
                    />
                    <label htmlFor="select-zip" style={{width: '100%', display: 'flex', marginBottom: '16px'}}>
                        <Typography sx={{flexGrow: 1}} component="span">ZIP Архив
                            рассылки с картинками: {selectedZip ? selectedZip.name : "не выбран"}</Typography>
                        <Button variant="outlined" color="primary" component="span">
                            {selectedZip ? "Выбрать другой файл" : "Выбрать файл"}
                        </Button>
                    </label>
                    <Select
                        id="select-provider"
                        value={emailDistribution.email.toString()}
                        name="provider"
                        size="small"
                        onChange={handleSelectChange}
                        sx={{width: '100%', mb: '16px'}}
                    >
                        {map(emailsProviders, (email) => (
                            <MenuItem value={email.id}>{email.email} ({email.signature})</MenuItem>))}
                    </Select>

                    <TextField id="title" name="title" onChange={handleChange} value={emailDistribution.title}
                               sx={{width: '100%', mb: '16px'}} label="Заголовок рассылки" variant="outlined"/>
                    <Box>
                        <Button variant="contained" onClick={saveDistribution}>Сохранить</Button>
                    </Box>
                </Box>
                <Box sx={{width: '48%'}}>
                    <EmailsList data={emailDistribution?.emails}/>
                    {emailDistribution.id && emailDistribution.fileExist && emailDistribution.status === 'create' && (
                            <Box>
                                <Button variant="contained" onClick={sendDistribution}>Отправить письма</Button>
                            </Box>
                    )}
                </Box>
            </Box>
            <PreviewEmail emailDistribution={emailDistribution}/>
            <Snackbar
                open={isSnackbarVisible}
                autoHideDuration={2000}
                onClose={() => {
                    setIsSnackbarVisible(false)
                }}
                sx={{top: 0, right: 0}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%'}}>
                    Отправлено
                </Alert>
            </Snackbar>
        </>
    );
}
