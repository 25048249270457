import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {object, string, TypeOf} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {useLoginMutation} from '../store/protected/auth/auth.api';
import {authSlice} from '../store/protected/auth/auth.slice';
import {useAppDispatch} from '../hooks/store';
import {useNavigate} from 'react-router-dom';
import {Alert, Box, Button} from '@mui/material';
import {TextInput} from './TextInput';
import {useState} from "react";

const loginSchema = object({
    username: string().min(4, 'Введите более 3 символов').max(32),
    password: string().min(1, 'Введите пароль').min(8, 'Минимальная длина пароля 8 символов').max(32, 'Максимальная длина пароля 32 символа'),
});

type TLoginInput = TypeOf<typeof loginSchema>;

export function LoginForm(): JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isError, setIsError] = useState<boolean>(false);

    const methods = useForm<TLoginInput>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            username: "",
            password: "",
        },
    });

    const {handleSubmit} = methods;

    const [login] = useLoginMutation();

    const submitHandler: SubmitHandler<TLoginInput> = async (values) => {
        setIsError(false);

        try {
            // @ts-ignore
            const {accessToken, refreshToken, role, id} = await login(values).unwrap();
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('role', JSON.stringify(role));

            dispatch(authSlice.actions.setIsLoggedIn());
            dispatch(authSlice.actions.setRole(role));
            navigate('/admin');

        } catch (e) {
            setIsError(true)
            console.log(e);
        }
    };

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(submitHandler)} autoComplete="off" noValidate sx={{mt: 1}}>
                {isError && (<Alert severity="error" sx={ { marginBottom:'30px' } }>Неверная связка логин/пароль</Alert>)}
                <TextInput name="username" id="username" label="Логин" autoFocus/>
                <TextInput name="password" id="password" label="Пароль" type="password"/>

                <Button type="submit" variant="contained" fullWidth sx={{mt: 3, mb: 2}}>Войти</Button>
            </Box>
        </FormProvider>
    );
}
