import {createApi} from '@reduxjs/toolkit/query/react';
import {IOrderResponse, IOrdersResponse} from './models';
import {INavigation} from "../models";
import {IOrderFile} from "../../../models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export interface IMultiOrderResponseDto {
    name: string;
    order: number;
}

export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getOrders: build.query<IOrdersResponse, INavigation>({
            query: ({limit, page}) => ({
                url: 'order',
                params: {
                    _limit: limit,
                    _page: page,
                }
            })
        }),

        getBankExchangeValue: build.query<number, null>({
            query: () => ({
                url: '/currency/euro',
            })
        }),

        updateOrder: build.query<void, { order: IOrderResponse, id: string }>({
            query: ({order, id}) => ({
                url: `order/${id}`,
                method: 'PATCH',
                body: order,
            }),
        }),

        addMultiOrder: build.query<{ multi: number }, { id: string }>({
            query: ({id}) => ({
                url: `multi-order`,
                method: 'POST',
                body: {
                    _orderId: id,
                },
            }),
        }),

        getMultiOrder: build.query<IMultiOrderResponseDto[], { id: string }>({
            query: ({id}) => ({
                url: `multi-order/order/${id}`,
                method: 'GET',
            }),
        }),

        getOrderHistory: build.query<{ id: number, updated_at: string, comment: string }[], { id: string }>({
            query: ({id}) => ({
                url: 'order/history',
                params: {
                    _order_id: id,
                }
            })
        }),

        getOrderHistoryRecord: build.query<IOrderResponse, { id: string, historyId: string }>({
            query: ({id, historyId}) => ({
                url: `order/history/${id}/${historyId}`,
            })
        }),

        getQrProduct: build.query<string, { id: string }>({
            query: ({id}) => ({
                url: `qr/product/${id}`,
            })
        }),

        deleteOrder: build.query<void, { id: string }>({
            query: ({id}) => ({
                url: `order/${id}`,
                method: 'delete',
            })
        }),

        bitrixUpdate: build.query<{ qrLink: string }, { id: string }>({
            query: ({id}) => ({
                url: 'order/bitrix-update',
                method: 'POST',
                body: {
                    _orderId: id,
                }
            })
        }),

        addFile: build.query<void, { formData: FormData, id: string }>({
            query: ({formData, id}) => ({
                url: `order/${id}/file`,
                method: 'PUT',
                body: formData,
            }),
        }),


        updateShippingProduct: build.query<void, { id: number, shipping: number }>({
            query: ({id, shipping}) => ({
                url: `order/product/${id}`,
                method: 'PATCH',
                body: {
                    shipping,
                },
            }),
        }),

        getFiles: build.query<IOrderFile[], { id: string }>({
            query: ({id}) => ({
                url: `order/${id}/file`,
                method: 'GET',
            }),
        }),

        deleteFile: build.query<IOrderFile[], { id: string, fileId: string }>({
            query: ({id, fileId}) => ({
                url: `order/${id}/file/${fileId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useLazyGetOrdersQuery,
    useGetBankExchangeValueQuery,
    useLazyUpdateOrderQuery,
    useLazyGetOrderHistoryQuery,
    useLazyGetOrderHistoryRecordQuery,
    useLazyBitrixUpdateQuery,
    useLazyDeleteOrderQuery,
    useLazyGetQrProductQuery,
    useLazyAddFileQuery,
    useLazyGetFilesQuery,
    useLazyDeleteFileQuery,
    useLazyAddMultiOrderQuery,
    useLazyGetMultiOrderQuery,
    useLazyUpdateShippingProductQuery
} = ordersApi;
