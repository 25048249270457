import React, {useCallback, useEffect, useState} from 'react';
import {TextareaAutosize} from "@mui/material";
import {debounce} from "lodash";
import isNil from "lodash/isNil";
import {useLazyUpdateFieldValueQuery} from "../../../../store/protected/clients/clients.api";
import {DisabledBox} from "../Disabled";


interface CommentCellProps {
    comment: string;
    clientId: number;
    clientUserId?: number;
    userId: number;
}

const DEBOUNCE_TIMEOUT = 500;

export const Comment: React.FC<CommentCellProps> = ({comment, clientUserId, userId, clientId}) => {
    const [updateField] = useLazyUpdateFieldValueQuery();
    const [commentLocal, setCommentLocal] = useState<string>('');

    const sentData = useCallback(debounce((comment, clientId): void => {
        updateField({
            value: comment,
            field: 'comment',
            clientId
        });
    }, DEBOUNCE_TIMEOUT), []);

    // @ts-ignore
    const handleChange = async (event) => {
        // @ts-ignore
        const comment = event.target.value as string;

        if (comment) {
            sentData(comment, clientId);
            setCommentLocal(comment);
        }
    };

    useEffect(() => {
        setCommentLocal(comment)
    }, [comment]);


    const disabled = userId !== clientUserId;

    return (
        <DisabledBox disabled={disabled} sx={{width:'100%', display: 'flex', flexDirection: 'column'}}>
            <TextareaAutosize
                onChange={handleChange}
                style={{height: '100%', minHeight: '100px',width: 'calc(100% - 16px)', resize: 'none', overflow: 'auto'}}
                value={commentLocal}
            />
            {disabled && (<>Для редактирования возьмите в работу или выберите себя ответственным</>)}
        </DisabledBox>
    );
}
