import {Box, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import {useState} from 'react';
import Grid from "@mui/material/Grid";
import {map} from "lodash";
import {IAsyncTaskResponse} from "../../../../store/protected/async-task/models";
import {AdminHeader} from "../../../header";
import {useAsyncTaskCatalogUploadQuery} from "../../../../store/protected/async-task/async-task.api";

export function CatalogUploadData(): JSX.Element {
    const [idTask, setIdTask] = useState<string>('');
    const {
        data = {} as IAsyncTaskResponse[],
    } = useAsyncTaskCatalogUploadQuery({}, {refetchOnMountOrArgChange: true});


    const changeDownloadBrand = (event: SelectChangeEvent): void => {
        setIdTask(event.target.value);
    }


    return (
        <Box sx={{flexGrow: 1, m: 2}}>
            <Grid container spacing={2}>
                <AdminHeader/>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <Typography variant="h5" component="h2">
                            Отчет по загруженным каталогам
                        </Typography>
                        <div style={{marginRight: '10px'}}>
                            <InputLabel>Файл дата </InputLabel>
                            <Select
                                id="select-async-task"
                                value={idTask}
                                name="_async-task"
                                size="small"
                                onChange={changeDownloadBrand}
                                sx={{width: '100%'}}
                            >
                                {map(data, element => (<MenuItem value={element.id}>{element.title}</MenuItem>))}
                            </Select>

                            {idTask && (<div style={{marginTop: '10px'}}>
                                test data
                            </div>)}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
