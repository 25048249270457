import { Box, Container, Typography } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { EditForm } from '../components/EditForm';
import { useGetProductQuery } from '../store/protected/products/products.api';

export function EditPage(): JSX.Element {
    const { id } = useParams();

    if (!id) {
        return <Navigate to="/admin" replace />;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useGetProductQuery({ id });

    return (
        <Container maxWidth="lg">
            <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>Добавить продукт</Typography>

                { data && <EditForm data={ data } /> }
            </Box>
        </Container>
    );
};