import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {IRoles, IUsersDetailsResponse, IUsersListResponse} from "./users.dto";
import {INavigation} from "../models";
import {baseQueryWithReauth} from "../../../consts/baseQuery";

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getRoles: build.query<IRoles, void>({
            query() {
                return {
                    url: 'users/roles',
                }
            }
        }),

        getUsers: build.query<IUsersListResponse, INavigation>({
            query({limit, page}) {
                return {
                    url: 'users',
                    params: {
                        _limit: limit, _page: page,
                    }
                }
            }
        }),

        getUser: build.query<IUsersDetailsResponse, number>({
            query(userId) {
                return {
                    url: `users/${userId}`,
                }
            }
        }),

        updateUser: build.query<IUsersDetailsResponse, { formData: any, id: number | null }>({
            query: ({ formData, id }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body: formData,
            }),
        }),
    })
});

export const { useGetRolesQuery,
    useLazyUpdateUserQuery,
    useLazyGetUsersQuery,
    useLazyGetUserQuery,
useGetUsersQuery} = usersApi;
