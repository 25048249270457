import {GridColDef, GridRenderCellParams, GridValueGetterParams} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Link} from 'react-router-dom';
import {useLazyGetQrProductQuery} from "../store/protected/orders/orders.api";
import Box from "@mui/material/Box";
import {REMOTE_SERVER} from "../consts";
import isNil from "lodash/isNil";
import SaveIcon from '@mui/icons-material/Save';
import {useDispatch} from 'react-redux'
import * as React from "react";
import {openModal, saveProduct} from "../pages/Order/store/api";

export const copyToClipboard = (value: string | number) => {
    navigator.clipboard.writeText(value.toString())
}

export const renderCellWithCopy = (cellValues: GridRenderCellParams) => (
    <>
        {cellValues.value?.toFixed(2)}
        <IconButton onClick={() => copyToClipboard(cellValues.value)} color="default" size="small" sx={{ml: 1}}>
            <ContentCopyIcon fontSize="small"/>
        </IconButton>
    </>
);

interface IProps {
    cellValues: GridRenderCellParams;
}

export const RenderCellWithSaveProduct = ({cellValues}: IProps): React.ReactNode => {
    const dispatch = useDispatch();
    const openDialog = (): void => {
        dispatch(openModal());
        dispatch(saveProduct({
            id: cellValues.row.id,
            brDepth: cellValues.row.brDepth,
            brHeight: cellValues.row.brHeight,
            brWeight: cellValues.row.brWeight,
            brWidth: cellValues.row.brWidth,
        }));
    }

    if (isNil(cellValues.row.productId?.id)) {
        return null;
    }

    return (<>
            <SaveIcon sx={{cursor: 'pointer'}} onClick={openDialog}/>
        </>
    );
}

export const RenderCellWithLink = (cellValues: GridRenderCellParams): JSX.Element => {
    const [getQrCode] = useLazyGetQrProductQuery();
    const getQrCodeLink = async (): Promise<void> => {
        try {
            const result = await getQrCode({id: cellValues.row.productId.id});
            // @ts-ignore
            copyToClipboard(result?.data?.qrLink);
        } catch (e) {
            alert(e);
        }
    }

    const getCatalog = (): void => {
        copyToClipboard(`${REMOTE_SERVER}files/manager-catalog/${cellValues.row.productId.id}`);
    }

    if (isNil(cellValues.row.productId?.id)) {
        return cellValues.row.productName;
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Link target="_blank" to={`/admin/edit-product/${cellValues.row.productId.id}`}>{cellValues.value}</Link>
            {cellValues.row.productId?.id && cellValues.row.productId?.catalogExist && (
                <IconButton onClick={getQrCodeLink} color="default" size="small" sx={{ml: 'auto', fontSize: '12px'}}>
                    QR
                </IconButton>)
            }
            {cellValues.row.productId?.id && cellValues.row.productId?.catalogExist && (
                <IconButton onClick={getCatalog} color="default" size="small" sx={{fontSize: '12px'}}>
                    CT
                </IconButton>)
            }
        </Box>
    )
}

export const getMainColumns = (brandFilterValues: string[]): GridColDef[] => {
    return [
        // ID
        {
            field: 'index',
            headerName: 'ID',
            flex: 0.3,
            filterable: false,
            disableColumnMenu: true,
        },

        // Артикул внутренний
        {
            field: 'productNumber',
            headerName: 'Арт. вн.',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Артикул
        {
            field: 'sku',
            headerName: 'Артикул',
            flex: 1,
            renderCell: RenderCellWithLink
        },

        // Бренд
        {
            field: 'brand',
            headerName: 'Бренд',
            flex: 0.7,
            type: 'singleSelect',
            valueOptions: brandFilterValues,
        },

        {
            field: 'priceRrp',
            headerName: 'Цена (Р)',
            type: 'number',
            filterable: false,
            sortable: true,
            disableColumnMenu: true,
        },

        {
            field: 'quantity',
            headerName: 'Кол-во',
            type: 'number',
            filterable: false,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (cellValues.value ? cellValues.value : 1)
            },
        },

        // Ширина
        {
            field: 'brWidth',
            headerName: 'Ш. (m)',
            editable: true,
            type: 'number',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Высота
        {
            field: 'brHeight',
            headerName: 'В. (m)',
            editable: true,
            type: 'number',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Глубина
        {
            field: 'brDepth',
            headerName: 'Г. (m)',
            editable: true,
            type: 'number',
            flex: 0.4,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },

        // Вес
        {
            field: 'brWeight',
            headerName: 'Вес (kg)',
            editable: true,
            type: 'number',
            flex: 0.5,
            renderCell: (cellValues: GridRenderCellParams) => (
                <>
                    {cellValues.row.quantity ? cellValues.value * cellValues.row.quantity : cellValues.value}
                    <IconButton onClick={() => copyToClipboard(cellValues.value)} color="default" size="small"
                                sx={{ml: 1}}>
                        <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                </>
            ),
        },

        // Объем
        {
            field: 'grossVolume',
            headerName: 'Объем (m³)',
            type: 'number',
            flex: 0.6,
            renderCell: (cellValues: GridRenderCellParams) => (
                <>
                    {cellValues.row.quantity ? (cellValues.value * cellValues.row.quantity).toFixed(2) : cellValues.value.toFixed(2)}
                    <IconButton onClick={() => copyToClipboard(cellValues.value)} color="default" size="small"
                                sx={{ml: 1}}>
                        <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                </>
            ),
        },

        // Перевозка Европа - РФ
        {
            field: 'transferEuroRussia',
            headerName: 'Европа - РФ (€)',
            editable: true,
            type: 'number',
            flex: 0.5,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellWithCopy,
        },

        // Перевозка по Европе
        {
            field: 'transferEuro',
            headerName: 'По Европе (€)',
            editable: true,
            type: 'number',
            flex: 0.5,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'id',
            headerName: 'SAVE',
            editable: true,
            filterable: false,
            sortable: false,
            flex: 0.3,
            // @ts-ignore
            renderCell: (params: GridValueGetterParams) => <RenderCellWithSaveProduct cellValues={params}/>,
        }
    ];
};

export const getMainTotalColumns = (): GridColDef[] => {
    return [
        {field: 'index', flex: 0.3, renderCell: () => (<></>)},
        {field: 'productNumber', flex: 0.4},
        {field: 'sku', flex: 1},
        {field: 'brand', flex: 0.7},
        {field: 'priceRrp'},
        {field: 'quantity'},
        {field: 'brWidth', flex: 0.4},
        {field: 'brHeight', flex: 0.4},
        {field: 'brDepth', flex: 0.4},
        {field: 'brWeight', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
        {field: 'grossVolume', editable: false, type: 'number', flex: 0.6, renderCell: renderCellWithCopy},
        {field: 'transferEuroRussia', flex: 0.5},
        {field: 'transferEuro', editable: false, type: 'number', flex: 0.5, renderCell: renderCellWithCopy},
        {field: 'id', editable: false, type: 'number', flex: 0.3, renderCell: () => (<></>)},
    ];
};
