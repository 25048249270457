import React, {useEffect, useState} from 'react';
import {IMultiOrderResponseDto, useLazyGetMultiOrderQuery} from "../../../store/protected/orders/orders.api";
import {useParams} from "react-router";
import isNil from "lodash/isNil";
import {isEmpty, map} from "lodash";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";

/** Компонент  */
export function MultiOrderList(): JSX.Element | null {
    const [data, setData] = useState<IMultiOrderResponseDto[] | null>(null);
    const [getMultiOrder] = useLazyGetMultiOrderQuery();
    const {id} = useParams();

    useEffect(() => {
        if (id) {

            (async () => {
                const result = (await getMultiOrder({id})).data;

                // @ts-ignore
                setData(result);
            })();

        }
    }, [id]);

    if (isNil(data) || isEmpty(data)) {
        return null;
    }

    return (
        <Box>
            <Typography variant="h6">Мультизаказ</Typography>
            {map(data, (item, i) => (
                <Box key={i} display="flex" alignItems="center" justifyContent="space-between"
                     marginBottom={2}>
                    <Link to={`/admin/multiple/${item.order}`} target="_blank">{item.name}</Link>
                </Box>
            ))
            }
        </Box>
    )
};
